import { TimesheetService } from './../services/timesheet.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexChart,
  ApexXAxis,
  ApexTitleSubtitle,
  ApexLegend,
  ApexTooltip,
  ApexMarkers
} from "ng-apexcharts";
import { User } from '../app.model';
import { AuthenticationService } from '../services/authentication.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Sort } from '@angular/material/sort';
import { roleConstant, statusConstant } from '../app.constant';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetDetailsComponent } from '../timesheet-details/timesheet-details.component';
import { MatDialog } from '@angular/material/dialog';
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  title: ApexTitleSubtitle;
  legend: ApexLegend;
  tooltip: ApexTooltip;
  markers: ApexMarkers;
};
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild("chart") chart: any;
  timeSheetsCount: number = 0;
  user: any;
  public ROLES = roleConstant;

  constructor(public dialog: MatDialog, private timesheetService: TimesheetService, private authService: AuthenticationService, private modalService: NgbModal) {
    this.user = this.authService.userValue;
  }

  ngOnInit(): void {
    this.user = this.authService.userValue;
    this.timesheetService.getTimesheetCountByStatus('pending').subscribe((timeSheets: any) => {
      this.timeSheetsCount = timeSheets.count;
    });
  }

}

<div class="card-body">
	<div class="mb-5">
		<h4 class="mb-1">Over Time Settings</h4>
	</div>
	<div class="vstack gap-5">
		<h5>Daily Overtime</h5>
		<div class="mt-1">
			<mat-radio-group aria-label="Select an option" [(ngModel)]="dailyOverTimeSettings.is_active">
			  <mat-radio-button [value]="1" style="margin-right:10px;">Yes</mat-radio-button>
			  <mat-radio-button [value]="0" class="mt-2">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="row mt-1">
			<div class="col-md-4">
				<div>
					<label class="text-sm text-muted">OT Start After</label> 
					<input type="number" [disabled]="!dailyOverTimeSettings.is_active" class="form-control" [(ngModel)]="dailyOverTimeSettings.ot_start_after">
				</div>
			</div>
			<div class="col-md-4">
				<div>
					<label class="text-sm text-muted">OT Multiplier</label> 
					<input type="number" [disabled]="!dailyOverTimeSettings.is_active" class="form-control" [(ngModel)]="dailyOverTimeSettings.ot_multiplier">
				</div>
			</div>
		</div>
		<div class="row mt-1">
			<div class="col-md-4">
				<div>
					<label class="text-sm text-muted">Double OT Start After</label>
					<input type="number" [disabled]="!dailyOverTimeSettings.is_active" class="form-control"  [(ngModel)]="dailyOverTimeSettings.double_ot_start_after">
				</div>
			</div>
			<div class="col-md-4">
				<div>
					<label class="text-sm text-muted">Double OT Multiplier</label>
					<input type="number" [disabled]="!dailyOverTimeSettings.is_active" class="form-control" [(ngModel)]="dailyOverTimeSettings.double_ot_multiplier">
				</div>
			</div>
		</div>

		<h5>Weekly Overtime</h5>
		<div class="mt-1">
			<mat-radio-group aria-label="Select an option" [(ngModel)]="weeklyOverTimeSettings.is_active">
			  <mat-radio-button [value]="1" style="margin-right:10px;">Yes</mat-radio-button>
			  <mat-radio-button [value]="0" class="mt-2">No</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="row mt-1">
			<div class="col-md-4">
				<div>
					<label class="text-sm text-muted">OT Start After</label>
					<input type="number" [disabled]="!weeklyOverTimeSettings.is_active" class="form-control" [(ngModel)]="weeklyOverTimeSettings.ot_start_after">
				</div>
			</div>
			<div class="col-md-4">
				<div>
					<label class="text-sm text-muted">OT Multiplier</label>
					<input type="number" [disabled]="!weeklyOverTimeSettings.is_active"  class="form-control" [(ngModel)]="weeklyOverTimeSettings.ot_multiplier">
				</div>
			</div>
		</div>
	</div>
	<div class="hstack gap-2 justify-content-end mt-5"><button class="btn btn-sm btn-primary" (click)="saveOverTimeSettings()">Save</button></div>
</div>
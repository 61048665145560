import { Component, EventEmitter, forwardRef, Input, Output, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';


import { UtilsService } from 'src/app/services/utils.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css'],
})
export class SearchSelectComponent implements OnInit {
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() endpoint!: string;
  @Input() controlName!: string;
  @Input() titleKey!: string;
  @Input() control!: FormControl;

  @Input() dataSearch?: string;
  @Input() defaultValue?: string;
  @Input() disabled?: boolean = false;

  formGroup!: FormGroup;
  dataArray: any = [];
  selectedValue: string;
  searchingData: boolean = false;

  constructor(
    private utilsService: UtilsService,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      [this.controlName]: this.control,
    });
    localStorage.removeItem(`new_default_select_${this.controlName}`);
  }

  async ngOnChanges(): Promise<void> {
    if (typeof this.defaultValue != 'undefined' && this.dataArray.length === 0 && this.controlName == "client_uuid") {
      localStorage.setItem(`new_default_select_${this.controlName}`, this.defaultValue);
      await this.ongetClientByID(this.defaultValue);
    }
  }

  async ongetClientByID(client_uuid: any) {
    await this.clientService.getClientByID(client_uuid).subscribe(response => {
      let dataArray: any = [];
      let clent_response = response?.data;

      dataArray.push(clent_response);
      this.dataArray = dataArray;
      this.selectedValue = client_uuid;

      const selectedDataTitle = this.dataArray.find((x: any) => x.uuid === client_uuid);
      this.dataSearch = selectedDataTitle[this.titleKey];

      this.onChange.emit({
        dataArray: this.dataArray,
        value: selectedDataTitle,
        formControlName: this.controlName
      });

      localStorage.removeItem(`new_default_select_${this.controlName}`);
    });
  }

  onDataSelect(event: any) {
    const selectedDataTitle = this.dataArray.find((x: any) => x.uuid === event.value);
    this.dataSearch = selectedDataTitle[this.titleKey];

    this.onChange.emit({
      dataArray: this.dataArray,
      value: selectedDataTitle,
      formControlName: this.controlName
    })
  }

  getDataArray(event: any, option: any) {
    this.dataArray = [];
    this.formGroup.markAllAsTouched();

    this.onChange.emit({ dataArray: this.dataArray, value: {}, formControlName: this.controlName })
    if (!event?.target?.value) {
      this.dataSearch = '';
      this.selectedValue = '';
      return
    } else if (event?.target?.value?.length < 3) {
      return
    }

    this.searchingData = true
    this.utilsService.getDataWithSearch(event?.target?.value ?? event?.value, this.endpoint).subscribe((data) => {

      this.dataArray = [];
      if (data?.data?.length) {
        this.dataArray = data.data;
      } else {
        this.dataSearch = '';
        this.selectedValue = '';
        this.dataArray.push({ disabled: true, [this.titleKey]: `No ${this.endpoint} found for "${event?.target?.value ?? event?.value}"`, uuid: null })
      }

      this.searchingData = false;

      setTimeout(() => {
        option.open();
      }, 1);

    });
  }

}

<div class="paginatorContainer">
    <nav aria-label="...">
        <ul class="pagination">
            <li class="page-item action"
                (click)="((currentPageStep-1)*limit) ? changePage(currentPageStep-1):null"
                [ngClass]="((currentPageStep-1)*limit) > 0?'':'disabled'">
                <a class="page-link"><span>Previous</span></a>
            </li>
            <li [class]="step === currentPageStep ? 'page-item active':'page-item'" *ngFor="let step of pageSteps"
                (click)="step === currentPageStep || ((step-1)*limit) > (totalCount-1) ? null:changePage(step)"
                [ngClass]="((step-1)*limit) > (totalCount-1)?'disabled':'action'"
                [style.display]="(currentPageStep === step || currentPageStep === step+1 || currentPageStep === step-1) || (step === 3 && currentPageStep === 1) || (step === (pageSteps.length-2) && currentPageStep === pageSteps.length) ?'':'none'">
                <a class="page-link"><span>{{step}}</span></a>
            </li>
            <li class="page-item action"
                (click)="(currentPageStep*limit) > (totalCount-1)? null: changePage(currentPageStep+1)"
                [ngClass]="(currentPageStep*limit) > (totalCount-1)?'disabled':''">
                <a class="page-link"><span>Next</span></a>
            </li>
        </ul>
    </nav>
</div>
import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-select-search',
  templateUrl: './select-search.component.html',
  styleUrls: ['./select-search.component.css']
})
export class SelectSearchComponent implements OnInit {

 @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Input() endpoint!: string;
  @Input() controlName!: string;
  @Input() titleKey!: string;
  @Input() control!: FormControl;
  @Input() dataSearch?: string;
  @Input() disabled?: boolean = false;
  formGroup!: FormGroup;
  dataArray: any = [];
  searchingData: boolean = false;
  // dataSearch: string = '';

  constructor(
    private utilsService: UtilsService
  ) { }

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      [this.controlName]: this.control,
    });
  }

  onDataSelect(event: any) {
    const selectedDataTitle = this.dataArray.find((x: any) => x.uuid === event.value);
    this.dataSearch = selectedDataTitle[this.titleKey];
    this.onChange.emit({ dataArray: this.dataArray, value: selectedDataTitle, formControlName: this.controlName })
  }

  getDataArray(event: any, option: any) {
    this.dataArray = [];
    this.formGroup.markAllAsTouched();
    this.onChange.emit({ dataArray: this.dataArray, value: {}, formControlName: this.controlName })
    if (!event?.target?.value) {
      this.dataSearch = '';
      return
    } else if (event?.target?.value?.length < 3) {
      return
    }
    this.searchingData = true
    this.utilsService.getDataWithSearch(event?.target?.value ?? event?.value, this.endpoint).subscribe((data) => {
      this.dataArray = [];
      if (data?.data?.length) {
        this.dataArray = data.data;
      } else {
        this.dataArray.push({ disabled: true, [this.titleKey]: `No ${this.endpoint} found for "${event?.target?.value ?? event?.value}"`, uuid: null })
      }
      this.searchingData = false;
      setTimeout(() => {
        option.open();
      }, 1);
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { messageConstant } from 'src/app/app.constant';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BoardService } from 'src/app/services/board.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { SendEmailComponent } from '../send-email/send-email.component';
import { TimesheetDetailsComponent } from 'src/app/timesheet-details/timesheet-details.component';

@Component({
  selector: 'app-awaiting-approvals-modal',
  templateUrl: './awaiting-approvals-modal.component.html',
  styleUrls: ['./awaiting-approvals-modal.component.css']
})
export class AwaitingApprovalsModalComponent implements OnInit {
  allAwaitingApprovals?: any
  constructor(
    private dialogRef: MatDialogRef<AwaitingApprovalsModalComponent>,
    public boardService: BoardService,
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    private modalService: NgbModal,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.boardService.getAllAwaitingApprovals().subscribe((allAwaitingApprovals:any) => {
      this.allAwaitingApprovals = allAwaitingApprovals.data;
    });
  }

  close(){
    this.dialogRef.close();
  }

  openSendReminderPopUp(clientUuid:any, candidatName:string, jobName:string,) {
    const modalRef = this.modalService.open(SendEmailComponent, {
      size: 'md',
      modalDialogClass: 'add-reminder-modal'
    });

    let message = messageConstant.AWAITING_APPROVAL_MESSAGE.
    replace('{{contractor_name}}', candidatName).
    replace('{{job_name}}', jobName);

    modalRef.componentInstance.clientUuid = clientUuid;
    modalRef.componentInstance.userSubject = messageConstant.AWAITING_APPROVAL_SUBJECT;
    modalRef.componentInstance.userMessage = message;
  
    modalRef.result.then((data) => {
      if (data?.data === true) {
       
      }
    });
  }

  openTimesheetDetails(timesheet: any, status: string, readonly: boolean) {
    let modalRef = this.dialog.open(TimesheetDetailsComponent, {
      width: '90%',
      maxWidth: '800px',
      maxHeight: '700px',
      height: '90%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
      data: {
        timesheetId: timesheet.uuid,
        timesheet,
        readonly,
        status: status,
      },
    });

    modalRef.afterClosed().subscribe(result => {
      if (result?.refresh) {
        this.ngOnInit();
      }
    });
  }

}


import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AlertModal } from 'src/app/app.model';
import { roleConstant } from '../../app.constant';
import { TimesheetService } from '../../services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
	selector: 'app-create-agency',
	templateUrl: './create-agency.component.html',
	styleUrls: ['./create-agency.component.css']
})
export class CreateAgencyComponent implements OnInit {

	error!: string;
	loading: boolean = false;

	formValid: boolean = false;
	agencyForm: FormGroup | any;

	email_type: number = 0;
	frequency: number = 1;
	day_to_receive: number = 1;
	time_to_receive: string = '17:00';
	agencyformValid: boolean = false;
	agency_user: any = {
		uuid: '',
		first_name: '',
		last_name: '',
		email: '',
		role_uuid: roleConstant.AGENCY,
		timesheet_notification_settings: {
			email_type: this.email_type,
			frequency: this.frequency,
			day: this.day_to_receive,
			time: this.time_to_receive,
		}
	};
	agency_users: any = [];
	agency_edit_index!: number;

	constructor(
		private timesheetService: TimesheetService,
		public authService: AuthenticationService,

		private modalService: NgbModal,
		private activeModalService: NgbActiveModal,
	) { }

	ngOnInit(): void {
		this.agencyForm = new FormGroup({
			name: new FormControl('', Validators.required),
		});
	}

	onClose() {
		const modalRef = this.modalService.open(AlertModalComponent, {
			size: 'md',
			backdrop: false,
			modalDialogClass: 'alert-modal modal-dialog-centered',
		});
		let data: AlertModal = {
			message: 'Are you sure you want to discard Job Creation?',
			yesAction: 'Discard',
			noAction: 'Cancel',
			yesActionColor: '#0566EA',
			noActionColor: 'transparent',
		}
		modalRef.componentInstance.data = data;
		modalRef.result.then((data) => {
			if (data?.action === 'yes') {
				this.activeModalService.close();
			}
		});
	}

	ValidateEmail(email: string) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		return email_regexp.test(email);
	}

	async agencyUserValidation(event: any) {
		this.error = '';
		let hasErr = false;

		if (this.agency_user.first_name.trim() == '') {
			hasErr = true;
		}

		if (!hasErr && this.agency_user.last_name.trim() == '') {
			hasErr = true;
		}

		if (this.agency_user.email.trim() == '') {
			hasErr = true;
		} else {
			if (!this.ValidateEmail(this.agency_user.email.trim())) {
				hasErr = true;
			}
		}

		if (this.agency_user.timesheet_notification_settings.email_type == 1) {
			if (this.agency_user.timesheet_notification_settings.time.trim() == '') {
				hasErr = true;
			}
		}

		this.agencyformValid = (hasErr) ? false : true;
	}

	async addAgencyUser(event: any) {
		await this.agencyUserValidation(event);
		if (this.agencyformValid) {
			if (typeof this.agency_edit_index !== "undefined") {
				this.agency_users[this.agency_edit_index] = {
					uuid: this.agency_user?.id,
					first_name: this.agency_user?.first_name,
					last_name: this.agency_user?.last_name,
					name: `${this.agency_user?.first_name} ${this.agency_user?.last_name}`,
					email: this.agency_user?.email,
					role_uuid: roleConstant.AGENCY,
					timesheet_notification_settings: this.agency_user?.timesheet_notification_settings
				}
			} else {
				this.agency_users.push({
					uuid: this.agency_user.length + 1,
					first_name: this.agency_user?.first_name,
					last_name: this.agency_user?.last_name,
					name: `${this.agency_user?.first_name} ${this.agency_user?.last_name}`,
					email: this.agency_user?.email,
					role_uuid: roleConstant.AGENCY,
					timesheet_notification_settings: this.agency_user?.timesheet_notification_settings
				});
			}
			this.resetAgencyUser();
			this.agency_edit_index;
		} else {
			this.error = "Please fill out this field";
		}
	}

	async editAgencyUser(contact: any, agency_index: any) {
		this.agencyformValid = false;
		this.agency_edit_index = agency_index;
		this.agency_user = {
			uuid: contact.uuid,
			first_name: contact?.first_name,
			last_name: contact?.last_name,
			email: contact?.email,
			role_uuid: roleConstant.AGENCY,
			timesheet_notification_settings: contact?.timesheet_notification_settings
		};
	}

	async deleteAgencyUser(agency_index: any) {
		this.agency_users.splice(agency_index, 1);

		this.error = '';
		this.resetAgencyUser();
	}

	resetAgencyUser() {
		this.agency_user = {
			uuid: '',
			first_name: '',
			last_name: '',
			email: '',
			role_uuid: roleConstant.AGENCY,
			timesheet_notification_settings: {
				email_type: this.email_type,
				frequency: this.frequency,
				day: this.day_to_receive,
				time: this.time_to_receive,
			}
		}
	}

	async agencyValidation() {
		this.error = '';
		let hasErr = false;

		let agencyNameElement = document.getElementById('agencyName');
		if (this.agencyForm.controls.name.errors || this.agencyForm.controls.name.value.trim() == '') {
			if (agencyNameElement !== null) {
				hasErr = true;
			}
		}

		if (hasErr) {
			this.error = "Agency name field is required";
			this.formValid = false;
		} else {
			this.formValid = true;
		}

	}

	onSubmit() {
		this.agencyValidation();
		if (this.formValid) {
			this.loading = true;
			const payload = { agency: { name: this.agencyForm.value.name }, agency_users: this.agency_users };
			this.timesheetService.createAgencies(payload).subscribe(res => {
				this.loading = false;
				this.activeModalService.close({ refresh: true });
			}, error => {
				this.loading = false;
				this.error = error;
			});
		}
	}
}

<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Invoice</title>
<style>
    body {
        font-family: Arial, sans-serif;
        background-color: #f9f9f9;
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 100vh;
    }
    .invoice-container {
        background-color: #fff;
        padding: 30px;
        max-width: 900px;
        width: 100%;
        border-radius: 8px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    }
    .invoice-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .invoice-header h1 {
        font-size: 24px;
        margin: 0;
    }
    .invoice-header img {
        max-width: 300px;
    }
    .invoice-details, .invoice-summary {
        margin: 20px 0;
    }
    .invoice-details table, .invoice-summary table {
        width: 100%;
        border-collapse: collapse;
    }
    .invoice-details td, .invoice-summary th, .invoice-summary td {
        padding: 10px;
        border-bottom: 1px solid #ddd;
        text-align: left;
    }
    .invoice-details td:first-child {
        text-align: left;
    }
    .invoice-details td:last-child {
        text-align: right;
    }
    .invoice-summary th {
        background-color: #f5f5f5;
        font-weight: bold;
    }
    .invoice-summary .total {
        font-weight: bold;
        font-size: 18px;
        color: #333;
    }
    .invoice-summary td:last-child, .invoice-summary th:last-child {
        text-align: right;
    }
    .invoice-footer {
        text-align: center;
        font-size: 12px;
        color: #666;
        margin-top: 20px;
    }
</style>
</head>
<body>

<div class="invoice-container">
    <div class="invoice-header">
        <img src="logo.png" alt="Company Logo">
        <div>
            <h1>Invoice</h1>
            <p><strong>Invoice Number:</strong> #12345</p>
            <p><strong>Date:</strong> October 1, 2024</p>
        </div>
    </div>

    <div class="invoice-details">
        <table>
            <tr>
                <td><br>{{invoice.candidate}}<br> 123 Main St.<br> New York, NY 10001</td>
                <td><br>{{invoice.client}}<br> 456 Corporate Blvd.<br> Los Angeles, CA 90012</td>
            </tr>
        </table>
    </div>

    <div class="invoice-summary">
        <table>
            <thead>
                <tr>
                    <th>Description</th>
                    <th>Units</th>
                    <th>Unit Price (Pay)</th>
                    <th>Unit Price (Bill)</th>
                    <th>Total Bill</th>
                    <th>Total Pay</th>
                </tr>
            </thead>
            <tbody>
                
                <tr *ngFor="let invoiceDetails of invoice.invoice_details">
                    <td>{{invoiceDetails.description}}</td>
                    <td>{{invoiceDetails.hours}}</td>
                    <td>{{invoice.currency_format}} {{invoiceDetails.bill_rate}}</td>
                    <td>{{invoice.currency_format}} {{invoiceDetails.pay_rate}}</td>
                    <td>{{invoice.currency_format}} {{invoiceDetails.total_bill}}</td>
                    <td>{{invoice.currency_format}} {{invoiceDetails.total_pay}}</td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td colspan="4">Subtotal</td>
                    <td>{{invoice.currency_format}} {{invoice.bill_amount}}</td>
                    <td>{{invoice.currency_format}} {{invoice.pay_amount}}</td>
                </tr>
                <!-- <tr>
                    <td colspan="4">Tax (10%)</td>
                    <td>$47.50</td>
                    <td>$47.50</td>
                </tr> -->
                <tr>
                    <td colspan="4" class="total">Total</td>
                    <td>{{invoice.currency_format}} {{invoice.bill_amount}}</td>
                    <td>{{invoice.currency_format}} {{invoice.pay_amount}}</td>
                </tr>
            </tfoot>
        </table>
    </div>
</div>

</body>
</html>
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { MatDialog } from '@angular/material/dialog';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { environment } from 'src/environments/environment';
import { SocialAuthService } from "angularx-social-login";
import { SocialUser } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public user: SocialUser = new SocialUser;
  emailSent: boolean = false;
  rememberMe: boolean = localStorage.getItem('email') && localStorage.getItem('password') ? true : false;

  loginForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl()
  });
  submitted = false;
  loading = false;
  err: string = '';
  show = false;
  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthenticationService,
    private dataSharedService: DataSharedService,
    public dialog: MatDialog,
    private authService: SocialAuthService
  ) { }

  ngOnInit(): void {
    this.loginForm = this.formBuilder.group(
      {
        email: ['',
          [Validators.required, Validators.email]
        ],
        password: ['',
          [Validators.required, Validators.minLength(6)]
        ],
      }
    );
    if (this.rememberMe) {
      this.loginForm.patchValue({
        email: localStorage.getItem('email'),
        password: localStorage.getItem('password'),
      })
    }
    this.authService.authState.subscribe(user => {
      this.user = user;
    });
  }

  get lForm(): { [key: string]: AbstractControl } {
    return this.loginForm.controls;
  }
  login(): void {
    this.err = '';
    this.submitted = true;
    this.emailSent = false;
    if (this.loginForm.valid) {
      this.loading = true;
      this.auth.login(this.loginForm.value['email'], this.loginForm.value['password']).subscribe({
        next: (response) => {
          this.processLoginSuccess(response);
          if (this.rememberMe) {
            localStorage.setItem('email', this.loginForm.value['email']);
            localStorage.setItem('password', this.loginForm.value['password']);
          } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
          }
        },
        error: (err) => {
          this.loading = false;
          this.err = err
        }
      });
    }
  }

  public onGoogleLogin(): void {
    this.err = '';
    this.emailSent = false;
    this.loading = true;
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((res) => {
      this.auth.onGoogleLogin(res.authToken).subscribe({
        next: (response) => {
          this.processLoginSuccess(response);
          if (this.rememberMe) {
            localStorage.setItem('email', this.loginForm.value['email']);
            localStorage.setItem('password', this.loginForm.value['password']);
          } else {
            localStorage.removeItem('email');
            localStorage.removeItem('password');
          }
        },
        error: (err) => {
          this.loading = false;
          this.err = err
        }
      })
    });
  }

  async processLoginSuccess(response: any): Promise<void> {
    localStorage.setItem('profile_pic', response.profile_pic ? response.profile_pic : '');
    this.dataSharedService.setProfileImage()
    this.dataSharedService.setAgencyLogo()
    this.dataSharedService.permissionData = response.permissions;
    localStorage.setItem('permissions', JSON.stringify(response.permissions));
    localStorage.setItem('agency_uuid', response.agency_uuid);
    const returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'dashboard';
    this.router.navigateByUrl(returnUrl);
  }
  processLoginFailure(): void { }


  onForgotPassword() {
    this.emailSent = false;
    this.err = '';
    let modalRef = this.dialog.open(ForgotPasswordComponent, {
      data: {},
    });

    modalRef.afterClosed().subscribe(result => {
      if (result?.emailSent) {
        this.emailSent = true;
      }
    });
  }
  public signOut(): void {
    this.authService.signOut();
  }

}

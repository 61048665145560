<div class="modal-header">
  <h4 class="modal-title">Account Creation Link</h4>
  <mat-icon class="close clickable me-4" (click)="close()">close</mat-icon>
</div>

<div class="modal-body">
  <div class="transparent"></div>
  <div class="table-responsive">
    <table matSort class="table table-hover table-nowrap">
      <thead class="table-light">
        <tr>
          <th scope="col">User</th>
          <th scope="col">Link</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="text-heading font-bold ps-4">
            <div class="textWithIcon">
              <div class="" *ngIf="accounUser.profile_pic">
                <img class="avatar avatar-sm rounded-circle me-2 bg-warning"
                  src="{{dataSharedService.getImage(accounUser.profile_pic)}}" alt="..." />
              </div>
              <div *ngIf="!accounUser.profile_pic" class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                <span class="text-primary fs-10x">{{authService.getInitials(accounUser.name)}}</span>
              </div>
              <span class="text-heading font-semibold">{{accounUser.name}}</span>
            </div>
          </td>
          <td>
            <input type="text" class="input-form" value="{{accountCreationLink}}" />
          </td>
          <td>
            <button type="button" class="btn btn-sm mx-2 btn-primary"
              (click)="copyLink(accountCreationLink)">Copy</button>
          </td>
        </tr>

      </tbody>
    </table>
  </div>

</div>
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-date-format',
  templateUrl: './date-format.component.html',
  styleUrls: ['./date-format.component.css']
})
export class DateFormatComponent implements OnInit {
  dateFormat: any = {
    old: localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : '',
    new: localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : ''
  }
  currDate = new Date()

  constructor(
    public dataSharedService: DataSharedService,
    // private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
  }

  async saveDateFormat() {
    localStorage.setItem('dateFormat', this.dateFormat.new);

    await this.dataSharedService.setDateFormat(this.dateFormat.new).subscribe(res => {
      console.log('date format res', res);
    });
    this.dateFormat.old = this.dateFormat.new;
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { DataSharedService } from '../../services/data-shared.service';
import { TimesheetService } from '../../services/timesheet.service';
import { UserService } from '../../services/user.service';
import { NgbModal, NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../../components/success-modal/success-modal.component';
import { AccountGenerationLinkModalComponent } from '../../components/account-generation-link-modal/account-generation-link-modal.component';
import { SuccessModal } from '../../app.model';
import { AddEndEmploymentDateComponent } from '../add-end-employment-date/add-end-employment-date.component';
import { Subscription } from 'rxjs';
import { EditCandidateComponent } from '../edit-candidate/edit-candidate.component';

@Component({
  selector: 'app-active-candidates',
  templateUrl: './active-candidates.component.html',
  styleUrls: ['./active-candidates.component.css']
})
export class ActiveCandidatesComponent implements OnInit {
  timesheets: any = [];
  candidates: any = [];
  allCandidates: any = [];
  loading: boolean = false;
  redirectedName!: string;
  totalCount: number = 0;
  candidateSearch: string = '';
  private subscription: Subscription;
  private modalRef: NgbModalRef;


  constructor( 
    private timesheetService: TimesheetService,
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    public userService: UserService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private activeModalService: NgbActiveModal,
    public dialog: MatDialog,
    ) { 
      this.subscription = this.dataSharedService.getUpdate().subscribe((message) => {
        if(message.message == 'Update Active Candidates') {
          this.getCandidates(1);
        }
      });

    }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.redirectedName = params?.name;
      this.getCandidates(1);
    });
  }

  ngOnDestroy() { // It's a good practice to unsubscribe to ensure no memory leaks
    this.subscription.unsubscribe();
}

  async getCandidates(page: number, search? :string) {
    this.candidates = [];
    this.allCandidates = [];
    this.loading = true;
    this.timesheetService.getCandidates(page, search, 1).subscribe((res: any) => {
      this.totalCount = res.meta.total;
      this.candidates = res.data.filter((x: any) => x.role === 'Candidate');
      if (this.redirectedName) {
        this.candidates = this.candidates.filter((x: any) => x.name === this.redirectedName);
      }
      this.loading = false;
      this.allCandidates = this.candidates.slice();
    });
  }

  onStepChange(event: any) {
    this.getCandidates(event)
  }

  searchCandidate() {
    if(this.candidateSearch != '') {
      this.getCandidates(1, this.candidateSearch);
    } else {
      this.getCandidates(1);
    }
  }

  sortData(sort: Sort) {
    const data = this.candidates.slice();
    if (!sort.active || sort.direction === '') {
      this.allCandidates = data;
      return;
    }

    this.allCandidates = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'email':
          return compare(a.email, b.email, isAsc);
        case 'created_at':
          return compare(a.created_at, b.created_at, isAsc);
        default:
          return 0;
      }
    });
  }

  resendAccountActivation(candidateUuid: string) {
    const payload = {
      user_uuid: candidateUuid,
    }
    this.userService.resendUserLogin(payload).subscribe({
      next: (response) => {
        this.successModal('Account creation mail sent.')
      },
      error: (err) => {
      }
    });
  }

  showEmploymentEndDateModal(candidateUuid: string) {
    const modalRef = this.modalService.open(AddEndEmploymentDateComponent, {
      size: 'md',
      modalDialogClass: 'add-reminder-modal'
    });
    modalRef.componentInstance.userUuid = candidateUuid;
    modalRef.result.then((data) => {
      if (data?.data === true) {
       
      }
    });
  }

 

  generateAccountCreationLink(candidateUuid: string) {
    this.dialog.open(AccountGenerationLinkModalComponent, {
      width: '75%',
      maxWidth: '800px',
      maxHeight: '700px',
      height: '35%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
      data: {
        userUuid: candidateUuid,
      },
    });
  }

  editCandidate(candidate: any) {
    this.modalRef = this.modalService.open(EditCandidateComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'update-client-modal',
    });
    this.modalRef.componentInstance.candidate = candidate;
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

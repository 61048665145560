export interface User {
  role_uuid: string;
  profile_pic: string;
  email: string;
  password: string;
  token: string;
  name: string;
}

export interface Job {
  data: JobData[];
  links: JobLinks;
  meta: JobMeta;

}

export interface JobData {
  uuid: string
  title: string
  client: string
  description: string
  start_date: string
  end_date: string
  start_time: string
  end_time: string
  location: string
  positions: number
  contractors: [],
}

export interface JobLinks {
  first: string
  last: string
  prev: any
  next: string
}

export interface JobMeta {
  current_page: number
  from: number
  last_page: number
  links: Link[]
  path: string
  per_page: number
  to: number
  total: number
}

export interface Link {
  url?: string
  label: string
  active: boolean
}


export interface Clients {
  data?: (ClientData)[] | null;
  links: ClientLinks;
  meta: ClientMeta;
}
export interface Client {
  data: ClientData;
  links: ClientLinks;
  meta: ClientMeta;
}
export interface ClientData {
  uuid: string;
  name: string;
  is_active?: string | null;
  contacts?: (ContactData)[] | null;
}
export interface ContactData{
  uuid: string;
  name: string;
  first_name: string;
  last_name: string;
  email: string;
  is_active: string;
}

export interface ClientLinks {
  first: string;
  last: string;
  prev?: null;
  next?: null;
}
export interface ClientMeta {
  current_page: number;
  from: number;
  last_page: number;
  links?: (ClientLinksEntity)[] | null;
  path: string;
  per_page: number;
  to: number;
  total: number;
}
export interface ClientLinksEntity {
  url?: string | null;
  label: string;
  active: boolean;
}


export interface JobAssignment {
  data: JobAssignmentData[]
  links: JobAssignmentLinks
  meta: JobAssignmentMeta
}

export interface Invoices {
  data: JobAssignmentData[]
  links: JobAssignmentLinks
  meta: JobAssignmentMeta
}
export interface TimesheetApprovers {
  data: Approver[]
}

export interface Approver {
  uuid: string,
  name: string,
  email: string,
}

export interface JobAssignmentData {
  uuid: string
  job: string
  client: string
  agency: string
  start_date: string
  end_date: string
}

export interface JobAssignmentLinks {
  first: string
  last: string
  prev: any
  next: any
}

export interface JobAssignmentMeta {
  current_page: number
  from: number
  last_page: number
  links: JobAssignmentLink[]
  path: string
  per_page: number
  to: number
  total: number
}

export interface JobAssignmentLink {
  url?: string
  label: string
  active: boolean
}


export interface Timesheet {
  data: TimesheetData[]
  links: Links
  meta: Meta
}

export interface TimesheetApprovalLinks {
  approval_links: ApprovalLinks[]
}

export interface TimesheetViewLink {
  timesheet_link: string,
}

export interface ApprovalLinks {
  approver: string,
  approver_pic: string,
  approver_link: string,
}
export interface TimesheetData {
  uuid: string
  status: string
  client: string
  job: string
  candidate: string
  timesheet_details: TimesheetDetail[]
}

export interface TimesheetDetail {
  uuid: string
  date: string
}

export interface Links {
  first: string
  last: string
  prev: any
  next: any
}

export interface Meta {
  current_page: number
  from: number
  last_page: number
  links: Link[]
  path: string
  per_page: number
  to: number
  total: number
}

export interface Link {
  url?: string
  label: string
  active: boolean
}

export interface TimesheetDetails {
  data: TimesheetDetail[]
}

export interface Reminder {
  reminders: ReminderData[]
}

export interface Board {
  data: BoardData[]
}

export interface BoardData {
  awaiting_approvals : [],
  missing_timesheets: [],
}

export interface ReminderData {
  message: string
  day: number
  role_uuid: string
  time: string,
  isActive: number
}

export interface SendMail {
  to?: string,
  user_uuid?: string,
  subject: string,
  message: string,
}

export interface TimesheetDetail {
  uuid: string
  date: string
}

export interface AgencyLogo {
  logo: File
}

export interface ProfileImage {
  logo: File
}

export interface Settings {
  data: any
}

export interface DateFormat {
  date_format: any
}

export interface CurrencyFormat {
  currency_format: any
}

export interface DateFilter {
  label: string,
  start_date: string,
  end_date: string,
}

export interface TimesheetParams {
  limit: number,
  page: number,
  start_date: string,
  end_date: string,
  status: string,
  order_by: string,
  order: string,
}

export interface InvoicesParams {
  limit: number,
  page: number,
  start_date: string,
  end_date: string,
  status: string,
  order_by: string,
  order: string,
}

export interface AlertModal {
  message: string,
  yesAction: string,
  noAction: string,
  yesActionColor: string,
  noActionColor: string,
  image?: string
}

export interface SuccessModal {
  message: string,
  image?: string
  duration: number,
}

export interface auditTrailHeader {
  title: string
  subTitle: string
  icon: string
}

export interface Comments {
  data: []
}

export interface TimesheetAttachments {
  data: []
}

export interface PostTimesheetComment {
  comment: string | null | undefined
}

export interface ActivateDeactivateUser {
  message: string
}

export interface Tasks {
  name: string,
  description: string,
  status: string,
  assigned_to_uuid: string
  uuid: string
}

export interface ResendUserLogin {
  user_uuid: string
}

export interface AccountCreationLink {
  account_link: string,
  message: string,
  user: User
}

export interface PayBillReport {
  report_data: PayBillReportData,
  report_days: any,
  track_hours: any,
}

export interface PayBillReportExport {
  report_path: string,
}

export interface PayBillReportData {
  candidate: string,
  client: string,
  job: string,
  candidate_identifier: string,
  report_info: PayBillReportInfoData,
  total_hours: string,
  total_pay: string,
  total_bill: string,
}

export interface PayBillReportInfoData {
  date: string,
  bill_rate: string,
  pay_rate: string,
  hours: string,
  day: string,
}

export interface Notifications {
  data: NotificationData[],
  links: NotificationLinks,
  meta: NotificationMeta,
  unread_count: number,
}
export interface NotificationData {
  id: number
  uuid: string
  title: string
  body: string
  data: any
  is_read: string
}
export interface NotificationLinks {
  first: string
  last: string
  prev: any
  next: any
}

export interface NotificationMeta {
  current_page: number
  from: number
  last_page: number
  links: NotificationLink[]
  path: string
  per_page: number
  to: number
  total: number
}
export interface NotificationLink {
  url?: string
  label: string
  active: boolean
}
export interface TimesheetNotificationSettings {
  email_type: number,
  frequency: number,
  day: number,
  time: string,
}

export interface Agency {
  data: AgencyData[]
  links: Links
  meta: Meta
}

export interface AgencyData {
  uuid: string,
  name: string,
  clug_api_key?: string
}

export interface OverTimeSettings {
  overtime_settings: OverTimeSetting[]
  
}
export interface OverTimeSetting{
  type: string,
  is_active: boolean,
  ot_start_after: number,
  ot_multiplier: number,
  double_ot_start_after: number,
  double_ot_multiplier: number,
}
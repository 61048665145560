import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbAlert, NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { apiConstant } from 'src/app/app.constant';
import { AlertModal } from 'src/app/app.model';
import { JobAssignmentService } from 'src/app/services/job-assignment.service';
import { JobService } from 'src/app/services/job.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { SearchSelectComponent } from '../search-select/search-select.component';

@Component({
  selector: 'app-edit-job-assignment',
  templateUrl: './edit-job-assignment.component.html',
  styleUrls: ['./edit-job-assignment.component.css']
})
export class EditJobAssignmentComponent implements OnInit {
  @Input() assignment: any;
  editJobAssignment: FormGroup | any;
	
  jobs: any = [];
  error: any = [];
  loading: boolean = false;
  timesheetApprovers: any = [];
  selectedTimesheetApprovers: any =[];

  constructor(
    private jobService: JobService,
    private timesheetService: TimesheetService,
    private jobAssignmentService: JobAssignmentService,
    public datePipe: DatePipe,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.timesheetApprovers = this.assignment.timesheet_approvers;
    this.selectedTimesheetApprovers = this.assignment.timesheet_approvers
    .filter((x: any) => x.is_selected === true)
    .map((x:any) => x.uuid);
    this.editJobAssignment = new FormGroup({
	  assignment_uuid: new FormControl(this.assignment.uuid, Validators.required),
      job_uuid: new FormControl(this.assignment.job_uuid, Validators.required),
	  job_name: new FormControl(this.assignment.job, Validators.required),
      candidate_uuid: new FormControl(this.assignment.candidate_uuid, Validators.required),
	  candidate_name: new FormControl(this.assignment.candidate, Validators.required),
      start_date: new FormControl(this.assignment.start_date, Validators.required),
      end_date: new FormControl(this.assignment.end_date, Validators.required),
      timesheet_approvers: new FormControl(this.selectedTimesheetApprovers, Validators.required),
    });	
  	this.getJobDetail(this.assignment.job_uuid);
  } 
  
  getJobDetail(jobUuid='') {
   
	  this.jobs = [];
    this.jobService.getJob(jobUuid).subscribe((jobData) => {
       this.jobs = jobData.data
    });
  }

  get minLimit() {
    if (this.editJobAssignment?.value?.assignment_uuid) {
      const job = this.jobs.uuid === this.editJobAssignment?.value?.job_uuid;
      if (job) {
        return this.jobs?.start_date
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  get maxLimit() {
    if (this.editJobAssignment?.value?.assignment_uuid) {
      const job = this.jobs.uuid === this.editJobAssignment?.value?.job_uuid;
      if (job) {
        return this.jobs?.end_date
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.editJobAssignment.value).forEach(element => {
      if (this.editJobAssignment.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Job Assignment Updation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.loading = true;
    const data = {
      job_uuid: this.editJobAssignment.value.job_uuid,
      start_date: this.datePipe.transform(this.editJobAssignment.value.start_date, 'YYYY-MM-dd'),
      end_date: this.datePipe.transform(this.editJobAssignment.value.end_date, 'YYYY-MM-dd'),
      timesheet_approvers: this.editJobAssignment.value.timesheet_approvers,
    };
	 const assignment_uuid = this.editJobAssignment.value.assignment_uuid;
	 
    this.jobAssignmentService.updateJobAssignments(assignment_uuid, data).subscribe((res) => {
      this.loading = false
      this.activeModalService.close({ refresh: true });
    }, error => {
      this.loading = false
      this.error = error;
    }
    );
    //console.log(this.editJobAssignment.value);
  }

}
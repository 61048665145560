<form [formGroup]="client_form">
    <header>
        <div class="container-fluid">
            <div class="border-bottom pt-1 pb-6">
                <div class="row align-items-center">
                    <div class="col-sm col-12">
                        <h1 class="h2 ls-tight">Create Client</h1>
                    </div>
                    <div class="col-sm-auto col-12 mt-4 mt-sm-0">
                        <div class="hstack gap-2 justify-content-sm-end">
                            <a routerLink="/clients" class="btn btn-sm btn-neutral border-base">Cancel</a>
                            <button type="submit" class="btn btn-sm btn-primary" [disabled]="loading || !formValid"
                                (click)="onSubmit()">Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>

    <div class="container-fluid max-w-screen-lg vstack gap-6 mt-8">
        <div class="vstack gap-4">

            <div class="loader" *ngIf="loading">
                <mat-spinner diameter="40"></mat-spinner>
            </div>

            <div class="card">
                <div class="card-body">
                    <div class="col-md-12">
                        <label class="form-label">Client Name</label>
                        <input type="text" class="form-control" placeholder="Enter client name" formControlName="name"
                            id="client_name" (blur)='clientValidation($event)' />
                    </div>
                </div>
            </div>

            <div class="agency-users-column">
                <div class="mb-5">
                    <h4 class="mb-1">Client Contacts</h4>
                </div>
                <div class="contact-section">
                    <div class="row gx-4 agency-users" *ngFor="let contact of client_contacts; let i = index;">
                        <div class="card">
                            <div class="card-body">
                                <div class="btn remove_agency-user-section">
                                    <!-- <i class="bi bi-x-circle-fill btn" (click)="removeContact(i)" *ngIf="i != 0"></i> -->
                                    <i class="bi bi-x-circle-fill btn" (click)="removeContact(i)"
                                        *ngIf="client_contacts.length > 1"></i>
                                </div>
                                <div class="row g-5">
                                    <div class="col-md-6">
                                        <label class="form-label">First Name</label>
                                        <input type="text" class="form-control" placeholder="Enter first name"
                                            name="first_name_{{contact.id}}" [(ngModel)]="contact.first_name"
                                            [ngModelOptions]="{standalone: true}" id="firstName_{{contact.id}}"
                                            (blur)='clientValidation($event)' />
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Last Name</label>
                                        <input type="text" class="form-control" placeholder="Enter last name"
                                            name="last_name_{{contact.id}}" [(ngModel)]="contact.last_name"
                                            [ngModelOptions]="{standalone: true}" id="lastName_{{contact.id}}"
                                            (blur)='clientValidation($event)' />
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Email</label>
                                        <input type="email" class="form-control" placeholder="Enter email"
                                            name="email_{{contact.id}}" [(ngModel)]="contact.email"
                                            [ngModelOptions]="{standalone: true}"
                                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" #contactEmail="ngModel"
                                            id="email_{{contact.id}}" (blur)='clientValidation($event)' />
                                    </div>
                                    <div class="col-md-6">
                                        <label class="form-label">Type of Email <span
                                                class="bi bi-info-circle-fill tooltip-custom"><span
                                                    class="tooltiptext">Individual Email Approval: Sends timesheet
                                                    details of single
                                                    candidate for Approval.<br>Bulk Email Approval: Sends timesheet
                                                    details of
                                                    multiple candidates for Approval.</span></span>
                                        </label>
                                        <mat-select class="form-select" type="text" name="email_type_{{contact.id}}"
                                            [(ngModel)]="contact.timesheet_notification_settings.email_type"
                                            [ngModelOptions]="{standalone: true}">
                                            <mat-option [value]="0">Individual</mat-option>
                                            <mat-option [value]="1">Bulk</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="contact.timesheet_notification_settings.email_type == 1">
                                        <label class="form-label">Frequency</label>
                                        <mat-select class="form-select" name="frequency_{{contact.id}}"
                                            [(ngModel)]="contact.timesheet_notification_settings.frequency"
                                            [ngModelOptions]="{standalone: true}">
                                            <mat-option [value]="1">Weekly</mat-option>
                                            <mat-option [value]="2">Monthly</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="contact.timesheet_notification_settings.email_type == 1">
                                        <label class="form-label">Day</label>
                                        <mat-select class="form-select" name="day_{{contact.id}}"
                                            [(ngModel)]="contact.timesheet_notification_settings.day"
                                            [ngModelOptions]="{standalone: true}">
                                            <mat-option [value]="0">Sunday</mat-option>
                                            <mat-option [value]="1">Monday</mat-option>
                                            <mat-option [value]="2">Tuesday</mat-option>
                                            <mat-option [value]="3">Wednesday</mat-option>
                                            <mat-option [value]="4">Thursday</mat-option>
                                            <mat-option [value]="5">Friday</mat-option>
                                            <mat-option [value]="6">Saturday</mat-option>
                                        </mat-select>
                                    </div>
                                    <div class="col-md-6"
                                        *ngIf="contact.timesheet_notification_settings.email_type == 1">
                                        <label class="form-label">Time</label>
                                        <input type="time" class="input-form input-time" min="06:00:00" max="20:00:00"
                                            name="time_{{contact.id}}"
                                            [(ngModel)]="contact.timesheet_notification_settings.time"
                                            [ngModelOptions]="{standalone: true}" id="time_{{contact.id}}"
                                            (blur)='clientValidation($event)' />
                                    </div>
                                    <div class="col-md-6">
                                        <div class="toggleContainer">
                                            <mat-slide-toggle [(ngModel)]="contact.send_invite"
                                                [ngModelOptions]="{standalone: true}">
                                                Send Invite
                                            </mat-slide-toggle>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hstack gap-2 justify-content-between">
                <button type="button" class="btn btn-sm btn-primary" (click)="onAddClientContact()">+
                    Add User</button>
                <div class="hstack gap-2 justify-content-sm-end">
                    <a routerLink="/clients" class="btn btn-sm btn-neutral border-base">Cancel</a>
                    <button type="submit" class="btn btn-sm btn-primary" [disabled]="loading || !formValid"
                        (click)="onSubmit()">Submit</button>
                </div>
            </div>

        </div>
    </div>
</form>
<div class="container-fluid max-w-screen-sm vstack gap-6 mt-4">
  <div class="card">
    <div class="card-body">
      <div class="mb-5">
        <h4 class="mb-1">Change Your Password</h4>
        <p class="text-sm text-muted">Enter your Old Password & New Password</p>
      </div>
      <div class="vstack gap-5">
        <form class="row g-3 needs-validation" [formGroup]="changePasswordForm" (ngSubmit)="changePassword()">
          <div class="col-12">
            <label for="email" class="form-label">Old Password</label>
            <div class="input-group d-flex align-items-center" id="form">
              <input [type]="showOld ? 'text' : 'password'" formControlName="old_password" name="old_password"
                class="form-control" id="email" [ngClass]="{ 'is-invalid': submitted && rForm['old_password'].errors }"
                style="border: none !important;">
              <i [class]="showOld ? 'bi bi-eye' : 'bi bi-eye-slash'" class="px-2" (click)="showOld = !showOld"></i>
              <div *ngIf="rForm['old_password'].errors" class="invalid-feedback">
                <div *ngIf="rForm['old_password'].errors['required']">Old Password is required
                </div>
                <div *ngIf="rForm['old_password'].errors['minlength']">Password must be at least
                  8
                  characters</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="password" class="form-label">New Password</label>
            <div class="input-group d-flex align-items-center" id="form">
              <input (keyup)="validatePasswordConfirmation()" [type]="showNew ? 'text' : 'password'"
                formControlName="new_password" name="new_password" class="form-control" id="password"
                [ngClass]="{ 'is-invalid': submitted && rForm['new_password'].errors }"
                style="border: none !important;">
              <i [class]="showNew ? 'bi bi-eye' : 'bi bi-eye-slash'" class="px-2" (click)="showNew = !showNew"></i>
              <div *ngIf="rForm['new_password'].errors" class="invalid-feedback">
                <div *ngIf="rForm['new_password'].errors['required']">New Password is required
                </div>
                <div *ngIf="rForm['new_password'].errors['minlength']">Password must be at least
                  8
                  characters</div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <label for="confirm_password" class="form-label">Password Confirmation</label>
            <div class="input-group d-flex align-items-center" id="form">
              <input (keyup)="validatePasswordConfirmation()" [type]="showConfirm ? 'text' : 'password'"
                formControlName="confirm_password" name="confirm_password" class="form-control" id="password"
                [ngClass]="{ 'is-invalid': submitted && rForm['confirm_password'].errors }"
                style="border: none !important;">
              <i [class]="showConfirm ? 'bi bi-eye' : 'bi bi-eye-slash'" class="px-2"
                (click)="showConfirm = !showConfirm"></i>
              <div *ngIf="rForm['confirm_password'].errors" class="invalid-feedback">
                <div *ngIf="rForm['confirm_password'].errors['required']">Password Confirmation
                  is required</div>
                <div *ngIf="rForm['confirm_password'].errors['minlength']">Password must be at
                  least 8 characters</div>
                <div *ngIf="rForm['confirm_password'].errors['confirmPassword']">Password not
                  match</div>
              </div>
            </div>
          </div>
          <span style="color: red; font-size: 13px; text-align: center;" *ngIf="err">{{err}}</span>
          <div class="hstack gap-2 justify-content-end mt-5">
            <button type="submit" class="btn btn-sm btn-primary">
              <div class="loginLoader d-flex justify-content-center">
                <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
              </div>
              <label *ngIf="!loading">Change Password</label>
            </button>
          </div>
          <div class="col-12 d-flex justify-content-center" *ngIf="successfullySet">
            <label>Your Password has been successfully changed</label>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<header>
  <div class="container-fluid">
    <div class="border-bottom">
      <ul class="nav nav-tabs overflow-x border-0">
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/settings/general">General</a>
        </li>
        <li class="nav-item" *ngIf="user.role_uuid === ROLES.AGENCY">
          <a class="nav-link" routerLinkActive="active" routerLink="/settings/form">Form</a>
        </li>
        <li class="nav-item" *ngIf="user.role_uuid === ROLES.AGENCY">
          <a class="nav-link" routerLinkActive="active" routerLink="/settings/reminder">Reminder</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" routerLinkActive="active" routerLink="/settings/change-password">Change Password</a>
        </li>
        <!-- <li class="nav-item" *ngIf="user.role_uuid === ROLES.AGENCY">
          <a class="nav-link" routerLinkActive="active" routerLink="/settings/flow">Authorization Flow</a>
        </li> -->
      </ul>
    </div>
  </div>
</header>
<router-outlet></router-outlet>
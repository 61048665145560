<div class="modal-header align-items-start p-0 border-bottom-0 d-flex mb-8 justify-content-between flex-sm-row-reverse">
    <div>
      <button type="button" class="btn-close btn-neutral btn-square"
        aria-label="Close" (click)="close()"></button>
    </div>
    <div class="text-dark align-items-center d-flex flex-column">
      <label class="pt-2 title">Share Timesheet View Link</label>
    </div>
    <div class="transparent"></div>
  </div>
 
  <div class="modal-body p-0">
    <table matSort class=" table table-borderless datatable">
        <thead class="table-light">
            <tr>
                <th scope="col">Link</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr class="dataColumn">
                <td>
                    <input type="text" class="input-form" value="{{timesheetViewLink}}"/>
                </td>
                <td>
                    <div class="copyLink">
                        <button (click)="copyLink(timesheetViewLink)" type="button">Copy</button>
                    </div>
                </td>
            </tr>
        </tbody>    
    </table>
  </div>
 
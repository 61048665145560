import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { JobService } from 'src/app/services/job.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AlertModal } from 'src/app/app.model';
import { AfterViewChecked, AfterViewInit, Component, OnInit, Input, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-edit-job',
  templateUrl: './edit-job.component.html',
  styleUrls: ['./edit-job.component.css']
})
export class EditJobComponent implements OnInit {
  @Input() job: any;
  jobForm: FormGroup | any;
  error!: string;
  loading: boolean = false;
  timesheetApprovers: any = [];
  selectedTimesheetApprovers: any =[];


  
  constructor(
    private activeModalService: NgbActiveModal,
    private jobService: JobService,
    private modalService: NgbModal,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    this.timesheetApprovers = this.job.timesheet_approvers;
    this.selectedTimesheetApprovers = this.job.timesheet_approvers
    .filter((x: any) => x.is_selected === true)
    .map((x:any) => x.uuid);
      this.jobForm = new FormGroup({
        name: new FormControl(this.job.title, Validators.required),
        description: new FormControl(this.job.description),
        location: new FormControl(this.job.location),
        positions: new FormControl(this.job.positions),
        start_date: new FormControl(this.job.start_date, Validators.required),
        end_date: new FormControl(this.job.end_date, Validators.required),
        pay_rate: new FormControl(this.job.pay_rate, Validators.required),
        bill_rate: new FormControl(this.job.bill_rate, Validators.required),
        client: new FormControl(this.job.client),
        candidates: new FormControl(this.job.contractors.join(', ') ?? '--'),
        timesheet_approvers: new FormControl(this.selectedTimesheetApprovers, Validators.required),
    });
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.jobForm.value).forEach(element => {
      if (this.jobForm.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Job Updation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.error = '';
    this.loading = true;
    const payload = {
      ...this.jobForm.value,
      start_date: this.datePipe.transform(this.jobForm.value.start_date, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.jobForm.value.end_date, 'yyyy-MM-dd'),
    };
    console.log(payload);
    this.jobService.updateJob(this.job.uuid, payload).subscribe(res => {
      this.activeModalService.close({ refresh: true });
      this.loading = false;
    }, error => {
      this.loading = false;
      this.error = error;
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { AlertModal } from 'src/app/app.model';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharedService } from 'src/app/services/data-shared.service';




@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent implements OnInit {
  @Output() onNewAttachment: EventEmitter<any> = new EventEmitter();
  @Input() attachmentsArr!: any;
  @Input() from: any;
  @Input() timesheet_uuid!: string;
  name: string = '';
  file: any;
  image: any;
  attachmentForm: FormGroup | any;
  loading: boolean = false;




  myForm = new FormGroup({
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl()
  });


  iconList = [
    { type: 'xlsx', icon: 'assets/extIcons/icons8-xls-48.png' },
    { type: 'docs', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'docx', icon: 'assets/extIcons/icons8-microsoft-word-48.png' },
    { type: 'pdf', icon: 'assets/extIcons/icons8-pdf-48.png' },
    { type: 'csv', icon: 'assets/extIcons/icons8-xls-48.png' },
    { type: 'xls', icon: 'assets/extIcons/icons8-xls-48.png' },
    { type: 'png', icon: 'assets/extIcons/icons8-png-48.png' },

  ]
  // acceptFiles: any = [
  //   '.csv', '.pdf', '.doc', '.docs', '.docx', '.png', '.svg', '.jpeg', '.jpg', 'xxl', 'xlsx', 'xls'
  // ]
  acceptFiles: any = [
    '.pdf'
  ]
  selectedFile: any;

  constructor(
    private attachmentsService: AttachmentsService,
    private modalService: NgbModal,
    public dataSharedService: DataSharedService,
  ) {

  }

  ngOnInit(): void {
    this.attachmentForm = new FormGroup({
      fileUpload: new FormControl('', [Validators.required])
    });
  }

  async fetchAttachments(){
    this.loading = true;
    this.attachmentsArr = await new Promise((resolve, reject) => this.attachmentsService.getAttachments(this.timesheet_uuid).subscribe((res: any) => resolve(res), err => reject(err)))
    this.onNewAttachment.emit(this.attachmentsArr)
    this.loading = false;
  }

  get f() {
    return this.myForm.controls;
  }

  selectFile(event: any, inputFile: any){
    event = event || window.event;
    if(event.target.id != 'filechose_button'){
      inputFile.click();
    }
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.myForm.patchValue({
        fileSource: file
      });
      const modalRef = this.modalService.open(AlertModalComponent, {
        size: 'md',
        modalDialogClass: 'alert-modal modal-dialog-centered',
        backdrop: false,
      });
      let data: AlertModal = {
        message: 'Are you sure you want to upload this attachment?',
        yesAction: 'Upload',
        noAction: 'Cancel',
        yesActionColor: '#02cc89',
        noActionColor: 'transparent',
        image: 'assets/img/approve-confirmation-icon.png'
      }
      modalRef.componentInstance.data = data;
      modalRef.result.then((data: any) => {
        if (data?.action === 'yes') {
          this.submit()
        }
      });
    }
  }

  submit() {
    const formData = new FormData();
    formData.append('files', this.myForm.get('fileSource')?.value);
    formData.append('timesheetId', this.timesheet_uuid);
    formData.append('name', this.myForm.get('fileSource')?.value?.name);

    this.loading = true;
    this.attachmentsService.uploadAttachment(formData).subscribe(res => {
      this.loading = false;
      this.fetchAttachments()
      console.log(res);
      alert('Uploaded Successfully.');
    }, err => {
      this.loading = false;
      console.log(err);
    })
  }

  getFileType(fileName: string){
    return fileName.split('.')[fileName.split('.').length-1];
  }

  getFileIcon(attachment: any) {
    const fileType = this.getFileType(attachment.document_name);
    return this.iconList.find(x => x.type === fileType)?.icon
  }

  deleteDocuments(attachment_uuid: string) {
    this.loading = true;
    this.attachmentsService.deleteAttachment(attachment_uuid).subscribe(res => {
      this.fetchAttachments();
      this.loading = false;
    })
  }

  downloadDocument(attachment: any) {
    const a = document.createElement('a');
    a.href = "data:image/png;base64," + attachment.contents;
    a.setAttribute('download', attachment.originalName)
    a.click();
  }

}

<div class="d-flex flex-column flex-lg-row h-lg-full bg-surface-secondary">
    <app-sidebar [isSideBarOpen]="isSideBarOpen"></app-sidebar>

    <div class="flex-lg-1 h-screen overflow-y-lg-auto min-h-screen">
        <app-header></app-header>

        <main class="py-6 bg-surface-secondary">
            <router-outlet></router-outlet>
        </main>

        <!-- <app-footer></app-footer> -->
    </div>
</div>
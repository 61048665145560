import { DatePipe } from '@angular/common';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { agencyConstant, apiConstant } from 'src/app/app.constant';
import { AlertModal, SuccessModal } from 'src/app/app.model';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { JobAssignmentService } from 'src/app/services/job-assignment.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { TimesheetDetailsComponent } from 'src/app/timesheet-details/timesheet-details.component';
import { SwiperOptions } from 'swiper';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import * as moment from 'moment';
 
@Component({
  selector: 'app-create-timesheet',
  templateUrl: './create-timesheet.component.html',
  styleUrls: ['./create-timesheet.component.css']
})
export class CreateTimesheetComponent implements OnInit {
  @Input() assignment: any;
  assignments: any = []
  timesheetForm: FormGroup | any;
  timesheetFormFields: any = {};
  timesheets: any = [
    // new Date(),
    // new Date(),
    // new Date(),
    // new Date(),
    // new Date(),
    // new Date(),
    // new Date(),
    // new Date(),
  ];
  selectedSlideDate: number = 0
  fields: any;
  error!: string;
  loading: boolean = false;
  public API_CONSTANT = apiConstant;
  start_date: Date | undefined
  end_date: Date | undefined;
  config: SwiperOptions = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    slidesPerView: 4,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 0
  };

  endTimeIsValid: boolean = true;
  copyableValues: any = {}
  disableAssignmentSelect: boolean = false
  preview: boolean = false
  showTotalDays: boolean = false;

  public AGENCY = agencyConstant;
  public agency_uuid: string | null = localStorage.getItem('agency_uuid');
  public is31T: boolean = this.agency_uuid === this.AGENCY['31T'];
  public timesheetDetailsToPreview = { timesheet_details: [] };
  public timesheetFormToPreview!: FormGroup;
  public jobName: string = '';

  constructor(
    private timesheetService: TimesheetService,
    private jobAssignmentService: JobAssignmentService,
    public datePipe: DatePipe,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
    public dataSharedService: DataSharedService,
    public dialog: MatDialog,
  ) { }

  async ngOnInit(): Promise<void> {
    this.timesheetFormFields = {};
    this.timesheetFormFields['assignment_uuid'] = new FormControl('', [Validators.required]);
    this.timesheetForm = new FormGroup(this.timesheetFormFields);

    if (this.assignment) {
      this.timesheetForm.patchValue({
        assignment_uuid: this.assignment.uuid
      })
      this.disableAssignmentSelect = true
    }

    this.loading = true;
    [this.fields] = await new Promise((resolve) => this.timesheetService.getTimesheetForm().subscribe((res: any) => resolve(res.data)));
    this.assignments = await new Promise((resolve) => this.jobAssignmentService.getJobAssignments(1,  {
      'active': 1,
    }).subscribe((res: any) => resolve(res.data)));
    this.fields.fields = this.fields.fields.sort((a: any, b: any) => a.order - b.order);
    this.fields.fields.forEach((field: any) => {
      this.copyableValues[field.label] = '';
    });
    this.loading = false;
  }

  get copyableFieldsIsValid() {
    let isValid = true;
    this.fields?.fields.forEach((field: any) => {
      if (!this.copyableValues[field.label] && field.field_type !== 'static' && field.is_required) {
        isValid = false;
      }
    });
    return isValid;
  }

  onBlurField(fieldName: string, delay: number) {
    setTimeout(() => {
      this.timesheetForm.controls[fieldName].focused = false;
    }, delay);
  }

  copyValues(fieldLabel: string, fieldName: string, idx: number) {
    const fieldValue = this.timesheetForm.controls[fieldName].value;
    this.timesheets.forEach((timesheet: any, i: number) => {
      if (timesheet.index >= idx) {
        this.timesheetForm.patchValue({
          [fieldLabel + '_' + timesheet.index]: fieldValue,
        });
      }
    });
  }

  getDaysCountBetweenTwoDates(start_date: Date, end_date: Date) {
    console.log(start_date, moment(start_date).toDate());
    let startDate = moment(start_date).toDate();
    let endDate = moment(end_date).toDate();
    var totalTime = endDate.getTime() - startDate.getTime();
    return totalTime / (1000 * 3600 * 24) + 1;
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    let startDate = moment(this.start_date).toDate();
    if (this.start_date && this.end_date) {
      const allDates = [];
      var totalDays = this.getDaysCountBetweenTwoDates(this.start_date, this.end_date);
      for (let i = 0; i < totalDays; i++) {
        allDates.push({ date: new Date(new Date(startDate).setDate(startDate.getDate() + i)), index: i });
      }
      this.createSelectedDates(allDates);
    }
  }

  createSelectedDates(dates: any[]) {
    this.selectedSlideDate = 0
    this.timesheets = dates;
    Object.keys(this.timesheetFormFields).forEach((element: any) => {
      if (element !== 'assignment_uuid') {
        delete this.timesheetFormFields[element];
      }
    });
    dates.forEach((date: any, i: number) => {
      this.fields.fields.forEach((field: any) => {
        if (field.field_type !== 'static') {
          this.timesheetFormFields[field.label + '_' + i] = new FormControl('', field.is_required ? [Validators.required] : []);
          this.timesheetForm.patchValue({
            [field.label + '_' + i]: this.copyableValues[field.label],
          })

          if (field.label == 'Days') { 
            this.showTotalDays = true;
          }

          if (field.label == 'Start Time' && field.type === 'time_range') {
            //var today = new Date();
            this.timesheetForm.patchValue({
              //[field.label + '_' + i]: today.getHours()+':'+today.getMinutes(),
              [field.label + '_' + i]: '09:00',
            })
          }
          if (field.label == 'End Time' && field.type === 'time_range') {
            this.timesheetForm.patchValue({
              [field.label + '_' + i]: '17:00',
            })
          }
        }
      });
      this.timesheetFormFields['timesheet_date_' + i] = new FormControl('', [Validators.required])
      this.timesheetForm.patchValue({
        ['timesheet_date_' + i]: date.date,
      })
    });
    //console.log(this.timesheetFormFields, this.timesheetForm)
  }

  removeSlide(i: number, order: number) {
    this.timesheets.splice(i, 1);
    this.selectedSlideDate = this.timesheets[0]?.index ? this.timesheets[0].index : 0;
    delete this.timesheetFormFields['timesheet_date_' + order];
    this.fields.fields.forEach((field: any) => {
      if (field.field_type !== 'static') {
        delete this.timesheetFormFields[field.label + '_' + order];
      }
    });
    if (!this.timesheets.length) {
      this.start_date = undefined;
      this.end_date = undefined;
    }
  }

  get totalDays() {
    let totalDays = 0;
    this.timesheets.forEach((el: any, i: number) => { 
      this.fields.fields.forEach((element: any) => { 
        if (element.field_type !== 'static' && element.label === 'Days') {
          let dayType = this.timesheetForm.controls[element.label + '_' + el.index].value;
          if(dayType != "" && dayType != "undefined") {
            totalDays += (dayType == 'Full Day' ? 1 : 0.5);
          }
        }
      });
    });
    return totalDays;
  }

  get totalHours() {
    let totalHours = 0;
    this.timesheets.forEach((el: any, i: number) => {
      this.fields.fields.forEach((element: any) => {
        
        if (element.field_type !== 'static' && element.label === 'Hours') {
          totalHours += Number(this.timesheetForm.controls[element.label + '_' + el.index].value)
        }
        if (element.field_type !== 'static' && element.label === 'Start Time') {

          let startTime = this.timesheetForm.controls[element.label + '_' + el.index].value;
          let endTime = this.timesheetForm.controls['End Time_' + el.index].value;

          if (startTime && endTime) {
            if (endTime < startTime) {
              totalHours -= Number(this.getHrsCountBetweenTwoTime(startTime, endTime))
            } else {
              totalHours += Number(this.getHrsCountBetweenTwoTime(startTime, endTime))
            }
          }
        }

        // if (element.field_type !== 'static' && element.label === 'Break Hours') { 
        //   console.log(this.timesheetForm.controls[element.label + '_' + el.index].value);
        //   totalHours -= Number(this.timesheetForm.controls[element.label + '_' + el.index].value);
        // }
      });
    })
    return totalHours
  }
  getHrsCountBetweenTwoTime(startTime: string, endTime: string) {
    var inputJSON = {
      "start_date": "2023-01-10 " + startTime,
      "end_date": "2023-01-10 " + endTime
    };
    var startDate = new Date(inputJSON.start_date);
    var endDate = new Date(inputJSON.end_date);

    var diff = (endDate.getTime() - startDate.getTime()) / 1000;

    diff /= (60 * 60);

    return Math.abs(Math.round(diff));
  }

  onSearchChange(event: any) {
    this.timesheetForm.patchValue({
      [event.formControlName]: event.value.uuid
    })
    this.assignment = event.value
  }

  previewTimesheet() {
    this.timesheetDetailsToPreview.timesheet_details = this.timesheets;
    const form: any = {};
    this.timesheetFormToPreview = new FormGroup(form);
    this.timesheetDetailsToPreview.timesheet_details.forEach((timesheet: any, i: number) => {
      this.fields?.fields.forEach((field: any) => {
        if (field.field_type !== 'static') {
          form[field.label + '_' + i] = new FormControl('', field.is_required ? [Validators.required] : []);
          this.timesheetFormToPreview.patchValue({
            [field.label + '_' + i]: this.timesheetForm.get(field.label + '_' + timesheet.index).value,
          })
        }
      });
    })

    this.fields.field_values = this.fields.fields;

    this.jobName = this.assignments.find((x: any) => x.uuid === this.timesheetForm.value?.assignment_uuid).job;
    this.preview = true
  }

  onAssignmentChange() {
    this.assignment = this.assignments.find((x: any) => x.uuid === this.timesheetForm.value.assignment_uuid);
  }

  getLimit(limit: any) {
    if (this.timesheetForm?.value?.assignment_uuid) {
      if (this.assignment) {
        if (limit === 'min') {
          return this.assignment.start_date
        } else {
          return this.assignment.end_date
        }
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  isValid() {
    let isValid: boolean = true;
    this.endTimeIsValid = true;
    this.timesheets.forEach((date: any, i: number) => {
      this.fields.fields.forEach((field: any) => {
        if (field.field_type !== 'static') {
          if (field.label === 'End Time') {
            let startTime = this.timesheetForm?.controls['Start Time_' + date.index].value;
            let endTime = this.timesheetForm.controls[field.label + '_' + date.index].value;
            if (startTime && endTime) {
              if (endTime < startTime) {
                isValid = false;
                this.timesheetForm.controls[field.label + '_' + date.index].errors = true;
                this.endTimeIsValid = false;
              }
            }
          }
          if (field.is_required) {
            if (!this.timesheetForm?.controls[field.label + '_' + date.index].value) {
              isValid = false;
            }
          }
        }
      });
    })
    return isValid;
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.timesheetForm.controls).forEach(element => {
      if (this.timesheetForm.controls[element].value) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }

    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'alert-modal modal-dialog-centered',
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Timesheet Creation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.error = '';
    this.timesheetForm.markAllAsTouched();
    if (!this.isValid()) {
      if (!this.endTimeIsValid) {
        this.error = 'End time should be same or greater than start time.';
      }
      return
    }
    this.loading = true;
    const data: any = {
      assignment_uuid: this.timesheetForm.controls['assignment_uuid'].value,
      form_uuid: this.fields.uuid,
      start_date: this.datePipe.transform(this.start_date, 'YYYY-MM-dd'),
      end_date: this.datePipe.transform(this.end_date, 'YYYY-MM-dd'),
      timesheet_details: []
    }
    this.timesheets.forEach((el: any, i: number) => {
      data.timesheet_details[i] = {
        timesheet_date: this.datePipe.transform(this.timesheetForm?.controls['timesheet_date_' + el.index].value, 'YYYY-MM-dd'),
        fields: []
      };
      this.fields.fields.forEach((element: any) => {
        if (element.field_type !== 'static') {
          data.timesheet_details[i].fields.push({
            uuid: element.uuid,
            values: this.timesheetForm.controls[element.label + '_' + el.index].value
          })
        }
      });
    })
    console.log(data);
    this.timesheetService.createTimesheet(data).subscribe(res => {
      this.activeModalService.close();
      this.loading = false;
      this.successModal('Timesheet Successfully Submitted!');
    }, error => {
      this.loading = false;
      this.error = error;
    });
  }
}

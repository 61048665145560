<div class="loader" *ngIf="loading">
  <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <h3 class="modal-title">Edit Job Assignment</h3>
  <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<div class="modal-body">
  <form [formGroup]="editJobAssignment" class="row g-3" *ngIf="editJobAssignment">
    <div class="vstack gap-5">
      <div class="row g-3">

        <div class="col-md-12">
          <label for="selectTimeSheet">Job Name</label>
          <input formControlName="assignment_uuid" style="display: none;">
          <input formControlName="job_uuid" style="display: none;">
          <input type="text" class="form-control" formControlName="job_name" readonly />
        </div>

        <div class="selectTimeSheet full clientSelect">
          <label for="selectTimeSheet">Timesheet Approvers</label>
          <mat-select class="me-2 input-form" multiple  formControlName="timesheet_approvers" [(ngModel)]="selectedTimesheetApprovers">
              <mat-option *ngFor="let timesheetApprover of timesheetApprovers; let i = index;" [value]="timesheetApprover.uuid">{{timesheetApprover.name}}
              </mat-option>
          </mat-select>	
        </div>

        <div class="col-md-12">
          <label for="selectTimeSheet">Candidate Name</label>
          <input formControlName="candidate_uuid" style="display: none;">
          <input type="text" class="form-control" formControlName="candidate_name" [readonly]="true" />
        </div>

        <div class="col-md-12">
          <label for="selectTimeSheet">Start Date - End Date</label>
          <div class="datePickerRangeContainer" (click)="picker.open()"
            [ngClass]="{'invalidField':(editJobAssignment?.controls['start_date']?.errors && editJobAssignment?.controls['start_date']?.touched) || editJobAssignment?.controls['end_date']?.errors && editJobAssignment?.controls['end_date']?.touched}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <i class="bi bi-calendar4"></i>
            <mat-date-range-input [rangePicker]="picker" [disabled]="!editJobAssignment?.value?.assignment_uuid"
              [min]="minLimit" [max]="maxLimit">
              <input matStartDate formControlName="start_date">
              <input matEndDate formControlName="end_date">
            </mat-date-range-input>
          </div>
        </div>

      </div>
    </div>
  </form>
  <div class="error" *ngIf="error">
    <span>{{error}}</span>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <div class="text-center">
    <button type="button" class="btn btn-sm btn-neutral me-5" (click)="onClose()">
      Cancel
    </button>
    <button type="submit" class="btn btn-sm btn-primary" [disabled]="loading" (click)="onSubmit()">Save</button>
  </div>
</div>
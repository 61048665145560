import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddReminderComponent } from '../components/add-reminder/add-reminder.component';
import { ReminderService } from '../services/reminder.service';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-reminders',
  templateUrl: './reminders.component.html',
  styleUrls: ['./reminders.component.css'],
})
export class RemindersComponent implements OnInit {
  timesheets: any = [];
  sortedReminders: any = [];
  reminderArr: any = [];

  constructor(
    public timesheetService: TimesheetService,
    private reminderService: ReminderService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getReminderSheet();
  }

  async getReminderSheet() {
    this.sortedReminders = [];
    this.reminderArr = [];
    this.reminderArr = await new Promise(resolve => this.reminderService.getReminders().subscribe((res: any) => resolve(res.data)));
    this.sortedReminders = this.reminderArr?.slice();
  }

  sortData(sort: Sort) {
    const data = this.reminderArr?.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedReminders = data;
      return;
    }

    this.sortedReminders = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'message':
          return compare(a.message, b.message, isAsc);
        case 'day':
          return compare(a.day, b.day, isAsc);
        case 'is_active':
          return compare(a.is_active, b.is_active, isAsc);
        case 'time':
          return compare(a.time, b.time, isAsc);
        default:
          return 0;
      }
    });
  }

  onAdd(reminder?: any) {
    const modalRef = this.modalService.open(AddReminderComponent, {
      size: 'md',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-reminder-modal',
    });

    modalRef.componentInstance.reminder = reminder
    modalRef.result.then((data) => {
      if (data?.data === true) {
        this.getReminderSheet();
      }
    });
  }

  async onDelete(index: number) {
    const newArr = this.reminderArr.splice(index, 1);
    localStorage.setItem('reminder', JSON.stringify(this.reminderArr));
    await this.getReminderSheet();
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

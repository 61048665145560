<nav
  class="navbar show navbar-vertical h-lg-screen navbar-expand-lg px-0 py-3 navbar-light bg-white border-bottom border-bottom-lg-0 border-end-lg"
  id="sidebar">
  <div class="container-fluid">

    <!-- Toggler -->
    <button class="navbar-toggler ms-n2" (click)="toggleCollapse()">
      <span class="navbar-toggler-icon"></span>
    </button>

    <!-- Brand -->
    <a class="navbar-brand d-inline-block py-lg-2 mb-lg-5 px-lg-6 me-0 header-logo text-center" routerLink="/dashboard">
      <img src="{{dataSharedService.agencyLogo}}" />
      <!-- <img src="https://clever.webpixels.io/img/logos/clever-primary.svg" /> -->
    </a>

    <!-- User menu (mobile) -->
    <div class="navbar-user d-lg-none">
      <div class="dropdown">
        <a role="button" id="mobile-setting-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
          <div class="avatar-parent-child">
            <img alt="..." src="{{dataSharedService.profileImage}}" class="avatar avatar- rounded-circle">
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end" id="mobile-setting-dropdown">
          <div class="dropdown-header">
            <span class="d-block text-sm text-muted mb-1 text-start">Signed in as</span>
            <span class="d-block text-heading font-semibold text-start">{{user.name}}</span>
          </div>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/dashboard">
            <i class="bi bi-house me-3"></i>Home
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="/settings">
            <i class="bi bi-gear me-3"></i>Settings
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/logout">
            <i class="bi bi-person me-3"></i>Logout
          </a>
        </div>
      </div>
    </div>


    <!-- Collapse -->
    <div class="collapse navbar-collapse {{visible ? 'show dinesh-chandel': 'dinesh-chandel'}}" id="sidebarCollapse">
      <ul class="navbar-nav" id="sidebar-nav">
        <li class="nav-item" *ngFor="let permissionMenu of permissionsMenu">
          <a class="nav-link collapsed weight d-flex align-items-center justify-content-between"
            [routerLink]="permissionMenu.path" routerLinkActive="sidebar-nav-active"
            [matTooltip]="isSideBarOpen ? null:permissionMenu.name" [matTooltipPosition]="'right'"
            *ngIf="!permissionMenu.hasSubMenu" data-bs-toggle="collapse" (click)="toggleCollapse()">
            <div class="left d-flex align-items-center">
              <div class="tabIcon p-2">
                <i [ngClass]="permissionMenu.icon"></i>
              </div>
              {{permissionMenu.name}}
            </div>
          </a>

          <div *ngIf="permissionMenu.hasSubMenu">
            <a class="nav-link collapsed weight d-flex align-items-center justify-content-between" href="javascript:;"
              (click)="showSubMenuDropdown(permissionMenu.hasSubMenu)"
              [matTooltip]="isSideBarOpen ? null:permissionMenu.name" [matTooltipPosition]="'right'"
              data-bs-toggle="collapse" (click)="toggleCollapse()">
              <i [ngClass]="permissionMenu.icon"></i>
              {{permissionMenu.name}}
            </a>
            <div class="collapse dropdown-menus show" *ngIf="showReportSubmenu">
              <ul class="nav nav-sm flex-column">
                <li class="nav-item" *ngFor="let childMenu of permissionMenu.subMenus.list">
                  <a href="javascript:;" class="nav-link" [routerLink]="childMenu.path"
                    routerLinkActive="sidebar-nav-active" [matTooltip]="isSideBarOpen ? null:childMenu.name"
                    [matTooltipPosition]="'right'" (click)="toggleCollapse()">
                    <span *ngIf="isSideBarOpen">{{childMenu.name}}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<!-- Sidebar End -->
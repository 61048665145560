import { Component, OnInit } from '@angular/core';
import { BoardService } from '../services/board.service';
@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {

  public missingTimesheets: any = [];
  public awaitingApprovalTimesheets: any = [];
  public missingTimesheetsCount: any = 0;
  public awaitingApprovalTimesheetsCount: any = 0;

  constructor(
    public boardService: BoardService
  ) { }

  ngOnInit(): void {
    this.boardService.getBoard().subscribe((board:any) => {
      this.missingTimesheets = board.data.missing_timesheets;
      this.awaitingApprovalTimesheets = board.data.awaiting_approvals;
      this.missingTimesheetsCount = board.data.missing_timesheets_count;
      this.awaitingApprovalTimesheetsCount = board.data.awaiting_approvals_count;
    });
  }

}

import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { ActivateDeactivateUser, AgencyLogo, CurrencyFormat, DateFormat, OverTimeSetting, OverTimeSettings, ProfileImage, Settings, TimesheetDetails, TimesheetNotificationSettings } from '../app.model';
import { TimesheetService } from './timesheet.service';
import * as moment from 'moment';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class DataSharedService {
  agencyLogo!: string;
  profileImage: any = localStorage.getItem('profile_pic');
  permissionData: any = null;
  dateFormat: any = localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : '';
  candidateTrackingHrs: any = localStorage.getItem('candidateTrackingHrs') ? localStorage.getItem('candidateTrackingHrs') : 1;
  timesheetSubmissionEmails:any = localStorage.getItem('timesheetSubmissionEmails') ? localStorage.getItem('timesheetSubmissionEmails') : 0;
  timesheetApprovalEmails:any = localStorage.getItem('timesheetApprovalEmails') ? localStorage.getItem('timesheetApprovalEmails') : 0;
  showAgencyLogoEmail:any = localStorage.getItem('showAgencyLogoEmail') ? localStorage.getItem('showAgencyLogoEmail') : 0;
  dateFormatList: any = [
    'MM-dd-YYYY',
    'dd-MM-YYYY',
    'MM-dd-YY',
    'dd-MM-YY',
    'yyyy-MM-dd',
    // 'yyyy-dd-MM',
    // 'dd-MM-yyyy',
    // 'MM-dd-yyyy',
    // 'yyyy/MM/dd',
    // 'yyyy/dd/MM',
    // 'dd/MM/yyyy',
    // 'MM/dd/yyyy',
    // 'dd MMM, yyyy',
    // 'MMM dd, yyyy',
  ];
  currencyFormatList: any = [
    '$',
    '£',
    '€',
  ];
  private subject = new Subject<any>();
  
  constructor(
    private timesheetService: TimesheetService,
    private http: HttpClient,
    private datePipe: DatePipe
  ) {
    if (localStorage.getItem('user')) {
      this.setAgencyLogo()
      this.setProfileImage();
    }
  }

  sendUpdate(message: string) {
    this.subject.next({message: message});
  }

  getUpdate(): Observable<any> {
    return this.subject.asObservable();
  }

  getFormatedDate(date: string) {
    this.dateFormat = localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : '';
    try {
      return this.datePipe.transform(String(new Date(date)), (this.dateFormat ? this.dateFormat : 'YYYY-MM-dd'));
    } catch (e) {
      return date
    }
  }

  getFormatedDateUTC(date: string) {
    this.dateFormat = localStorage.getItem('dateFormat') ? localStorage.getItem('dateFormat') : '';
    try {
      return this.datePipe.transform(String(new Date(date).toLocaleString("en-US", {timeZone: "UTC"})), (this.dateFormat ? this.dateFormat : 'YYYY-MM-dd'));
    } catch (e) {
      return date
    }
  }

  setProfileImage() {
    this.profileImage = localStorage.getItem('profile_pic');
  }

  getImage(imageUrl: string){
    return imageUrl
  }

  setAgencyLogo() {
    return new Promise(resolve => {
      this.getSettings().subscribe((res: any) => {
          const agencyLogo = res.data.find((x: any) => x.key === 'agency-logo');
          const dateFormat = res.data.find((x: any) => x.key === 'date-format');
          const candidateTrackingHrs = res.data.find((x: any) => x.key === 'track-hours');
          const timesheetSubmissionEmails = res.data.find((x:any) => x.key == 'timesheet-submission-emails');     
          const timesheetApprovalEmails = res.data.find((x:any) => x.key == 'timesheet-approval-emails'); 
          const showAgencyLogoEmail = res.data.find((x:any) => x.key == 'show-agency-logo-email'); 
          
          if (dateFormat !== undefined && dateFormat !== "") {
            localStorage.setItem('dateFormat', dateFormat.value);
          }	

          if (agencyLogo !== undefined && agencyLogo !== "") { 
            this.agencyLogo = agencyLogo.value;
            resolve(this.agencyLogo);
          }
          
          if (candidateTrackingHrs !== undefined && candidateTrackingHrs !== "") {
            localStorage.setItem('candidateTrackingHrs', candidateTrackingHrs.value);
          }		
              
          if(timesheetSubmissionEmails !== undefined && timesheetSubmissionEmails != "") {
            localStorage.setItem('timesheetSubmissionEmails', timesheetSubmissionEmails.value);
            this.timesheetSubmissionEmails = timesheetSubmissionEmails.value;
            resolve(this.timesheetSubmissionEmails);
          }

          if(timesheetApprovalEmails !== undefined && timesheetApprovalEmails != "") {
            localStorage.setItem('timesheetApprovalEmails', timesheetApprovalEmails.value);
            this.timesheetApprovalEmails = timesheetApprovalEmails.value
            resolve(this.timesheetApprovalEmails);
          } 
          
          if(showAgencyLogoEmail !== undefined && showAgencyLogoEmail != "") {
            localStorage.setItem('showAgencyLogoEmail', showAgencyLogoEmail.value);
            this.showAgencyLogoEmail = showAgencyLogoEmail.value
            resolve(this.showAgencyLogoEmail);
          }
        });
    })
  }

  getSettings() {
    return this.http.get<Settings>(`${environment.baseUrl}` + apiConstant.SETTINGS);
  }

  uploadProfileImage(image: any) {
    return this.http.post<ProfileImage>(`${environment.baseUrl}` + apiConstant.SET_PROFILE_PIC, { profile_pic: image });
  }

  uploadAgencyLogo(image: any) {
    return this.http.post<AgencyLogo>(`${environment.baseUrl}` + apiConstant.SET_AGENCY_LOGO, { logo: image });
  }

  setDateFormat(format: any) {
    return this.http.post<DateFormat>(`${environment.baseUrl}` + apiConstant.SET_DATE_FORMAT, { date_format: format});
  }

  setCurrencyFormat(format: any) {
    return this.http.post<CurrencyFormat>(`${environment.baseUrl}` + apiConstant.SET_CURRENCY_FORMAT, { currency_format: format});
  }
  
  setTrackHrs(trackingHrs: any) {
    return this.http.post<DateFormat>(`${environment.baseUrl}` + `set-track-hours`, { set_track_hours: trackingHrs });
  }

  setAgencyLogoEmail(setAgencyEmailLogo: any) {
    return this.http.post<DateFormat>(`${environment.baseUrl}` + `set-email-template-settings`, { show_agency_logo_email: setAgencyEmailLogo });
  }

  setAgencyTimesheetNotifications(timesheetSubmissionEmails : boolean, timesheetApprovalEmails: boolean) {
    return this.http.post<DateFormat>(`${environment.baseUrl}` + `agency-timesheet-notifications`, { timesheet_submission_emails: timesheetSubmissionEmails, timesheet_approval_emails: timesheetApprovalEmails});
  }
  getTimesheetNotificationSettings() {
    return this.http.get<TimesheetNotificationSettings>(`${environment.baseUrl}` + `timesheet-notification-settings`);
  }
  updateTimesheetNotificationSettings(data: any) {
    return this.http.patch<TimesheetNotificationSettings>(`${environment.baseUrl}` + `timesheet-notification-settings`, { email_type: data.email_type, frequency: data.frequency, day: data.day,  time: data.time });
  }

  getOverTimeSettings() {
      return this.http.get<OverTimeSettings>(`${environment.baseUrl}` + apiConstant.OVERTIME_SETTINGS);
  }

  updateOverTimeSettings(overTimeSettings: any) {
      return this.http.post<ActivateDeactivateUser>(`${environment.baseUrl}` +  apiConstant.OVERTIME_SETTINGS, { overtime_settings: overTimeSettings });
  }
}
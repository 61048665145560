import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AddReminderComponent } from '../components/add-reminder/add-reminder.component';
import { roleConstant } from '../app.constant';
import { ChangePasswordComponent } from '../change-password/change-password.component';
import { ModalComponent } from '../components/modal/modal.component';
import { CreateFormComponent } from '../components/create-form/create-form.component';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {
  selectedAgencyLogo: string = '';
  user: any;
  ROLES = roleConstant;

  constructor(
    private modalService: NgbModal,
    private dataSharedService: DataSharedService,
    private router: Router,
    private authService: AuthenticationService,
  ) {
    this.user = this.authService.userValue;
  }

  ngOnInit(): void {
    // if(this.router.url === '/settings'){
    //   if(this.user.role_uuid === this.ROLES.AGENCY){
    //     this.router.navigate(['/settings/general']); 
    //   }else{
    //     this.router.navigate(['/settings/change-password']); 
    //   }
    // }
  }

  openTimesheetDetails() {
    const modalRef = this.modalService.open(ModalComponent, { size: 'xl' });
  }

  gotoForms() {
    this.router.navigate(['/timesheet-forms']);
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }

  addReminder() {
    this.router.navigate(['/reminders']);
  }

  async onImageChange(imageInput: any) {
    const file: File = imageInput.files[0];
    const reader = new FileReader();

    reader.readAsDataURL(file);
    this.selectedAgencyLogo = await new Promise(resolve => {
      reader.addEventListener('load', (event: any) => {
        resolve(new ImageSnippet(event.target.result, file).src);
      });
    })
    console.log(this.selectedAgencyLogo);
  }

  uploadAgencyLogo() {
    this.dataSharedService.uploadAgencyLogo(this.selectedAgencyLogo).subscribe((res: any) => {
      console.log(res);
    })
  }

}

class ImageSnippet {
  constructor(public src: string, public file: File) { };
};

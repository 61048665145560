import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from 'src/app/app.model';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { CommunicationService } from 'src/app/services/communication.service';
import { SuccessModal } from 'src/app/app.model';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  @Input() userUuid: any;
  @Input() userEmail: any;

  loading: boolean = false

  @Input() userMessage:any;
  @Input() userSubject:any;
  @Input() clientUuid: any;
  @Input() agencyUuid: any;
  @Input() timesheetUuid: any;
  sendEmailForm: FormGroup | any;

  constructor(
    public communicationService: CommunicationService,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,) { }

  ngOnInit(): void {
    this.sendEmailForm = new FormGroup({
      message: new FormControl(this.userMessage, Validators.required),
      subject: new FormControl(this.userSubject, Validators.required),
    });
  }

  onSend() {
    let sendEmailData = this.sendEmailForm.value;

    if (this.userUuid) {
      sendEmailData = {
        ...sendEmailData,
        user_uuid: this.userUuid,
      };
    }

    if (this.clientUuid && this.agencyUuid && this.timesheetUuid) {
      sendEmailData = {
        ...sendEmailData,
        client_uuid: this.clientUuid,
        timesheet_uuid: this.timesheetUuid,
        agency_uuid: this.agencyUuid,
      };
    }

    if(this.userEmail) {
      sendEmailData = {
        ...sendEmailData,
        to: this.userEmail,
      }
    }
    
    this.communicationService.sendEmail(sendEmailData).subscribe(res => {
      this.activeModalService.close({
        data: true,
      });
      this.successModal('Sent Succesfully');
    });

    
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onClose() {
    this.activeModalService.close();
  }
}

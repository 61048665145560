import { Component, Input, OnInit } from '@angular/core';
import { SuccessModal } from 'src/app/app.model';
import { UserService } from 'src/app/services/user.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-add-end-employment-date',
  templateUrl: './add-end-employment-date.component.html',
  styleUrls: ['./add-end-employment-date.component.css']
})
export class AddEndEmploymentDateComponent implements OnInit {
  @Input() userUuid: any;
  employmentEndDate: string = '';
  deactivateUserForm: FormGroup | any;
  constructor(
    public userService: UserService,
    private router: Router,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
    private datePipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.deactivateUserForm = new FormGroup({
      employment_end_date: new FormControl(this.employmentEndDate),
    });
  }

  onDeactivate(): void {
    const form = this.deactivateUserForm.value;
    form.employment_end_date   = this.datePipe.transform(form.employment_end_date, 'yyyy-MM-dd');
     
    this.userService.deactivateAccount(this.userUuid,form).subscribe({
      next: (response) => {
          this.activeModalService.close({
            data: true,
          });
          this.successModal('Deactivated Successfully.');
          this.router.navigate(['candidates/inactive-candidates'])
      },
      error: (err) => {
      }
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }


}

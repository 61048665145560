<div class="formContainer p-7">
    <form [formGroup]="deactivateUserForm" class="reminderForm">
        <div class="fieldsContainer">
            <div class="selectTimeSheet">
                <label for="selectTimeSheet">Employment End Date</label>
                <div class="datePickerContainer" (click)="picker.open()">
                    <input matInput [matDatepicker]="picker" formControlName="employment_end_date">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker> 
                    <i class="bi bi-calendar4"></i>
                </div>
            </div>
        </div>
    </form>
    <div class="submitForm">
        <button type="submit" (click)="onDeactivate()">Deactivate</button>
    </div>
</div>

import { count } from 'rxjs';
import { DatePipe, Location } from '@angular/common';
import { ActivatedRoute, Route } from '@angular/router';
import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { TimesheetService } from '../../services/timesheet.service';


@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.css'],
})

export class CreateFormComponent implements OnInit {
  formName!: string;
  error = '';
  fields: any = [
    { is_required: 1, field_type: 'static', type: 'readonly', order: 0, label: 'Job', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'static', type: 'readonly', order: 1, label: 'Contractor', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'static', type: 'readonly', order: 2, label: 'Client', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'static', type: 'readonly', order: 6, label: 'Date', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'static', type: 'readonly', order: 3, label: 'Start Date', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'static', type: 'readonly', order: 4, label: 'End Date', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'static', type: 'readonly', order: 5, label: 'Status', values: true, has_hours: 0, show_to_client: 0 },
    { is_required: 1, field_type: 'dynamic', type: 'text', order: 7, label: '' }
  ]
  preview: boolean = false;
  fromEdit: boolean = false;
  timesheetDetails: any = [
    {
      client_name: "Ripping-Stark",
      job_occupation: "Finance Manager",
      contractor_detail: "Emma Green",
      date: "2023-01-22",
      start_date: "2023-01-23",
      end_date: "2023-06-19",
      hours: "234hrs",
      status: "Complete",
      days: "Full Day, Half Day"
    },
    {
      client_name: "Pfeffer-Fay",
      job_occupation: "Product Manager",
      contractor_detail: "Steve Joseph",
      date: "2023-02-05",
      start_date: "2023-02-05",
      end_date: "2023-06-13",
      hours: "112hrs",
      status: "Complete",
      days: "Full Day, Half Day"
    },
    {
      client_name: "Jack Henderson",
      job_occupation: "UX/UI Designer",
      contractor_detail: "Shwe Jin",
      date: "2023-03-27",
      start_date: "2023-03-27",
      end_date: "2023-11-12",
      hours: "167hrs",
      status: "Complete",
      days: "Full Day, Half Day"
    },
    {
      client_name: "Danny Stubbs",
      job_occupation: "Backend Developer",
      contractor_detail: "Jone",
      date: "2023-05-01",
      start_date: "2023-05-27",
      end_date: "2023-10-13",
      hours: "139hrs",
      status: "Complete",
      days: "Full Day, Half Day"
    },
  ]
  formData: any;
  loading: boolean = false;
  formCount: any = 0;

  constructor(
    private timesheetService: TimesheetService,
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private location: Location
  ) {
    if (this.route.snapshot.queryParams['preview']) {
      this.preview = JSON.parse(this.route.snapshot.queryParams['preview']);
      this.fromEdit = !this.preview;
    }
    if (this.route.snapshot.queryParams['formData']) {
      this.formData = JSON.parse(this.route.snapshot.queryParams['formData'])
    }
  }

  ngOnInit(): void {
    if (this.formData) {
      this.fetchForm()
    }
  }

  ngAfterViewInit(): void {
    this.disableHours()
  }

  close() {
    this.location.back();
  }

  changeForm(form_section: boolean) {
    this.preview = form_section;
    this.fromEdit = !this.preview;

    console.log("changeForm this.preview -------", this.preview);
    console.log("changeForm this.fromEdit -------", this.fromEdit);
  }

  fetchForm() {
    this.fields = [];
    this.formName = this.formData.name;
    this.formData.fields = this.formData.fields.sort((a: any, b: any) => a.order - b.order)
    this.formData.fields.forEach((element: any) => {
      var field = element;
      field.is_required = field.is_required ? true : false;
      field.has_hours = field.has_hours ? 1 : 0;
      field.show_to_client = field.show_to_client ? 1 : 0;
      if (element.type === 'dropdown' && element.values) {
        field.options = [];
        const options = element.values.split(',')
        options.forEach((option: any) => {
          field.options.push({ name: option });
        });
      } else if (element.type === 'readonly') {
        field.values = field.values === 'Yes' ? true : false;
      }
      this.fields.push(field);
    });
    this.formCount = this.fields.length;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.fields, event.previousIndex, event.currentIndex);
    this.fields.forEach((element: any, i: number) => {
      element.order = i;
    });
  }

  addField() {
    this.fields.push({ is_required: 1, field_type: 'dynamic', type: 'text', order: this.fields.length, label: '' })
  }

  removeField(i: number) {
    this.fields.splice(i, 1)
  }

  onTypeChange(event: any, i: number) {
    if (event.target.value === 'dropdown') {
      this.fields[i].options = [{ name: 'Option 1' }];
    } else {
      delete this.fields[i].options
    }
  }

  addOption(i: number) {
    this.fields[i].options.push({ name: `Option ${this.fields[i].options.length + 1}` });
  }

  removeOption(i: number, optionIdx: number) {
    this.fields[i].options.splice(optionIdx, 1);
  }

  isOptionsValid(options: any[]): boolean {
    let valid = true;
    options?.forEach(element => {
      if (!element?.name) {
        valid = false;
      }
    });
    return valid
  }

  enableHours(event: any, index: number) {
    const count = this.formCount;
    if (event.target.checked) {
      for (let i = 0; i < count; i++) {
        if (index != i) {
          const id = "hours_" + i;
          const checkbox = document.getElementById(id) as HTMLInputElement;
          checkbox.disabled = true;
          checkbox.checked = false;
        }
      }
    } else {
      for (let i = 0; i < count; i++) {
        if (index != i) {
          const id = "hours_" + i;
          const checkbox = document.getElementById(id) as HTMLInputElement;
          checkbox.disabled = false;
          checkbox.checked = false;
        }
      }
    }
  }

  disableHours() {
    const count = this.formCount;
    let checked = false;
    for (let i = 0; i < count; i++) {
      const id = "hours_" + i;
      let checkbox = document.getElementById(id) as HTMLInputElement;
      const value = checkbox.attributes['3'].value;
      if (value === "0") {
        checkbox.disabled = true;
      } else {
        checked = true;
        checkbox.disabled = false;
      }
    }
    if (!checked) {
      for (let i = 0; i < count; i++) {
        const id = "hours_" + i;
        let checkbox = document.getElementById(id) as HTMLInputElement;
        checkbox.disabled = false;
      }
    }
  }

  get isValid(): boolean {
    let valid = true;
    this.fields.forEach((singleField: any) => {
      if (((singleField.type === 'dropdown' && !singleField?.options?.length) || (!this.isOptionsValid(singleField.options))) || (singleField.type !== 'dropdown' && singleField.type !== 'readonly' && !singleField.label)) {
        valid = false;
      }
    });
    return valid && (this.formName ? true : false);
  }

  submitForm() {
    this.error = '';
    if (!this.isValid) {
      this.error = 'Please fill all the fields';
      return;
    }
    this.loading = true;
    const newForm = { form_name: this.formName, form_fields: this.fields }
    let has_hours = false;
    newForm.form_fields.forEach((singleForm: any) => {
      singleForm.is_required = singleForm.is_required ? 1 : 0;
      singleForm.has_hours = singleForm.has_hours ? 1 : 0;
      if (has_hours) {
        singleForm.has_hours = 0;
      }
      if (singleForm.has_hours) {
        has_hours = true;
      }
      singleForm.show_to_client = singleForm.show_to_client ? 1 : 0;
      if (singleForm.type === 'dropdown') {
        singleForm.values = ''
        singleForm.options.forEach((singleOption: any) => {
          if (singleForm.values) {
            singleForm.values += ',' + singleOption.name;
          } else {
            singleForm.values = singleOption.name;
          }
        });
        delete singleForm.options;
      } else if (singleForm.type === 'readonly') {
        singleForm.values = singleForm.values ? 'Yes' : 'No';
      } else {
        singleForm.values = '';
      }
    });

    this.timesheetService.submitTimesheetForm(newForm, this.formData?.uuid).subscribe(res => {
      this.location.back();
      this.loading = false;
    }, err => {
      console.error(err);
      this.loading = false;
    });
  }

}

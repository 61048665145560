
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-create',
  templateUrl: './client-create.component.html',
  styleUrls: ['./client-create.component.css']
})
export class ClientCreateComponent implements OnInit {

  error!: string;
  loading: boolean = false;

  formValid: boolean = false;
  client_form: FormGroup | any;
  email_type: number = 0;
  frequency: number = 1;
  day_to_receive: number = 1;
  time_to_receive: string = '17:00';
  client_contacts: any[] = [{
    id: 1,
    first_name: '',
    last_name: '',
    email: '',
    send_invite: true,
    timesheet_notification_settings: {
      email_type: this.email_type,
      frequency: this.frequency,
      day: this.day_to_receive,
      time: this.time_to_receive,
    }
  }];


  constructor(
    public router: Router,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    this.client_form = new FormGroup({
      name: new FormControl('', Validators.required),
    });
  }

  onAddClientContact() {
    this.error = '';
    this.formValid = false;

    this.client_contacts.push({
      id: this.client_contacts.length + 1,
      first_name: '',
      last_name: '',
      email: '',
      send_invite: true,
      timesheet_notification_settings: {
        email_type: this.email_type,
        frequency: this.frequency,
        day: this.day_to_receive,
        time: this.time_to_receive,
      }
    });
  }

  ValidateEmail(email: string) {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  async clientValidation(event: any) {

    this.error = '';
    let hasErr = false;

    for (var i = 0; i < this.client_contacts.length; i++) {

      let sectionId = this.client_contacts[i].id;

      if (this.client_contacts[i].timesheet_notification_settings.email_type == 1) {
        if (this.client_contacts[i].timesheet_notification_settings.time.trim() == '') {
          let timeElement = document.getElementById('time_' + sectionId);
          if (timeElement !== null) {
            hasErr = true;
          }
        }
      }

      if (this.client_contacts[i].email.trim() == '') {
        let emailElement = document.getElementById('email_' + sectionId);
        if (emailElement !== null) {
          hasErr = true;
        }
      } else {
        if (!this.ValidateEmail(this.client_contacts[i].email)) {
          let emailElement = document.getElementById('email_' + sectionId);
          if (emailElement !== null) {
            hasErr = true;
          }
        }
      }

      if (this.client_contacts[i].last_name.trim() == '') {
        let lnElement = document.getElementById('lastName_' + sectionId);
        if (lnElement !== null) {
          hasErr = true;
        }
      }

      if (this.client_contacts[i].first_name.trim() == '') {
        let fnElement = document.getElementById('firstName_' + sectionId);
        if (fnElement !== null) {
          hasErr = true;
        }
      }
    }


    let client_name_element = document.getElementById('client_name');
    if (this.client_form.controls.name.errors || this.client_form.controls.name.value.trim() == '') {
      if (client_name_element !== null) {
        hasErr = true;
      }
    }


    if (event.target.value.trim().length < 1) {
      event.target.classList.add('invalidField');
    } else {
      event.target.classList.remove('invalidField');
    }

    if (hasErr) {
      this.formValid = false;
    } else {
      this.formValid = true;
    }
  }

  removeContact(i: number) {
    this.client_contacts.splice(i, 1);
    this.error = '';
  }

  onSubmit() {
    this.loading = true;
    const payload = { client: this.client_form.value, client_contacts: this.client_contacts };
    this.clientService.createClient(payload).subscribe(response => {
      this.loading = false;
      this.router.navigate(['/clients']);
    }, error => {
      this.loading = false;
      this.error = error;
    });
  }

}

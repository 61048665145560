import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { apiConstant } from '../app.constant';
import { environment } from 'src/environments/environment';
import { BoardData } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class BoardService {

  constructor(private http: HttpClient) { }

  getBoard() {
    return this.http.get<BoardData>(`${environment.baseUrl}` + apiConstant.BOARD, {});
  }

  getAllMissingTimesheets() {
    return this.http.get(`${environment.baseUrl}` + apiConstant.SHOW_ALL_MISSING_TIMESHEETS, {});
  }
  getAllAwaitingApprovals() {
    return this.http.get(`${environment.baseUrl}` + apiConstant.SHOW_ALL_AWAITING_APPROVALS, {});
  }

}

<div class="container">
  <button class="border" type="button" (click)="selectFile($event, inputFile)">
    <div class="body">
      <div class="upload">
        <i class="bi bi-upload"></i>
      </div>
      <div class="text">
        <form [formGroup]="myForm">
          <div class="text1">
            <label for="file">Upload a file or drag and drop</label>
            <input [accept]="acceptFiles.join(',')" #inputFile formControlName="file" id="filechose_button" type="file"
              (change)="onFileChange($event)">
          </div>
        </form>
        <div class="text2">
          <label for="">PDF, JPG, PNG upto 3MB</label>
        </div>
      </div>
    </div>
  </button>
  <div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  <div class="subBody" *ngFor="let attachment of attachmentsArr">
    <div class="right">
      <div class="box">
        <img class="imageIcon" *ngIf="!getFileIcon(attachment)" [src]="attachment.contents" />
        <img class="imageIcon" *ngIf="getFileIcon(attachment)" [src]="getFileIcon(attachment)" />
      </div>
      <div class="textarea">
        <div class="nameText">
          <label>{{ attachment.originalName }}</label>
        </div>
        <div class="subtext">
          <label for="">Added on {{dataSharedService.getFormatedDate(attachment.addedDate)}}</label>
        </div>
      </div>
    </div>
    <div class="left">
      <div class="smallBox">
        <button (click)="downloadDocument(attachment)">
          <img class="smallImg" src="../../../assets/img/icons8-downloading-updates-40.png" />
        </button>
      </div>
      <div class="smallBoxThree" (click)="deleteDocuments(attachment.uuid)">
        <img class="ellipsis" src="../../../assets/extIcons/icons8-delete-24.png" />
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { roleConstant } from '../app.constant';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
import { AlertModal, SuccessModal } from 'src/app/app.model';

@Component({
  selector: 'app-general-tab',
  templateUrl: './general-tab.component.html',
  styleUrls: ['./general-tab.component.css']
})
export class GeneralTabComponent implements OnInit {
  user: any;
  ROLES = roleConstant;
  agencyLogo: any = {
    selectedImage: '',
    imageSelected: false,
    imageChangedEvent: '',
    uploadButtonText: 'Select',
    uploadButtonIcon: 'bi bi-eyedropper'
  }
  profileImage: any = {
    selectedImage: '',
    imageSelected: false,
    imageChangedEvent: '',
    uploadButtonText: 'Select',
    uploadButtonIcon: 'bi bi-eyedropper'
  }
  TimesheetApprovalSuccessfullySet = false;
  TrackingTimeSuccessfullySet = false;
  trackingTimeErr: string = '';
  saveAgencyTimesheetSettingsErr: string = '';
  timesheetApprovalErr: string = '';	
  candidateTrackingHrs: any = localStorage.getItem('candidateTrackingHrs') ? localStorage.getItem('candidateTrackingHrs') : 1; 
  timesheetSubmissionEmails:any = localStorage.getItem('timesheetSubmissionEmails') ? localStorage.getItem('timesheetSubmissionEmails') : 0;
  timesheetApprovalEmails:any = localStorage.getItem('timesheetApprovalEmails') ? localStorage.getItem('timesheetApprovalEmails') : 0;  
  showAgencyLogoEmail:any = localStorage.getItem('showAgencyLogoEmail') ? localStorage.getItem('showAgencyLogoEmail') : 0;  
  timesheetNotificationSettings: any = {
				email_type: 0,
				frequency: 1, 
				day: 1,
				time: '17:00',
			};
      
  constructor(
    public dataSharedService: DataSharedService,
    private authService: AuthenticationService,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
  ) {
    this.user = this.authService.userValue;
  }

  async ngOnInit(): Promise<void> {
    await this.dataSharedService.setAgencyLogo();

    this.agencyLogo.selectedImage = this.dataSharedService.agencyLogo;
    this.profileImage.selectedImage = this.dataSharedService.profileImage;
	  this.userTimesheetSetting();
  }
	
  userTimesheetSetting(){
		if(this.user.role_uuid === this.ROLES.AGENCY){
			this.dataSharedService.getTimesheetNotificationSettings().subscribe((res: any) => {
				if(res.data.length > 0){
					this.timesheetNotificationSettings = res.data[0];
				}
			});
		}
	}	
	
  reset(image: any, type: string){
    if(type === 'agencyLogo'){
      image.selectedImage = this.dataSharedService.agencyLogo;
    } else if (type === 'profileImage') {
      image.selectedImage = this.dataSharedService.profileImage;
    }
    image.imageSelected = false;
    image.imageChangedEvent = '';
    image.uploadButtonText = 'Select',
      image.uploadButtonIcon = 'bi bi-eyedropper'
  }

  uploadImage(type: string) {
    if (type === 'agencyLogo') {
      this.agencyLogo.uploadButtonText = 'Uploading...';
      this.dataSharedService.uploadAgencyLogo(this.agencyLogo.selectedImage).subscribe((res: any) => {
        this.agencyLogo.uploadButtonText = 'Uploaded';
        this.dataSharedService.setAgencyLogo();
        setTimeout(() => {
          this.reset(this.agencyLogo, type);
        }, 1500);
      })
    } else if (type === 'profileImage') {
      this.profileImage.uploadButtonText = 'Uploading...'
      this.dataSharedService.uploadProfileImage(this.profileImage.selectedImage).subscribe((res: any) => {
        this.profileImage.uploadButtonText = 'Uploaded';
        localStorage.setItem('profile_pic', this.profileImage.selectedImage);
        this.dataSharedService.setProfileImage();
        setTimeout(() => {
          this.reset(this.profileImage, type);
        }, 1500);
      })
    }
  }

  fileChangeEvent(event: any, type: string): void {
    if (type === 'agencyLogo') {
      this.agencyLogo.imageChangedEvent = event;
      this.agencyLogo.imageSelected = true;
      this.agencyLogo.uploadButtonText = 'Upload';
      this.agencyLogo.uploadButtonIcon = 'bi bi-cloud-arrow-up-fill'
    } else if (type === 'profileImage') {
      this.profileImage.imageChangedEvent = event;
      this.profileImage.imageSelected = true;
      this.profileImage.uploadButtonText = 'Upload';
      this.profileImage.uploadButtonIcon = 'bi bi-cloud-arrow-up-fill'
    }
  }

  imageCropped(event: ImageCroppedEvent, type: string) {
    if (type === 'agencyLogo') {
      this.agencyLogo.selectedImage = event.base64;
    } else if (type === 'profileImage') {
      this.profileImage.selectedImage = event.base64;
    }
  }

  imageLoaded() {
    /* show cropper */
  }

  cropperReady() {
    /* cropper ready */
  }

  loadImageFailed() {
    /* show message */
  }

  async saveCandidateTrackingHrs() {
    this.trackingTimeErr = '';
    localStorage.setItem('candidateTrackingHrs', this.candidateTrackingHrs);
    await this.dataSharedService.setTrackHrs(this.candidateTrackingHrs).subscribe(
		res => {
		  this.successModal('Updated Successfully!');      
		}, error => {
		  this.trackingTimeErr = error;
    });
  }
  
  async saveAgencyLogoEmail() {
    this.trackingTimeErr = '';
    localStorage.setItem('showAgencyLogoEmail', this.showAgencyLogoEmail);
    await this.dataSharedService.setAgencyLogoEmail(this.showAgencyLogoEmail).subscribe(
		res => {
		  this.successModal('Updated Successfully!');      
		}, error => {
		  this.trackingTimeErr = error;
    });
  }

  async saveAgencyTimesheetSettings() {
    this.saveAgencyTimesheetSettingsErr;
    localStorage.setItem('timesheetSubmissionEmails', this.timesheetSubmissionEmails);	
    localStorage.setItem('timesheetApprovalEmails', this.timesheetApprovalEmails);	
    await this.dataSharedService.setAgencyTimesheetNotifications(this.timesheetSubmissionEmails, this.timesheetApprovalEmails).subscribe(
      res => {
        this.successModal('Updated Successfully!');      
      }, error => {
        this.trackingTimeErr = error;
      });
  }

  //async saveOverTimeSettings() {}
	async saveTimesheetNotificationSettings(){
	this.timesheetApprovalErr = '';
    await this.dataSharedService.updateTimesheetNotificationSettings(this.timesheetNotificationSettings).subscribe({
      next: (response) => {
        this.successModal('Updated Successfully!');
      },
      error: (err) => {
        this.timesheetApprovalErr = err;
      },
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }
}
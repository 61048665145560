<div class="modal-header">
    <h5 class="modal-title">"{{client?.name}}" Contacts</h5>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>
<!-- Table with hoverable rows -->
<div class="modal-body">

    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
    
    <div class="table-responsive">
        <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap">
            <thead class="table-light" *ngIf="allClientContacts?.length > 0">
                <tr>
                    <th mat-sort-header="name" scope="col">Name</th>
                    <th mat-sort-header="created_at" scope="col">Email</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngIf="allClientContacts.length == 0">
                    <td class="text-center border-bottom-0">
                        <span class="text-heading font-bold text-center border-bottom-0">No records found</span>
                    </td>
                </ng-container>
                <ng-container *ngIf="allClientContacts.length > 0">
                    <tr *ngFor="let contact of allClientContacts" class="dataColumn">
                        <td class="text-heading font-bold ps-4">
                            <div class="textWithIcon">
                                <div class="avatar avatar-sm bg-warning rounded-circle text-white h-7 w-7 me-2"
                                    *ngIf="contact.profile_pic">
                                    <img alt="..." src="{{dataSharedService.getImage(contact.profile_pic)}}">
                                </div>
                                <div *ngIf="!contact.profile_pic"
                                    class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                                    <span class="text-primary fs-10x">{{authService.getInitials(contact.name)}}</span>
                                </div>
                                <span>{{contact.name}}</span>
                            </div>
                        </td>
                        <td>{{contact.email}}</td>
                        <td>
                            <a  (click)="resendAccountActivation(contact.uuid)" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" ngbTooltip="Send Account Creation Mail" placement="top">
                                <i class="bi bi-link"></i>
                            </a>
                            <a class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7"
                                (click)="showEditClient(client)" *ngIf="active_contact == 1" placement="top"
                                ngbTooltip="Edit Contact">
                                <i class="bi bi-pencil-fill"></i>
                            </a>
                            <a class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7"
                                (click)="deactivateClientContact(client, contact)" *ngIf="active_contact == 1"
                                ngbTooltip="Deactivate Contact">
                                <i class="bi bi-person-x-fill"></i>
                                <!-- <i class="bi bi-arrow-counterclockwise"></i> -->
                            </a>
                            <a class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7"
                                (click)="activateClientContact(client, contact)" *ngIf="active_contact == 0"
                                ngbTooltip="Activate Contact">
                                <i class="bi bi-person-plus-fill"></i>
                                <!-- <i class="bi bi-arrow-clockwise"></i> -->
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
    </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AlertModal } from 'src/app/app.model';
import { roleConstant } from 'src/app/app.constant';
import { DataSharedService } from '../../services/data-shared.service';


@Component({
  selector: 'app-edit-candidate',
  templateUrl: './edit-candidate.component.html',
  styleUrls: ['./edit-candidate.component.css']
})
export class EditCandidateComponent implements OnInit {
  userForm: FormGroup | any;
  name!: string;
  email!: string;
  error!: string;
  user!: any;

  @Input() public candidate: any;
  constructor( 
    private timesheetService: TimesheetService,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,
    private authService: AuthenticationService,
    public dataSharedService: DataSharedService,) { }

  ngOnInit(): void {
    this.userForm = new FormGroup({
      first_name: new FormControl(this.candidate.first_name, Validators.required),
      last_name: new FormControl(this.candidate.last_name, Validators.required),
      email: new FormControl(this.candidate.email, [Validators.required, Validators.email]),
      identifier: new FormControl(this.candidate.identifier, []),
      ssn: new FormControl(this.candidate.ssn, []),
    });
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.userForm.value).forEach(element => {
      if (this.userForm.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Candidate Creation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.error = '';
    const payload = {
      ...this.userForm.value,
    }
    this.timesheetService.updateCandidate(payload, this.candidate.uuid).subscribe(res => {
      this.activeModalService.close({ refresh: true });
      this.dataSharedService.sendUpdate('Update Active Candidates');
    }, error => {
      console.error(error);
      this.error = error;
    });
  }

}

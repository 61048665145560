<div class="loader" *ngIf="loading">
  <mat-spinner diameter="40"></mat-spinner>
</div>

<div class="modal-header p-4" *ngIf="from !== 'create-timesheet'">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <div class="text-dark align-items-center d-flex flex-column">
    <h3 class="modal-title">{{auditTrail.title}}</h3>
    <p class="subtitle">Date: {{auditTrail.subTitle}}</p>
  </div>
  <mat-icon class="close clickable me-4" (click)="close()">close</mat-icon>
</div>

<div class="modal-header border-bottom-0 justify-content-center p-0" *ngIf="from == 'create-timesheet'">
  <div class="text-dark align-items-center d-flex flex-column">
    <h3 class="modal-title">{{auditTrail.title}}</h3>
    <p class="subtitle">Date: {{auditTrail.subTitle}}</p>
  </div>
</div>

<div class="modal-body pt-0">
  <div class="border-bottom" *ngIf="!loading && from !== 'create-timesheet'">
    <ul class="nav nav-tabs overflow-x border-0">
      <li class="nav-item" *ngIf="!(readonly && status === STATUS.PENDING)">
        <a class="nav-link" style="cursor: pointer;" [class]="tab === 'timesheetDetails' ? 'active':''"
          (click)="tab = 'timesheetDetails'">Timesheet Details</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [class]="tab === 'auditTrail' ? 'active':''"
          (click)="tab = 'auditTrail'">Audit Trail</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [class]="tab === 'comments' ? 'active':''"
          (click)="tab = 'comments'">Comments</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" style="cursor: pointer;" [class]="tab === 'attachments' ? 'active':''"
          (click)="tab = 'attachments'">Attachments</a>
      </li>
    </ul>
  </div>

  <ng-container *ngIf="tab === 'timesheetDetails'">
    <div class="py-3">

      <div class="row edit-timesheet-form">
        <form *ngIf="timesheetForm" [formGroup]="timesheetForm" (ngSubmit)="submitTimesheet()">

          <div class="accordion-container">
            <ngb-accordion #acc="ngbAccordion" class="w-100 mt-4 timesheet-accordion"
              [activeIds]="accordion_active_ids">
              <ng-container *ngFor="let timesheet_detail of timesheetDetails?.timesheet_details;  let idx = index">
                <ngb-panel id="timsheet-ngbaccordion-panel-{{idx}}" cardClass="w-100 open">

                  <ng-template ngbPanelHeader let-opened="opened">
                    <div class="accordion-button custom-header justify-content-between" [class.collapsed]="!opened">
                      <div class="row w-100 justify-content-between align-items-center">
                        <button class="col-8 accordion-button-toggel" ngbPanelToggle>
                          <div class="align-items-center">
                            <p>{{timesheet_detail.date | date: 'EEEE' }}
                              ({{dataSharedService.getFormatedDateUTC(timesheet_detail.date)}})</p>
                          </div>
                        </button>
                        <div class="col-4 text-end">
                          <div class="hstack gap-4 justify-content-end">
                            <span class=" timesheet-display">{{timesheet_detail?.timesheet_display}}</span>
                            <ng-container *ngIf="from !== 'create-timesheet'">
                              <button type="button" (click)="changeTimesheetMode()"
                                class="btn btn-sm btn-square btn-neutral me-2"
                                *ngIf="((timesheet.status !== STATUS.APPROVED) && (user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CANDIDATE))"><i
                                  class="bi bi-{{readonly ? 'pencil-fill' : 'eye-fill'}}"></i></button>
                            </ng-container>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>

                  <ng-template ngbPanelContent>
                    <ng-container class="container">
                      <div class="row">
                        <ng-container *ngFor="let field of fields?.field_values">
                          <ng-container *ngIf="field.field_type !== 'static'">
                            <div
                              class="{{(field.type === 'textarea' || field.label === 'Comments') ? 'col-md-12' : 'col-md-6'}} {{field.type}}">

                              <div *ngIf="field.type === 'text' || field.type === 'number'">
                                <label class="form-label">{{field.label}} <span *ngIf="field.is_required"
                                    class="requiredStar">*</span></label>
                                <input [readonly]="readonly" [ngClass]="readonly? 'form-control-readOnly':''"
                                  type="text" [type]="field.type" class="form-control remover"
                                  formControlName="{{field.label+'_'+idx}}"
                                  (focus)="timesheetForm.controls[field.label+'_'+idx].focused = true"
                                  (blur)="onBlurField(field.label+'_'+idx)"
                                  [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+idx].errors && timesheetForm.controls[field.label+'_'+idx].touched}">
                              </div>

                              <div *ngIf="field.type === 'time_range'">
                                <label class="form-label">{{field.label}} <span *ngIf="field.is_required"
                                    class="requiredStar">*</span></label>
                                <input [readonly]="readonly" [ngClass]="readonly? 'form-control-readOnly':''"
                                  type="time" class="form-control remover input-time"
                                  [formControlName]="field.label+'_'+idx" placeholder="HH:mm:ss" min="06:00:00"
                                  max="20:00:00"
                                  [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+idx]?.errors && timesheetForm.controls[field.label+'_'+idx]?.touched}" />
                              </div>

                              <div *ngIf="field.type === 'textarea'">
                                <label class="form-label">{{field.label}} <span *ngIf="field.is_required"
                                    class="requiredStar">*</span></label>
                                <textarea [readonly]="readonly" [ngClass]="readonly? 'form-control-readOnly':''"
                                  class="form-control" formControlName="{{field.label+'_'+idx}}" id="floatingTextarea"
                                  (focus)="timesheetForm.controls[field.label+'_'+idx].focused = true"
                                  (blur)="onBlurField(field.label+'_'+idx)"
                                  [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+idx].errors && timesheetForm.controls[field.label+'_'+idx].touched}"></textarea>
                              </div>

                              <div *ngIf="field.type === 'dropdown' || field.type === 'single-select'">
                                <label class="form-label">{{field.label}} <span *ngIf="field.is_required"
                                    class="requiredStar">*</span></label>
                                <select [attr.disabled]="readonly? 'disabled':null"
                                  [ngClass]="readonly? 'form-control-readOnly':''" class="select"
                                  formControlName="{{field.label+'_'+idx}}"
                                  (focus)="timesheetForm.controls[field.label+'_'+idx].focused = true"
                                  (blur)="onBlurField(field.label+'_'+idx)"
                                  [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+idx].errors && timesheetForm.controls[field.label+'_'+idx].touched}">
                                  <option *ngFor="let option of field.values.split(',')" [value]="option">{{option}}
                                  </option>
                                </select>
                              </div>

                              <div class="copy d-none" *ngIf="!readonly">
                                <a class="btn btn-neutral btn-sm bt-square ms-1"
                                  *ngIf="timesheetForm.controls[field.label+'_'+idx]?.focused && !readonly"
                                  (click)="copyValues(field.label, field.label+'_'+idx, idx)">
                                  <i class="bi bi-clipboard-fill"></i>
                                </a>
                                <a class="btn btn-neutral btn-sm bt-square ms-1 opacity-0"
                                  *ngIf="!timesheetForm.controls[field.label+'_'+idx]?.focused || readonly">
                                  <i class="bi bi-clipboard-fill"></i>
                                </a>
                              </div>

                            </div>
                          </ng-container>
                        </ng-container>
                      </div>
                    </ng-container>
                  </ng-template>

                </ngb-panel>
              </ng-container>
            </ngb-accordion>
          </div>

          <div class="error"><span *ngIf="error" class="error">{{error}}</span></div>
          <div class="row" *ngIf="timesheet">
            <div class="col-sm col-12 mt-4 text-md-start">
              <button type="button" class="btn btn-sm btn-neutral me-2" (click)="changeTimesheetMode()"
                *ngIf="((timesheet.status !== STATUS.APPROVED) && (user.role_uuid === ROLES.AGENCY || user.role_uuid === ROLES.CANDIDATE))">
                {{readonly ? 'Edit Timesheets' : 'View Timesheets'}}
              </button>
            </div>
            <div class="col-sm col-12 mt-4 text-md-center d-flex justify-content-md-center">
              <button class="btn btn-sm btn-success me-2" type="button" (click)="onApprove()"
                *ngIf="readonly && arFlag">Approve</button>
              <button class="btn btn-sm btn-danger" type="button" (click)="onReject()"
                *ngIf="readonly && arFlag">Reject</button>
              <button class="btn btn-sm btn-primary" type="submit" *ngIf="!readonly">Submit</button>
            </div>
            <div class="col-sm col-12 mt-4 text-md-end">
              <button type="button" class="btn btn-sm btn-primary"
                (click)="showAllAccordionItems(acc)">{{timesheetDetails?.timesheet_details?.timesheet_total_type}}:
                <!-- {{timesheetDetails?.timesheet_details?.timesheet_total}} -->
                {{totalDays}}
                {{timesheetDetails?.timesheet_details?.timesheet_total_type_suffix}}</button>
            </div>
          </div>
        </form>
      </div>

    </div>
  </ng-container>

  <ng-container *ngIf="!loading">
    <app-audit-trail *ngIf="tab === 'auditTrail'" [auditTrailActivities]="auditTrailActivities" type="timesheet"
      [uuid]="timesheet.uuid" [from]="'timeSheetDetail'" [header]="auditTrail"> </app-audit-trail>
    <app-comments *ngIf="tab === 'comments'" (onCommentEnter)="onCommentEnter($event)" [commentsList]="commentsList"
      [timesheet_uuid]="timesheet.uuid" [endPoint]="API_CONSTANT.TIMESHEET_COMMENTS" [parentKey]="'timesheet_uuid'">
    </app-comments>
    <app-attachments *ngIf="tab === 'attachments'" (onNewAttachment)="onNewAttachment($event)"
      [attachmentsArr]="attachmentsArr" [timesheet_uuid]="timesheet.uuid"></app-attachments>
  </ng-container>
</div>
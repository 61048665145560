<form [formGroup]="formGroup" *ngIf="formGroup">
    <div class="input-form searchSelect"
        [ngClass]="{'invalidField':formGroup.controls[controlName].touched && formGroup.controls[controlName].errors && !dataSearch}">
        <div class="inputAndSelect">
            <mat-select #option (selectionChange)="onDataSelect($event)" class="searchSelect"
                panelClass="searchSelectPanel" id="matSelect" [formControlName]="controlName">
                <mat-option *ngFor="let data of dataArray" [value]="data.uuid" [disabled]="data?.disabled">
                    {{data[titleKey]}}<span *ngIf="controlName === 'candidate_uuid'"> ({{data.email}})</span>
                </mat-option>
            </mat-select>
            <input [disabled]="disabled ? disabled:false" #search type="text" [(ngModel)]="dataSearch"
                (keyup)="getDataArray($event, option)" [ngModelOptions]="{standalone: true}">
        </div>
        <mat-icon *ngIf="!searchingData" (click)="getDataArray(search, option)">search</mat-icon>
        <mat-spinner *ngIf="searchingData" diameter="20"></mat-spinner>
    </div>
</form>
<div class="notiContainer">
    <div class="notiHeader">
        <h3 class="notiHeading" (click)="$event.stopPropagation();">Notifications</h3>
        <i class="bi bi-x-lg"></i>
    </div>
    <!--div class="tabsContainer">
        <ul class="nav nav-tabs" (click)="$event.stopPropagation();">
            <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" [class]="tab === 'all' ? 'active':''"
                    (click)="tab = 'all'">All</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" style="cursor: pointer;" [class]="tab === 'comments' ? 'active':''"
                    (click)="tab = 'comments'">Comments</a>
            </li>
        </ul>
    </div-->
    <div class="notificationsContainer">
        <div class="notificationContainer" *ngFor="let notification of notifications">
            <div class="notiAvatar">
                <img src="assets/img/notiStaticAvatar.png" alt="" *ngIf="notification.created_by_pic == ''" />
				<img src="{{notification.created_by_pic}}" alt="" *ngIf="notification.created_by_pic" />
            </div>
            <div class="notiContent">
                <h4>
                    <span class="userName">{{notification.created_by}}</span>
                    {{notification.raw_body}}
                    <span class="actionName">{{notification.action_name}}</span>
                </h4>
                <h5>{{notification.time_ago}}</h5>
            </div>
        </div>
    </div>
	
	<div class="loader notification-loader" *ngIf="notificationLoading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
	
	<div class="noMore loadMore" *ngIf="notificationCurrentPage != notificationLastPage" (click)="loadNotifications(); $event.stopPropagation();" >
        <span>Load more notifications</span>
    </div>
    <div class="noMore" *ngIf="notificationCurrentPage == notificationLastPage">
        <span>no more notifications</span>
    </div>
</div>
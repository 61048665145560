import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { TimesheetService } from '../services/timesheet.service';
import { DataSharedService } from '../services/data-shared.service';
import { CreateAgencyComponent } from '../components/create-agency/create-agency.component';

@Component({
  selector: 'app-agencies',
  templateUrl: './agencies.component.html',
  styleUrls: ['./agencies.component.css']
})

export class AgenciesComponent implements OnInit {
  timesheets: any = [];
  agencies: any = [];
  allAgencies: any = [];
  loading: boolean = false;
  totalCount: number = 0;

  constructor(
    public dataSharedService: DataSharedService,

    private timesheetService: TimesheetService,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getAgencies(1);
  }

  async getAgencies(page: number) {
    this.agencies = [];
    this.allAgencies = [];
    this.loading = true;
    const res: any = await new Promise((resolve) => this.timesheetService.getAgencies(page).subscribe((res: any) => resolve(res)));
    this.agencies = res.data;
    this.totalCount = res.meta.total
    this.loading = false;
    this.allAgencies = this.agencies.slice();
  }

  onStepChange(event: any) {
    this.getAgencies(event)
  }

  sortData(sort: Sort) {
    const data = this.agencies.slice();
    if (!sort.active || sort.direction === '') {
      this.allAgencies = data;
      return;
    }

    this.allAgencies = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        default:
          return 0;
      }
    });
  }

  onAdd() {
    const modalRef = this.modalService.open(CreateAgencyComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-agency-modal',
    });
    modalRef.result.then((data) => {
      if (data?.refresh === true) {
        this.getAgencies(1);
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
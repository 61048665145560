<div class="row" [ngClass]="{'scroll' : jobAssignments.length >= 3}">
    <div class="cardContainer" *ngFor="let assignment of jobAssignments; let i = index"
        [class.me-5]="i !== jobAssignments.length-1">
        <div class="card">
            <div class="card-body">
                <div class="addIcon">
                    <button (click)="createTimesheet(assignment)"><i class="bi bi-plus-lg"></i></button>
                </div>
                <div class="cardHeadBody">
                    <div class="assignmentIcon">
                        <i class="bi bi-briefcase"></i>
                    </div>
                    <h3>{{assignment.job}}</h3>
                    <h5>Client</h5>
                    <h4>{{assignment.client}}</h4>
                </div>
                <div class="cardFooterBody">
                    <div class="dateColumn" id="startDate">
                        <div class="dateIndicator"></div>
                        <span>{{dataSharedService.getFormatedDateUTC(assignment.start_date)}}</span>
                    </div>
                    <hr>
                    <div class="dateColumn" id="endDate">
                        <div class="dateIndicator"></div>
                        <span>{{dataSharedService.getFormatedDateUTC(assignment.end_date)}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from '../services/user.service';
import { SuccessModal } from '../app.model';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';


@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.css']
})

export class ClientContactsComponent implements OnInit {
  @Input() client: any;
  @Output() showEditClientModal: EventEmitter<any> = new EventEmitter();
  @Output() activateEditClientContactModal: EventEmitter<any> = new EventEmitter();
  @Output() deactivateEditClientContactModal: EventEmitter<any> = new EventEmitter();

  loading: boolean = false;
  clientContacts: any = [];
  allClientContacts: any = [];

  active_client: number = 1;
  active_contact: number = 1;

  constructor(
    private route: ActivatedRoute,
    public dataSharedService: DataSharedService,
    public authService: AuthenticationService,
    public activeModalService: NgbActiveModal,
    public userService: UserService,
    private modalService: NgbModal,

  ) {

    this.allClientContacts = []
    this.clientContacts = []
    if (this.route.snapshot.queryParams['client']) {
      this.client = JSON.parse(this.route.snapshot.queryParams['client']);

      this.clientContacts = this.client.contacts;
      this.allClientContacts = this.clientContacts.slice();

      this.active_client = this.client.active_client;
      this.active_contact = this.client.active_contact;
    }
  }

  ngOnInit(): void {
    if (this.client) {
      this.clientContacts = this.client.contacts;
      this.allClientContacts = this.clientContacts.slice();
    }
  }

  // Modal custom update data
  updateData(data: any): void {
    if (data?.client) {
      this.client = data?.client;
      this.clientContacts = this.client.contacts;
      this.allClientContacts = this.clientContacts.slice();
    }
    this.loading = false;
  }

  sortData(sort: Sort) {
    const data = this.clientContacts.slice();
    if (!sort.active || sort.direction === '') {
      this.allClientContacts = data;
      return;
    }

    this.allClientContacts = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'created_at':
          return compare(a.created_at, b.created_at, isAsc);
        default:
          return 0;
      }
    });
  }

  onClose() {
    this.activeModalService.close();
  }

  showEditClient(client: any) {
    this.showEditClientModal.emit(client);
    this.onClose();
  }

  activateClientContact(client: any, contact: any) {
    this.loading = true;
    this.activateEditClientContactModal.emit({ client, contact });
  }

  deactivateClientContact(client: any, contact: any) {
    this.loading = true;
    this.deactivateEditClientContactModal.emit({ client, contact });
  }

  resendAccountActivation(contactUuid: string) {
    const payload = {
      user_uuid: contactUuid,
    }
    this.userService.resendUserLogin(payload).subscribe({
      next: (response) => {
        this.successModal('Account creation mail sent.')
      },
      error: (err) => {
      }
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}

<div class="formContainer p-7">
  <div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  <div class="d-flex justify-content-end">
    <mat-icon class="clickable" (click)="onClose()">close</mat-icon>
  </div>
  <div class="formContainer">
    <form [formGroup]="editTimesheet" *ngIf="editTimesheet">
      <div class="selectTimeSheet">
        <label for="selectTimeSheet">Job Name</label>		
        <input formControlName="timesheet_uuid" style="display: none;">
		<input type="text" class="form-control" formControlName="job_name" readonly  />
      </div>
      <div class="selectTimeSheet">
        <label for="selectTimeSheet">Candidate Name</label>
        <input type="text" class="form-control" formControlName="candidate_name" [readonly]="true"  />
      </div>
      <div class="selectTimeSheet">
        <label for="selectTimeSheet">Start Date - End Date</label>
        <div class="datePickerRangeContainer" (click)="picker.open()"
          [ngClass]="{'invalidField':(editTimesheet?.controls['start_date']?.errors && editTimesheet?.controls['start_date']?.touched) || editTimesheet?.controls['end_date']?.errors && editTimesheet?.controls['end_date']?.touched}">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <i class="bi bi-calendar4"></i>
          <mat-date-range-input [rangePicker]="picker" [disabled]="!editTimesheet?.value?.timesheet_uuid"
            [min]="minLimit" [max]="maxLimit">
            <input matStartDate formControlName="start_date">
            <input matEndDate formControlName="end_date">
          </mat-date-range-input>
        </div>
      </div>
    </form>
    <div class="error" *ngIf="error">
      <span>{{error}}</span>
    </div>
    <div class="submitForm">
      <button type="submit" [disabled]="loading" (click)="onSubmit()">Save</button>
    </div>
  </div>
</div>
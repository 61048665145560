import { Component, OnInit, Input} from '@angular/core';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { MatDialog } from '@angular/material/dialog';
import { MisssingTimesheetsModalComponent } from '../components/misssing-timesheets-modal/misssing-timesheets-modal.component';
import { SendEmailComponent } from '../components/send-email/send-email.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import  { messageConstant } from '../app.constant';
import { Router } from '@angular/router';

@Component({
  selector: 'app-missing-timesheets',
  templateUrl: './missing-timesheets.component.html',
  styleUrls: ['./missing-timesheets.component.css']
})
export class MissingTimesheetsComponent implements OnInit {
  @Input() missingTimesheets?: any;
  @Input() missingTimesheetsCount?: any;
  public permissions: any = {};
  public user: any;
  public isCollapsed = false;

  constructor(  
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    public dialog: MatDialog,
    private modalService: NgbModal,
    private router: Router,) { 
      this.user = this.authService.userValue;
      this.permissions.candidates = this.user.permissions.find((x: any) => x.permission === 'Candidates');
    }

  ngOnInit(): void {
  }

  openAllMissingtimesheets() {
    this.dialog.open(MisssingTimesheetsModalComponent, {
      width: '100%',
      maxWidth: '100%',
      height: '98%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
    });
  }

  redirect(url: string, name: any) {
    if ((url === '/jobs' && this.permissions.jobs) || (url === '/candidates' && this.permissions.candidates) || (url === '/clients' && this.permissions.clients)) {
      this.router.navigate([url], { queryParams: { name } });
    }
  }

  openSendReminderPopUp(candidateUuid:any, candidatName:string, jobName:string, clientName:any) {
    const modalRef = this.modalService.open(SendEmailComponent, {
      size: 'md',
      modalDialogClass: 'add-reminder-modal'
    });

    let message = messageConstant.PENDING_TIMESHEET_MESSAGE.
                  replace('{{contractor_name}}', candidatName).
                  replace('{{job_name}}', jobName).
                  replace('{{client_name}}', clientName);

    modalRef.componentInstance.userUuid = candidateUuid;
    modalRef.componentInstance.userSubject = messageConstant.PENDING_TIMESHEET_SUBJECT;
    modalRef.componentInstance.userMessage = message;
    modalRef.result.then((data) => {
      if (data?.data === true) {
       
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MissingTimesheetsComponent } from 'src/app/missing-timesheets/missing-timesheets.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { BoardService } from 'src/app/services/board.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SendEmailComponent } from '../send-email/send-email.component';
import { messageConstant } from 'src/app/app.constant';

@Component({
  selector: 'app-misssing-timesheets-modal',
  templateUrl: './misssing-timesheets-modal.component.html',
  styleUrls: ['./misssing-timesheets-modal.component.css']
})
export class MisssingTimesheetsModalComponent implements OnInit {
  allMissingTimesheets?: any
  constructor(public boardService: BoardService,
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    private dialogRef: MatDialogRef<MissingTimesheetsComponent>,
    private modalService: NgbModal,) {
    
   }

  ngOnInit(): void {
    this.boardService.getAllMissingTimesheets().subscribe((missingTimesheets:any) => {
      this.allMissingTimesheets = missingTimesheets.data;
    });
  }

  close(){
    this.dialogRef.close();
  }

  openSendReminderPopUp(candidateUuid:any, candidatName:string, jobName:string, clientName:any) {
    const modalRef = this.modalService.open(SendEmailComponent, {
      size: 'md',
      modalDialogClass: 'add-reminder-modal'
    });

    let message = messageConstant.PENDING_TIMESHEET_MESSAGE.
                  replace('{{contractor_name}}', candidatName).
                  replace('{{job_name}}', jobName).
                  replace('{{client_name}}', clientName);

    modalRef.componentInstance.userUuid = candidateUuid;
    modalRef.componentInstance.userSubject = messageConstant.PENDING_TIMESHEET_SUBJECT;
    modalRef.componentInstance.userMessage = message;

    modalRef.result.then((data) => {
      if (data?.data === true) {
       
      }
    });
  }

}

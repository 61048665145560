import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { Notifications } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }
  
  getNotifications(page: number,type: string = '') {
    return this.http.get<Notifications>(`${environment.baseUrl+apiConstant.NOTIFICATIONS}?page=${page}&type=${type}`);
  }
  
  setReadAllNotifications(){    
      return this.http.post<Notifications>(`${environment.baseUrl + apiConstant.READ_ALL_NOTIFICATIONS}`,'');   
  }
  
}
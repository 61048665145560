import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { SendMail,} from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {

  constructor(
    private http: HttpClient
  ) { }

  sendEmail(emailData: SendMail,) {
      return this.http.post<SendMail>(`${environment.baseUrl + apiConstant.SEND_EMAIL}`, emailData);
  }

}

export const apiConstant = {
  VERIFY_GOOGLE_TOKEN: 'verify-google-token',
  LOGIN: 'login',
  LOGOUT: 'logout',
  JOBS: 'jobs',
  AGENCIES: 'agencies',
  USERS: 'users',
  CLIENTS: 'clients',
  SETTINGS: 'settings',
  JOB_ASSINGMENTS: 'job-assignments',
  TIMESHEETS: 'timesheets',
  TIMESHEET_DETAILS: 'timesheet-details',
  ADD_TIMESHEET: 'add-timesheet',
  SUBMIT_TIMESHEET: 'submit-timesheet',
  APPROVE_TIMESHEET: 'approve-timesheet',
  REJECT_TIMESHEET: 'reject-timesheet',
  SET_AGENCY_LOGO: 'set-agency-logo',
  SET_PROFILE_PIC: 'set-profile-pic',
  TIMESHEET_FORM: 'timesheet-form',
  FORGOT_PASSWORD: 'forgot-password',
  RESET_PASSWORD: 'set-password',
  CHANGE_PASSWORD: 'change-password',
  AUDIT_TRAIL: 'audit-trail',
  TIMESHEET_COMMENTS: 'timesheet-comments',
  TASK_COMMENTS: 'task-comments',
  SET_DATE_FORMAT: 'set-date-format',
  SET_CURRENCY_FORMAT: 'set-currency-format',
  ATTACH_DOCUMENTS: 'attach-documents',
  GET_DOCUMENTS: 'get-documents',
  DELETE_DOCUMENT: 'delete-document',
  REMINDERS: 'reminders',
  GET_ASSIGNED_TASK:'get-assigned-task',
  TASKS:'tasks',
  GET_ASSIGNEES:'get-assignees',
  RESEND_USER_LOGIN:'resend-login',
  GENERATE_ACCOUNT_CREATION_LINK: 'generate-account-creation-link',
  SEND_EMAIL: 'send-email',
  TIMESHEET_APPROVAL_LINK: 'timesheet-approval-link',
  TIMESHEET_VIEW_LINK:'timesheet-view-link',
  PAY_BILL_REPORT_DATA: 'pay-bill-report',
  NOTIFICATIONS: 'notifications',
  READ_ALL_NOTIFICATIONS: 'read-all-notifications',
  BOARD: 'board',
  SHOW_ALL_MISSING_TIMESHEETS : 'show-all-missing-timesheets',
  SHOW_ALL_AWAITING_APPROVALS : 'show-all-awaiting-approvals',
  ACTIVATE : 'activate',
  DEACTIVATE : 'deactivate',
  TIMESHEET_APPROVERS: 'timesheet-approvers',
  OVERTIME_SETTINGS : 'overtime-settings',
  RESEND_INVOICE : 'resend-invoice',
  INVOICES : 'invoices',
}


export const roleConstant = {
  CANDIDATE: '07233ee6-057f-4286-899e-77d50a61602f',
  CLIENT: 'd4a4da95-edd2-4b17-a6df-a32657803a7d',
  AGENCY: 'd3ef9c2d-b647-40f2-a6b2-a93aad43f513',
  SUPER_ADMIN: 'super-admin',
}

export const agencyConstant = {
  '31T': '5adbc67d-8cee-4f6f-8b89-15595a015083',
  HYPHEN: '7252f8f7-84b7-4e15-95d1-d05e4afa5e20',
  CLUG_DEMO: '9378639f-b37a-412b-81d5-b6458ba98233',
}

export const statusConstant = {
  SUBMITTED: 'Submitted',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  PENDING: 'Pending'
}

export const permissionConstant = {
  AGENCIES: 'Agencies',
  CANDIDATES: 'Candidates',
  JOBS: 'Jobs',
  TIMESHEETS: 'Timesheets',
  CLIENTS: 'Clients',
  ASSIGNMENTS: 'Assignments',
  REPORTS: 'Reports',
  SETTINGS: 'Settings',
  TASKS: 'Tasks'
}

export const messageConstant =  {
  PENDING_TIMESHEET_SUBJECT : 'Pending Timesheet',
  PENDING_TIMESHEET_MESSAGE : 'Hi {{contractor_name}}, just a reminder that you still need to submit your timesheet for {{job_name}} at {{client_name}}.',
  AWAITING_APPROVAL_SUBJECT : 'Awaiting Approval',
  AWAITING_APPROVAL_MESSAGE : 'Gentle Reminder to approve the timesheet of {{contractor_name}} for {{job_name}}.'

}
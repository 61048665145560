<div class="modal-header">
    <h4 class="modal-title fs-5">Timesheet Details</h4>
    <button type="button" class="btn-close btn-neutral btn-square" aria-label="Close" (click)="close()"></button>
  </div>
  <div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
  </div>
  <div class="modal-body p-0">
    <form *ngIf="timesheetForm" [formGroup]="timesheetForm" (ngSubmit)="submitTimesheet()" >
      <table class="table table-borderless datatable">
        <thead class="table-light">
          <tr class="header-row">
            <th scope="col">Date</th>
            <th scope="col" *ngFor="let field of fields.fields">{{field.label}}</th>
          </tr>
        </thead>
        <tbody class="">
          <tr *ngFor="let timesheet of timesheetDetails?.timesheet_details;  let idx = index" class="table-row">
            <td>
              {{timesheet.date}}
            </td>
            <td *ngFor="let field of fields.fields">
              <div class="tdFields">
                <input [readonly]="readonly" [ngClass]="readonly? 'form-control-readOnly':''"
                  *ngIf="field.type === 'text' || field.type === 'number'" type="text" [type]="field.type"
                  class="form-control remover" formControlName="{{field.label+'_'+idx}}" (focus)="timesheetForm.controls[field.label+'_'+idx].focused = true" (blur)="onBlurField(field.label+'_'+idx)">
                <textarea [readonly]="readonly" [ngClass]="readonly? 'form-control-readOnly':''"
                  *ngIf="field.type === 'textarea'" class="form-control" formControlName="{{field.label+'_'+idx}}"
                  id="floatingTextarea" (focus)="timesheetForm.controls[field.label+'_'+idx].focused = true" (blur)="onBlurField(field.label+'_'+idx)"></textarea>
                <select *ngIf="field.type === 'single-select'" class="form-select" formControlName="{{field.label+'_'+idx}}" (focus)="timesheetForm.controls[field.label+'_'+idx].focused = true" (blur)="onBlurField(field.label+'_'+idx)">
                  <option *ngFor="let option of field.values.split(',')" [value]="option">{{option}}</option>
                </select>
                <div class="copy" *ngIf="timesheetForm.controls[field.label+'_'+idx]?.focused && !readonly">
                  <a class="btn btn-neutral btn-sm bt-square ms-1" (click)="copyValues(field.label, field.label+'_'+idx)">
                    <i class="bi bi-clipboard-fill"></i>
                  </a>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="buttons w-100 d-flex justify-content-start align-items-center pb-2 flex-wrap  ps-3">
        <button class="btn btn-success mb-2" type="button" (click)="onApprove()" *ngIf="readonly && arFlag">Approve</button>
        <button class="btn btn-danger mb-2 ms-3" type="button" (click)="onReject()" *ngIf="readonly && arFlag">Reject</button>
        <button class="btn btn-primary mb-2 ms-3" type="submit" *ngIf="!readonly">Submit</button>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="close()">Close</button>
  </div>
  
import { DatePipe } from '@angular/common';
import { AlertModal } from 'src/app/app.model';
import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Reminder, ReminderData } from 'src/app/app.model';
import { ReminderService } from 'src/app/services/reminder.service';
import { TimesheetService } from '../../services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-add-reminder',
  templateUrl: './add-reminder.component.html',
  styleUrls: ['./add-reminder.component.css'],
})
export class AddReminderComponent implements OnInit {

  @Input() reminder: any;
  reminder_title: string = "Add New Reminder";
  reminder_uuid!: string;
  reminderForm: FormGroup | any;
  roles: any = [
    {
      uuid: '07233ee6-057f-4286-899e-77d50a61602f',
      role: 'Candidate'
    },
    {
      uuid: 'd4a4da95-edd2-4b17-a6df-a32657803a7d',
      role: 'Client'
    }
  ];
  timePickerTheme = {
    container: {
      buttonColor: "#0566EA"
    },
    dial: {
      dialBackgroundColor: "#0566EA"
    },
    clockFace: {
      clockHandColor: "#0566EA",
    }
  };

  constructor(
    public timesheetService: TimesheetService,
    public reminderService: ReminderService,
    public datePipe: DatePipe,

    private modalService: NgbModal,
    private activeModalService: NgbActiveModal
  ) { }

  ngOnInit(): void {

    this.reminderForm = new FormGroup({
      message: new FormControl('', Validators.required),
      day: new FormControl('', Validators.required),
      role_uuid: new FormControl('', Validators.required),
      time: new FormControl('', Validators.required),
      is_active: new FormControl(false, Validators.required),
    });

    if (this.reminder?.uuid) {
      this.reminder_uuid = this.reminder.uuid;
      this.reminder_title = "Update Reminder"
      this.reminderForm.patchValue({
        message: this.reminder.message,
        day: this.reminder.day,
        role_uuid: this.reminder.role_uuid,
        time: `${this.reminder.time.split(':')[0]}:${this.reminder.time.split(':')[1]}`,
        is_active: this.reminder.is_active ? true : false
      })
    }
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.reminderForm.value).forEach(element => {
      if (this.reminderForm.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue && !this.reminderForm.length) {
      this.activeModalService.close();
      return
    }

    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'alert-modal modal-dialog-centered',
    });

    let data: AlertModal = {
      message: `Are you sure you want to discard reminders ${this.reminder_uuid ? "updation" : "creation"}?`,
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSumit() {
    const reminderData: Reminder = this.reminder_uuid ?
      {
        ...this.reminderForm.value,
        time: this.reminderForm.value.time + ':00'
      } : {
        reminders:
          [
            {
              ...this.reminderForm.value,
              time: this.reminderForm.value.time + ':00'
            }
          ]
      };
    this.reminderService.addReminder(reminderData, this.reminder_uuid).subscribe(res => {
      this.activeModalService.close({
        data: true,
      });
    })

  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { Reminder, ReminderData } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class ReminderService {

  constructor(
    private http: HttpClient
  ) { }

  addReminder(reminderData: Reminder, reminder_uuid?: string) {
    if (reminder_uuid) {
      return this.http.patch<Reminder>(`${environment.baseUrl + apiConstant.REMINDERS}/${reminder_uuid}`, reminderData);
    } else {
      return this.http.post<Reminder>(`${environment.baseUrl + apiConstant.REMINDERS}`, reminderData);
    }
  }

  getReminders() {
    return this.http.get<Reminder>(`${environment.baseUrl}` + apiConstant.REMINDERS);
  }
}

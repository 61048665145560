import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbAlert, NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { apiConstant } from 'src/app/app.constant';
import { AlertModal } from 'src/app/app.model';
import { JobAssignmentService } from 'src/app/services/job-assignment.service';
import { JobService } from 'src/app/services/job.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { SearchSelectComponent } from '../search-select/search-select.component';

@Component({
  selector: 'app-create-job-assignment',
  templateUrl: './create-job-assignment.component.html',
  styleUrls: ['./create-job-assignment.component.css'],
})
export class CreateJobAssignmentComponent implements OnInit {
  createJobAssignment: FormGroup | any;
  jobs: any = [];
  candidates: any = [];
  error: any = [];
  loading: boolean = false
  searchingJob: boolean = false
  searchingCandidate: boolean = false
  jobSearch: string = ''
  candidateSearch: string = ''
  formControls: any = {};
  timesheetApprovers: any = [];
  public API_CONSTANT = apiConstant

  constructor(
    private jobService: JobService,
    private timesheetService: TimesheetService,
    private jobAssignmentService: JobAssignmentService,
    public datePipe: DatePipe,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.createJobAssignment = new FormGroup({
      job_uuid: new FormControl('', Validators.required),
      candidate_uuid: new FormControl('', Validators.required),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      timesheet_approvers: new FormControl(''),
    });
  }

  onSearchChange(event: any) {
    console.log(event.value)
    this.createJobAssignment.patchValue({
      [event.formControlName]: event.value.uuid
    })
    if (event.formControlName === 'job_uuid') {
      this.jobs = event.dataArray
    }
    if(event.value && event.value.client_uuid != undefined) {
      this.timesheetApprovers = [];
      this.getTimesheetApprovers(event.value.client_uuid);
    }
  }

  getTimesheetApprovers(clientUuid: string) {
    this.jobAssignmentService.getTimesheeetApprovers(clientUuid).subscribe(res => {
      this.timesheetApprovers = res.data;
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log(err);
    });
  }

  get minLimit() {
    if (this.createJobAssignment?.value?.job_uuid) {
      const job = this.jobs?.find((x: any) => x.uuid === this.createJobAssignment?.value?.job_uuid);
      if (job) {
        return job?.start_date
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  get maxLimit() {
    if (this.createJobAssignment?.value?.job_uuid) {
      const job = this.jobs?.find((x: any) => x.uuid === this.createJobAssignment?.value?.job_uuid);
      if (job) {
        return job?.end_date
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.createJobAssignment.value).forEach(element => {
      if (this.createJobAssignment.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Job Assignment Creation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.loading = true;
    const data = {
      job_uuid: this.createJobAssignment.value.job_uuid,
      candidate_uuid: this.createJobAssignment.value.candidate_uuid,
      start_date: this.datePipe.transform(this.createJobAssignment.value.start_date, 'YYYY-MM-dd'),
      end_date: this.datePipe.transform(this.createJobAssignment.value.end_date, 'YYYY-MM-dd'),
      timesheet_approvers: this.createJobAssignment.value.timesheet_approvers,
    };
    this.jobAssignmentService.addJobAssignments(data).subscribe((res) => {
      this.loading = false
      this.activeModalService.close({ refresh: true });
    }, error => {
      this.loading = false
      this.error = error;
    }
    );
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import * as moment from 'moment';
import { DateFilter, Timesheet, TimesheetApprovalLinks, TimesheetDetails, TimesheetParams, TimesheetViewLink, Agency } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class TimesheetService {
  filterOptions: DateFilter[] = [
    {
      label: 'This week',
      start_date: moment().startOf('week').format('YYYY-MM-DD'),
      end_date: moment().endOf('week').format('YYYY-MM-DD'),
    },
    this.startAndEndOfWeek('Last 2 weeks'),
    {
      label: 'Last Week',
      start_date: moment().subtract(1, 'weeks').startOf('week').add(1, 'day').format('YYYY-MM-DD'),
      end_date: moment().subtract(1, 'weeks').endOf('week').add(1, 'day').format('YYYY-MM-DD'),
    },
    {
      label: 'This month',
      start_date: String(new Date(new Date().setDate(1))),
      end_date: String(new Date(new Date().setDate(this.getDays(new Date())))),
    },
    {
      label: 'Last month',
      start_date: String(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1))),
      end_date: String(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(this.getDays(new Date(new Date().setMonth(new Date().getMonth() - 1)))))),
    },
    {
      label: 'Most Recent',
      start_date: '',
      end_date: '',
    },
  ]
  weekDays: any[] = [
    'sun',
    'mon',
    'tues',
    'wed',
    'thurs',
    'fri',
    'sat',
  ]
  statusArr: any[] = [
    'Submitted',
    'Approved',
    'Rejected',
    'Pending'
  ]

  constructor(private http: HttpClient) { }

  startAndEndOfWeek(label: string) {
    var now = new Date();
    now.setHours(0, 0, 0, 0);

    var monday = new Date(now);
    monday.setDate(monday.getDate() - monday.getDay() + 1);
    if (label === 'Last 2 weeks') {
      monday.setDate(monday.getDate() - 14);
    }

    var sunday = new Date(now);
    sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
    if (label === 'Last 2 weeks') {
      sunday.setDate(sunday.getDate() - sunday.getDay() - 7);
    }
    return { label, start_date: String(monday), end_date: String(sunday) }
  }


  getDays(date: any) {
    var yyyy = date.getFullYear(),
      mm = date.getMonth()
    return new Date(yyyy, mm + 1, 0).getDate();
  }

  getAgencies(page: number) {
    return this.http.get<Agency>(`${environment.baseUrl + apiConstant.AGENCIES}?page=${page}`);
  }

  getCandidates(page?: number, search?: string, isActive?:number) {

    let url = `${environment.baseUrl + apiConstant.USERS}?page=${page}&is_active=${isActive}&order_by=created_at&order=desc`;
   
    if(search != undefined) {
      url = url + `&search=${search}`;
    }
    return this.http.get<TimesheetDetails>(url, {});
  }

  getCandidatesWithSearch(search: string) {
    return this.http.get<TimesheetDetails>(`${environment.baseUrl + apiConstant.USERS}?search=${search}`);
  }

  createAgencies(payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.AGENCIES, payload);
  }

  createCandidate(payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.USERS, payload);
  }

  updateCandidate(payload:any, candidateUuid: string) {
    return this.http.patch<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.USERS+`/` + candidateUuid, payload);
  }

  createTimesheet(payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.ADD_TIMESHEET, payload);
  }

  getTimesheetCountByStatus(status: string) {
    return this.http.get<Timesheet>(`${environment.baseUrl + apiConstant.TIMESHEETS}?type=count&status=${status}`)
  }

  getTimesheetApprovalLinks(timesheetUuid: string) {
    return this.http.get<TimesheetApprovalLinks>(`${environment.baseUrl + timesheetUuid}/` + apiConstant.TIMESHEET_APPROVAL_LINK, {});
  }

  getTimesheetViewLink(timesheetUuid: string) {
    return this.http.get<TimesheetViewLink>(`${environment.baseUrl + timesheetUuid}/` + apiConstant.TIMESHEET_VIEW_LINK, {});

  }

  getTimesheets(params?: TimesheetParams) {

    let order = params?.order ? params.order : 'created_by';
    let orderBy = params?.order_by ? params.order_by : 'desc';
    return this.http.get<Timesheet>(!params?.start_date ?
      (`${environment.baseUrl + apiConstant.TIMESHEETS}?
      order=${params?.order}&
      per_page=${params?.limit}&
      page=${params?.page}&
      order_by=${params?.order_by}`) :
      (`${environment.baseUrl + apiConstant.TIMESHEETS}?
      per_page=${params.limit}&
      page=${params.page}&
      start_date=${params.start_date}&
      end_date=${params.end_date}&
      status=${params.status}`
      ));
  }

  getTimesheetDetails(timesheetId: any) {
    return this.http.get<TimesheetDetails>(`${environment.baseUrl + timesheetId}/` + apiConstant.TIMESHEET_DETAILS, {});
  }

  submitTimeSheet(timesheetId: any, payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl + timesheetId}/` + apiConstant.SUBMIT_TIMESHEET, payload);
  }

  approveTimeSheet(timesheetId: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl + timesheetId}/` + apiConstant.APPROVE_TIMESHEET, { timesheet_uuid: timesheetId });
  }

  rejectTimeSheet(timesheetId: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl + timesheetId}/` + apiConstant.REJECT_TIMESHEET, { timesheet_uuid: timesheetId });
  }

  deleteTimeSheet(timesheetId: any) {
    return this.http.delete<TimesheetDetails>(`${environment.baseUrl + apiConstant.TIMESHEETS}/` + timesheetId, {});
  }

  sendTimesheetInvoice(timesheetUuid: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl + timesheetUuid}/` + apiConstant.RESEND_INVOICE, {});
  }

  submitTimesheetForm(payload: any, formUuid: string) {
    if (formUuid) {
      return this.http.patch<TimesheetDetails>(`${environment.baseUrl + apiConstant.TIMESHEET_FORM}/` + formUuid, payload);
    } else {
      return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.TIMESHEET_FORM, payload);
    }
  }

  getTimesheetForm() {
    return this.http.get<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.TIMESHEET_FORM, {});
  }
  forgotPassword(payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.FORGOT_PASSWORD, payload);
  }

  setPassword(payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.RESET_PASSWORD, payload);
  }

  changePassword(payload: any) {
    return this.http.post<TimesheetDetails>(`${environment.baseUrl}` + apiConstant.CHANGE_PASSWORD, payload);
  }

  getAuditTrail(type: string, uuid: string) {
    return this.http.get<TimesheetDetails>(`${environment.baseUrl + apiConstant.AUDIT_TRAIL}/${type}/${uuid}?per_page=${20}`);
  }
  /*updateTimesheet(payload: any) {   
      return this.http.patch<TimesheetDetails>(`${environment.baseUrl}update-timesheet`, payload);
  }*/
  updateTimesheet(timesheetId: any, payload: any) {
    return this.http.patch<TimesheetDetails>(`${environment.baseUrl + timesheetId}/update-timesheet`, payload);
  }
}

<div class="container-fluid max-w-screen-lg vstack gap-6 mt-4">
    <div class="card">
        <div class="card-body">
            <div class="reminder mb-0 d-flex bg-light justify-content-between">
                <h5 class="card-title ps-4 mb-0 tableTitle">Reminders</h5>
                <div class="submitForm d-flex justify-content-center align-items-center">
                    <button class="submitForm btn btn-sm px-3 mx-5 btn-neutral d-flex align-items-center text-white"
                        (click)="onAdd()">
                        <i id="add" class="bi bi-plus-circle-dotted me-2"></i>
                        Add
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap">
                    <thead class="table-light">
                        <tr>
                            <th mat-sort-header="message" scope="col" class="ps-4">Message</th>
                            <th mat-sort-header="day" scope="col">Day</th>
                            <th mat-sort-header="is_active" scope="col">Active/Inactive</th>
                            <th mat-sort-header="time" scope="col">Time</th>
                            <th scope="col">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let reminder of sortedReminders; let i = index">
                            <td class="text-heading font-semibold">{{reminder.message}}</td>
                            <td>{{timesheetService.weekDays[reminder.day] | uppercase}}</td>
                            <td>{{reminder.is_active ? 'Active':'Inactive'}}</td>
                            <td>{{reminder.time}}</td>
                            <td (click)="onAdd(reminder)">
                                <a class="btn btn-neutral btn-sm bt-square ms-1" matTooltip="Delete">
                                    <i class="bi bi-pencil-fill"></i>
                                </a>
                            </td>
                        </tr>
                </table>
            </div>
        </div>
    </div>
</div>
import { FormGroup, ValidatorFn, AbstractControl } from '@angular/forms';

export class ConfirmPasswordValidator {
  public static confirmPasswordValidator(
    form: FormGroup,
    fieldName: string
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const passwordField = form.get(fieldName);
      const password = passwordField?.value;
      if (control.value !== undefined && control.value !== password) {
        return { confirmPassword: true };
      }
      return null;
    };
  }
}

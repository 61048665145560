import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { apiConstant, roleConstant } from 'src/app/app.constant';
import { TaskService } from 'src/app/services/task.service';
import { AlertModal, Tasks } from 'src/app/app.model';
import { AttachmentsService } from 'src/app/services/attachments.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit {
  loading: boolean = false;
  taskUuid: string = '';
  user: any = this.authService.userValue;
  taskForm: any = new FormGroup({
    taskName: new FormControl('', [Validators.required]),
    description: new FormControl('', [Validators.required]),
    status: new FormControl('not started', [Validators.required]),
    assigned_to_uuid: new FormControl('', this.user.role_uuid === roleConstant.CLIENT ? [Validators.required] : []),
  });
  ROLES = roleConstant;
  public API_CONSTANT = apiConstant;
  public statusOptions = this.taskService.statusOptions;
  cadidates: any = []
  acceptFiles: any = [
    '.pdf'
  ]
  attachmentsArr: any = [];
  newAttachmentsArrToSubmit: any = [];
  assignees: any = [];

  constructor(
    private authService: AuthenticationService,
    private attachmentsService: AttachmentsService,
    private taskService: TaskService,
    private timesheetService: TimesheetService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public location: Location,
  ) {
    if (this.route.snapshot.queryParams['taskUuid']) {
      this.taskUuid = this.route.snapshot.queryParams['taskUuid'];
      this.loading = true;
      this.taskService.getTasks().subscribe((res: any) => {
        this.loading = false;
        const taskData = res.data.find((x: any) => x.uuid === this.taskUuid);
        if (taskData) {
          this.taskForm.patchValue({
            taskName: taskData.name,
            description: taskData.description,
            status: taskData.status,
            assigned_to_uuid: taskData.assigned_to_uuid,
          })
          this.fetchAttachments()
        }
      })
    }
  }

  ngOnInit(): void {
    if(this.user.role_uuid === roleConstant.CLIENT){
      this.fetchAssignees();
    }
  }

  async fetchAssignees() {
    this.assignees = await new Promise((resolve, reject) => this.timesheetService.getCandidates(1).subscribe((res: any) => resolve(res?.data.filter((x: any) => x.role === 'Candidate')), err => reject(err)))
    console.log(this.assignees)
  }

  async fetchAttachments() {
    this.loading = true;
    const res: any = await new Promise((resolve, reject) => this.attachmentsService.getAttachments(this.taskUuid).subscribe((res: any) => resolve(res), err => reject(err)))
    res.forEach((attachment: any) => {
      this.attachmentsArr.push(this.attachmentsService.base64IntoFile(attachment.contents, attachment.originalName, 'application/pdf'))
    });
    this.loading = false;
  }

  onSearchChange(event: any) {
    this.taskForm.patchValue({
      [event.formControlName]: event.value.uuid
    })
  }

  selectFile(event: any, inputFile: any) {
    event = event || window.event;
    if (event.target.id != 'filechose_button') {
      inputFile.click();
    }
  }

  formatBytes(bytes: any, decimals = 2) {
    if (!+bytes) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (`${parseFloat(
      (bytes / Math.pow(k, i)).toFixed(dm)
    )} ${sizes[i]}`);
  }

  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      const modalRef = this.modalService.open(AlertModalComponent, {
        size: 'md',
        modalDialogClass: 'alert-modal modal-dialog-centered',
        backdrop: false,
      });
      let data: AlertModal = {
        message: 'Are you sure you want to upload this attachment?',
        yesAction: 'Upload',
        noAction: 'Cancel',
        yesActionColor: '#02cc89',
        noActionColor: 'transparent',
        image: 'assets/img/approve-confirmation-icon.png'
      }
      modalRef.componentInstance.data = data;
      modalRef.result.then((data: any) => {
        if (data?.action === 'yes') {
          const file = event.target.files[0];
          this.attachmentsArr.push(file);
          this.newAttachmentsArrToSubmit.push(file)
        }
      });
    }
  }

  onCreate() {
    this.taskForm.markAllAsTouched()
    if (!this.taskForm.valid) {
      return
    }
    const payload: Tasks = {
      name: this.taskForm.controls['taskName'].value,
      description: this.taskForm.controls['description'].value,
      status: this.taskForm.controls['status'].value,
      assigned_to_uuid: this.taskForm.controls['assigned_to_uuid'].value ?? null,
      uuid: this.taskUuid,
    };
    this.loading = true;
    this.taskService.createTasks(payload).subscribe((res: any) => {
      if (res.task) {
        this.uploadAttachments(res.task?.uuid ?? res.task);
      }
    }, (error) => {
      confirm(error)
      console.log(error);
    });
  }

  async uploadAttachments(taskUuid: string) {
    for (let attachment of this.newAttachmentsArrToSubmit) {
      const formData = new FormData();
      formData.append('files', attachment);
      formData.append('timesheetId', taskUuid);
      formData.append('name', attachment.name);
      await new Promise(resolve => {
        this.attachmentsService.uploadAttachment(formData).subscribe(res => {
          resolve(true)
        }, err => {
          confirm(err)
          resolve(true)
        })
      })
    }
    this.loading = false;
    this.location.back();
  }
}

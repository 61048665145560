import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { Tasks } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class TaskService {
  statusOptions = [
    {
      name: 'Not Started',
      key: 'not started',
    },
    {
      name: 'In Progress',
      key: 'in progress',
    },
    {
      name: 'Completed',
      key: 'completed',
    },
    {
      name: 'Signed',
      key: 'signed',
    },
  ];

  constructor(
    private http: HttpClient,
  ) { }

  getTasks() {
    return this.http.get<Tasks>(`${environment.baseUrl + apiConstant.GET_ASSIGNED_TASK}`);
  }

  createTasks(payload: Tasks) {
    if(payload.uuid){
      return this.http.put<Tasks>(`${environment.baseUrl + apiConstant.TASKS}/${payload.uuid}`, payload);
    }else{
      return this.http.post<Tasks>(`${environment.baseUrl + apiConstant.TASKS}`, payload);
    }
  }
}

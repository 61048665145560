<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">Edit Candidate</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
  </div>
  
  <form class="" [formGroup]="userForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="row g-3">
        <div class="col-md-12">
          <label for="form-label">First Name</label>
          <input type="text" class="form-control" formControlName="first_name"
            [id]="userForm.controls.first_name.errors && userForm.controls.first_name.touched?'invalidField':''">
        </div>
        <div class="col-md-12">
          <label for="form-label">Last Name</label>
          <input type="text" class="form-control" formControlName="last_name"
            [id]="userForm.controls.last_name.errors && userForm.controls.last_name.touched?'invalidField':''">
        </div>
        <div class="col-md-12">
          <label for="form-label">Email</label>
          <input type="text" class="form-control" formControlName="email"
            [id]="userForm.controls.email.errors && userForm.controls.email.touched?'invalidField':''">
        </div>
        <div class="col-md-12">
          <label for="form-label">Identifier</label>
          <input type="text" class="form-control" formControlName="identifier"
            [id]="''">
        </div>
        <div class="col-md-12">
          <label for="form-label">SSN/NI</label>
          <input type="text" class="form-control" formControlName="ssn"
            [id]="''">
        </div>
        <div class="error" *ngIf="error">
          <span>{{error}}</span>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-sm mx-2 btn-primary" [disabled]="!userForm.valid">Save</button>
    </div>
  </form>
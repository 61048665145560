<div class="loader" *ngIf="loading">
  <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <h3 class="modal-title">Add Job Assignment</h3>
  <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<div class="modal-body">
  <form [formGroup]="createJobAssignment" class="row g-3" *ngIf="createJobAssignment">
    <div class="vstack gap-5">
      <div class="row g-3">

        <div class="col-md-12">
          <label for="form-label">Select Job</label>
          <input formControlName="job_uuid" style="display: none;">
          <app-search-select (onChange)="onSearchChange($event)" [endpoint]="API_CONSTANT.JOBS"
            [control]="createJobAssignment.controls.job_uuid" controlName="job_uuid" titleKey="title">
          </app-search-select>
        </div>

        <div class="col-md-12">
          <label for="form-label">Timesheet Approvers</label>
          <mat-select class="me-2 input-form" type="text" multiple  formControlName="timesheet_approvers">
            <mat-option *ngFor="let timesheetApprover of timesheetApprovers.approvers; let i = index;" [value]="timesheetApprover.uuid">{{timesheetApprover.name}}
            </mat-option>
        </mat-select>	
        </div>

        <div class="col-md-12">
          <label for="form-label">Select Candidate</label>
          <input formControlName="candidate_uuid" style="display: none;">
          <app-search-select (onChange)="onSearchChange($event)" [endpoint]="API_CONSTANT.USERS"
            [control]="createJobAssignment.controls.candidate_uuid" controlName="candidate_uuid" titleKey="name">
          </app-search-select>
        </div>

        <div class="col-md-12">
          <label for="form-label">Start Date - End Date</label>
          <div class="datePickerRangeContainer" (click)="picker.open()"
            [ngClass]="{'invalidField':(createJobAssignment?.controls['start_date']?.errors && createJobAssignment?.controls['start_date']?.touched) || createJobAssignment?.controls['end_date']?.errors && createJobAssignment?.controls['end_date']?.touched}">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
            <i class="bi bi-calendar4"></i>
            <!-- <mat-date-range-input [rangePicker]="picker" [disabled]="!createJobAssignment?.value?.job_uuid"
              [min]="minLimit" [max]="maxLimit">
              <input matStartDate formControlName="start_date">
              <input matEndDate formControlName="end_date">
            </mat-date-range-input> -->

            <mat-date-range-input [rangePicker]="picker" [min]="minLimit" [max]="maxLimit">
              <input matStartDate formControlName="start_date">
              <input matEndDate formControlName="end_date">
            </mat-date-range-input>

          </div>
        </div>

      </div>
    </div>
  </form>
  <div class="error" *ngIf="error">
    <span>{{error}}</span>
  </div>
</div>
<div class="modal-footer justify-content-center">
  <div class="text-center">
    <button type="button" class="btn btn-sm btn-neutral me-5" (click)="onClose()">
      Cancel
    </button>
    <button type="submit" class="btn btn-sm btn-primary" [disabled]="!createJobAssignment.valid || loading"
      (click)="onSubmit()">Save</button>
  </div>
</div>
<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h4 class="modal-title">{{reminder_title}}</h4>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>
<form [formGroup]="reminderForm" class="reminderForm">
    <div class="modal-body">
        <div class="fieldsContainer">
            <div class="selectTimeSheet">
                <label for="selectTimeSheet">Message</label>
                <input type="text" class="input-form" formControlName="message"
                    [id]="reminderForm.controls.message.errors && reminderForm.controls.message.touched?'invalidField':''">
            </div>
            <div class="selectTimeSheet">
                <label for="selectTimeSheet">Role</label>
                <mat-select class="form-select" formControlName="role_uuid"
                    [id]="reminderForm.controls.role_uuid.errors && reminderForm.controls.role_uuid.touched?'invalidField':''">
                    <mat-option *ngFor="let role of roles; let i = index;" [value]="role.uuid">{{role.role}}
                    </mat-option>
                </mat-select>
            </div>
            <div class="selectTimeSheet half me-20x">
                <label for="selectTimeSheet">Day</label>
                <mat-select class="form-select" formControlName="day"
                    [id]="reminderForm.controls.day.errors && reminderForm.controls.day.touched?'invalidField':''">
                    <mat-option *ngFor="let day of timesheetService.weekDays; let i = index;" [value]="i">{{day |
                        uppercase}}</mat-option>
                </mat-select>
            </div>
            <div class="selectTimeSheet half">
                <label for="selectTimeSheet">Time</label>
                <input type="text" class="input-form" formControlName="time"
                    [id]="reminderForm.controls.time.errors && reminderForm.controls.time.touched?'invalidField':''"
                    [ngxTimepicker]="time" [format]="24">
                <ngx-material-timepicker [theme]="timePickerTheme" #time></ngx-material-timepicker>
            </div>
            <div class="toggleContainer">
                <span>Active</span>
                <mat-slide-toggle formControlName="is_active" def></mat-slide-toggle>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-sm mx-2 btn-primary" (click)="onSumit()"
            [disabled]="!reminderForm.valid">Set Reminder</button>
    </div>
</form>
import { DatePipe } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, OnInit, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbAlert, NgbAlertConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from 'src/app/app.model';
import { JobService } from 'src/app/services/job.service';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

@Component({
  selector: 'app-edit-timesheet',
  templateUrl: './edit-timesheet.component.html',
  styleUrls: ['./edit-timesheet.component.css']
})
export class EditTimesheetComponent implements OnInit {
  @Input() timesheet: any;
  editTimesheet: FormGroup | any;
	
  jobs: any = [];
  
  jobStartDate = '';
  jobEndDate = '';
  formUuid = ''; 
	 
  candidates: any = [];
  error: any = [];
  loading: boolean = false
  formControls: any = {};

  constructor(
    private jobService: JobService,
    private timesheetService: TimesheetService,
    public datePipe: DatePipe,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
	  //console.log(this.timesheet);
	  
    this.editTimesheet = new FormGroup({
      timesheet_uuid: new FormControl(this.timesheet.uuid, Validators.required),
	  job_name: new FormControl(this.timesheet.job, Validators.required),
      candidate_uuid: new FormControl(this.timesheet.candidate_uuid, Validators.required),
	  candidate_name: new FormControl(this.timesheet.candidate, Validators.required),
      start_date: new FormControl(this.timesheet.start_date, Validators.required),
      end_date: new FormControl(this.timesheet.end_date, Validators.required),
    });	
	
	if(this.timesheet.timesheet_form_details[0].uuid){
		this.formUuid = this.timesheet.timesheet_form_details[0].uuid;
	}

	this.jobStartDate = this.timesheet.job_start_date;
	this.jobEndDate = this.timesheet.job_end_date;
	
  } 

  get minLimit() {
    if (this.editTimesheet?.value?.timesheet_uuid) {
      if (this.jobStartDate) {
        return this.jobStartDate
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  get maxLimit() {
    if (this.editTimesheet?.value?.timesheet_uuid) {
      if (this.jobEndDate) {
        return this.jobEndDate
      } else {
        return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      }
    } else {
      return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.editTimesheet.value).forEach(element => {
      if (this.editTimesheet.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Timesheet Updation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.loading = true;
    const data = {
      timesheet_uuid: this.editTimesheet.value.timesheet_uuid,
      form_uuid: this.formUuid,
      start_date: this.datePipe.transform(this.editTimesheet.value.start_date, 'YYYY-MM-dd'),
      end_date: this.datePipe.transform(this.editTimesheet.value.end_date, 'YYYY-MM-dd'),
    };
    //this.timesheetService.updateTimesheet(data).subscribe((res) => {
	this.timesheetService.updateTimesheet(this.editTimesheet.value.timesheet_uuid, data).subscribe((res) => {	
      this.loading = false
      this.activeModalService.close({ refresh: true });
    }, error => {
      this.loading = false
      this.error = error;
    }
    );
    console.log(this.editTimesheet.value);
  }
}
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { TimesheetService } from '../services/timesheet.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetDetailsComponent } from '../timesheet-details/timesheet-details.component';
import { EditTimesheetComponent } from '../components/edit-timesheet/edit-timesheet.component';
import { MatSort, Sort } from '@angular/material/sort';
import { AuthenticationService } from '../services/authentication.service';
import { agencyConstant, roleConstant, statusConstant } from '../app.constant';



import { pairwise } from 'rxjs';
import { DatePipe } from '@angular/common';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { MatSelectChange } from '@angular/material/select';
import { NavigationExtras, Router } from '@angular/router';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

import { SubmitTimesheetComponent } from '../submit-timesheet/submit-timesheet.component';
import { AlertModal, DateFilter, TimesheetParams, SuccessModal } from '../app.model';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';
import { DataSharedService } from '../services/data-shared.service';
import { TimesheetApprovalLinkModalComponent } from '../components/timesheet-approval-link-modal/timesheet-approval-link-modal.component';
import { TimesheetViewLinkModalComponent } from '../components/timesheet-view-link-modal/timesheet-view-link-modal.component';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
export interface Dessert {
  calories: number;
  carbs: number;
  fat: number;
  name: string;
  protein: number;
}

@Component({
  selector: 'app-timesheets',
  templateUrl: './timesheets.component.html',
  styleUrls: ['./timesheets.component.css'],
})
export class TimesheetsComponent implements OnInit {
  @ViewChild('statusSelect') statusSelect!: FormControl;
  @ViewChild('mainContainer') mainContainer!: ElementRef;
  @ViewChild('picker', { static: true }) _picker!: MatDatepicker<Date | any>;
  @ViewChild('filterDate') filterDate: any;
  @ViewChild('table') table = new MatSort();
  @Input() needMargins: boolean = false;
  public selecting = false;
  public CLOSE_ON_SELECTED = false;
  public init = new Date();
  public resetModel = new Date(0);
  public timesheets: any = [];
  public timesheet_fields: any = [];
  public user: any;
  public mainContainerHeight: any;
  public ROLES = roleConstant;
  public STATUS = statusConstant;
  public AGENCY = agencyConstant;
  public agency_uuid: string | null = localStorage.getItem('agency_uuid');
  public isOpen: boolean = false;
  public auditTrailActivities: any = [];
  public showDropDown: boolean = false;
  public showTimesheetOptions: boolean = false;
  public extraColumns: any[] = []
  public sortedTimesheet!: any[];
  public loading: boolean = false;
  public filterOptions: any = this.timesheetService.filterOptions;
  public permissions: any = {}
  public selectedFilterOption: any = this.filterOptions[5].label;
  public selectedStatusFilter: any = [];
  public start_date: any
  public end_date: any
  public selectAll: boolean = false;
  public totalTimesheetCount: number = 0;
  public auditTrail: any = {
    timesheet: {}
  }

  public custom_datepicker_text: string = "Custome Date picker";

  constructor(
    public timesheetService: TimesheetService,
    private modalService: NgbModal,
    public authService: AuthenticationService,
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private router: Router,
    private activeModalService: NgbActiveModal,
    public dataSharedService: DataSharedService
  ) {
    this.setStartEndDate()
    this.updateHeight();
    this.user = this.authService.userValue;
    this.permissions.clients = this.user.permissions.find((x: any) => x.permission === 'Clients');
    this.permissions.jobs = this.user.permissions.find((x: any) => x.permission === 'Jobs');
    this.permissions.candidates = this.user.permissions.find((x: any) => x.permission === 'Candidates');
    this.permissions.assignments = this.user.permissions.find((x: any) => x.permission === 'Assignments');
  }

  updateHeight() {
    setTimeout(() => {
      this.mainContainerHeight = this.mainContainer.nativeElement.offsetHeight;
    }, 1);
  }

  openAuditTrailPanel(timesheet: any) {
    this.isOpen = true;
    this.auditTrail.timesheet = timesheet;
    this.timesheetService.getAuditTrail('timesheet', timesheet.uuid).subscribe(res => {
      this.auditTrailActivities = res.data;
    });
  }

  onSelectAll() {
    this.timesheets.forEach((timesheet: any) => {
      timesheet.selected = this.selectAll
    });
  }

  allSelected() {
    let allSelected: boolean = true;
    this.timesheets.forEach((timesheet: any) => {
      if (!timesheet?.selected) {
        allSelected = false;
      }
    });
    return allSelected;
  }

  onSingleSelect() {
    this.selectAll = this.allSelected();
  }

  isAllSelectedIsSubmitted() {
    let isAllSubmitted = true;
    this.timesheets.forEach((timesheet: any) => {
      if (timesheet?.selected && timesheet.status !== this.STATUS.SUBMITTED) {
        isAllSubmitted = false;
      }
    });
    return isAllSubmitted
  }

  isSelected() {
    let isSelected = false;
    this.timesheets.forEach((timesheet: any) => {
      if (timesheet?.selected) {
        isSelected = true;
      }
    });
    return isSelected;
  }

  selectToggle() {
    this.selecting = !this.selecting;
    this.selectAll = false;
    this.timesheets.forEach((element: any) => {
      delete element.selected;
    });
  }

  redirect(url: string, name: any) {
    if ((url === '/jobs' && this.permissions.jobs) || (url === '/candidates' && this.permissions.candidates) || (url === '/clients' && this.permissions.clients)) {
      this.router.navigate([url], { queryParams: { name } });
    }
  }

  redirectAssignments(url: string, job: any) {
    if ((url === '/assignments' && this.permissions.assignments)) {
      this.router.navigate([url], { queryParams: { job } });
    }
  }

  async onApproveAll() {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to approve selected Timesheets?',
      yesAction: 'Approve',
      noAction: 'Cancel',
      yesActionColor: '#02cc89',
      noActionColor: 'transparent',
      image: 'assets/img/approve-confirmation-icon.png'
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then(async (data) => {
      if (data?.action === 'yes') {
        this.loading = true;
        for (let timesheet of this.timesheets) {
          if (timesheet.selected) {
            await new Promise(resolve => {
              this.timesheetService.approveTimeSheet(timesheet.uuid).subscribe(res => {
                console.log(res);
                resolve(true);
              }, error => {
                resolve(false);
                console.error(error);
              });
            });
          }
        }
        this.loading = false;
        this.ngOnInit();
      }
    });
  }

  async onRejectAll() {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to reject selected Timesheets?',
      yesAction: 'Reject',
      noAction: 'Cancel',
      yesActionColor: 'red',
      noActionColor: 'transparent',
      image: 'assets/img/reject-confirmation-icon.png'
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then(async (data) => {
      if (data?.action === 'yes') {
        this.loading = true;
        for (let timesheet of this.timesheets) {
          if (timesheet.selected) {
            await new Promise(resolve => {
              this.timesheetService.rejectTimeSheet(timesheet.uuid).subscribe(res => {
                console.log(res);
                resolve(true);
              }, error => {
                resolve(false);
                console.error(error);
              });
            })
          }
        }
        this.loading = false;
        this.ngOnInit();
      }
    });
  }

  onReject(timesheetId: any) {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to reject the Timesheet?',
      yesAction: 'Reject',
      noAction: 'Cancel',
      yesActionColor: 'red',
      noActionColor: 'transparent',
      image: 'assets/img/reject-confirmation-icon.png'
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.loading = true;
        this.timesheetService.rejectTimeSheet(timesheetId).subscribe(res => {
          this.loading = false;
          this.ngOnInit();
        }, error => {
          console.error(error);
          this.loading = false;
          this.ngOnInit();
        });
      }
    });
  }

  openTimesheetApprovalLinks(timesheetUuid: any) {
    this.dialog.open(TimesheetApprovalLinkModalComponent, {
      width: '75%',
      maxWidth: '800px',
      maxHeight: '700px',
      height: '85%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
      data: {
        timesheetUuid: timesheetUuid,
      },
    });
  }

  openTimesheetViewLink(timesheetUuid: any) {
    this.dialog.open(TimesheetViewLinkModalComponent, {
      width: '75%',
      maxWidth: '800px',
      maxHeight: '700px',
      height: '35%',
      disableClose: true,
      panelClass: 'timesheetDetailDialog',
      data: {
        timesheetUuid: timesheetUuid,
      },
    })
  }

  sendTimesheetInvoice(timesheetUuid:any) {
    console.log(timesheetUuid);
    this.timesheetService.sendTimesheetInvoice(timesheetUuid).subscribe(res => {
      this.successModal('Timesheet Invoice Sent!');
    }, error => {
      this.loading = false;
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onApprove(timesheetId: any) {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to approve the Timesheet?',
      yesAction: 'Approve',
      noAction: 'Cancel',
      yesActionColor: '#02cc89',
      noActionColor: 'transparent',
      image: 'assets/img/approve-confirmation-icon.png'
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.loading = true;
        this.timesheetService.approveTimeSheet(timesheetId).subscribe(res => {
          this.loading = false;
          this.ngOnInit();
        }, error => {
          console.error(error);
          this.loading = false;
          this.ngOnInit();
        });
      }
    });
  }

  public dateChanged(event: MatDatepickerInputEvent<Date>): void {
    if (this.start_date && this.end_date) {

      let start_date = this.datePipe.transform(this.start_date, 'yyyy-MM-dd');
      let end_date = this.datePipe.transform(this.end_date, 'yyyy-MM-dd');

      this.custom_datepicker_text = `${start_date} - ${end_date}`;
      this.selectedFilterOption = `${start_date} - ${end_date}`;

      this.ngOnInit();
    }
  }

  onFilterChange(picker: any) {
    if (this.selectedFilterOption === 'datePicker') {
      picker.open();
    } else {
      this.setStartEndDate()
      this.ngOnInit()
    }
  }

  setStartEndDate() {
    const selectedFilter: DateFilter = this.filterOptions.find((x: DateFilter) => x.label === this.selectedFilterOption);
    if(selectedFilter.start_date != '' && selectedFilter.end_date != '') {
      this.start_date = selectedFilter.start_date;
      this.end_date = selectedFilter.end_date;
    }  
  }

  openStatusFilter(statusFilter: any) {
    statusFilter.open();
  }

  onStatusFilterChange(event: MatSelectChange) {
    this.ngOnInit();
  }

  filterTimeSheet(timesheets: any[]) {
    const filteredTimesheetsArr: any[] = [];
    const start_date = new Date(this.datePipe.transform(this.start_date, 'yyyy-MM-dd') + ' ' + "00:00").getTime();
    const end_date = new Date(this.datePipe.transform(this.end_date, 'yyyy-MM-dd') + ' ' + "00:00").getTime();
    timesheets.forEach(element => {
      const timesheetDate = new Date(this.datePipe.transform(element.start_date, 'yyyy-MM-dd') + ' ' + "00:00").getTime();
      if (timesheetDate >= start_date && timesheetDate <= end_date) {
        filteredTimesheetsArr.push(element)
      }
    });
    return filteredTimesheetsArr;
  }

  onStepChange(event: any) {
    this.ngOnInit(event)
  }

  ngOnInit(page = 1): void {

    this.sortedTimesheet = []
    this.timesheets = []
    this.extraColumns = []
    this.loading = true;
    if (page === 1) {
      this.totalTimesheetCount = 0;
    }
    const params: TimesheetParams = {
      limit: 20,
      page,
      start_date: '',
      end_date: '',
      status: '',
      order_by: '',
      order: '',
    };

    if(this.start_date) {
      params.start_date = String(this.datePipe.transform(this.start_date, 'yyyy-MM-dd'));
    }
    if(this.end_date) {
      params.end_date = String(this.datePipe.transform(this.end_date, 'yyyy-MM-dd'));
    }

    if (this.selectedStatusFilter.length) {
      params.status = this.selectedStatusFilter.join(',').toLowerCase();
    }

    if(this.selectedFilterOption == 'Most Recent') {
      params.order_by = 'created_at';
      params.order = 'desc';
    }
    console.log(params);

    this.timesheetService.getTimesheets(params).subscribe((timesheets: any) => {

      this.totalTimesheetCount = timesheets.meta.total;
      this.timesheet_fields = timesheets?.data[0]?.timesheet_form_details[0]?.fields;
      // this.timesheet_fields.push({
      //   'label': 'Submitted At',
      // });
      this.timesheet_fields = this.timesheet_fields?.filter((x: any) => x.values === "Yes" || x.field_type !== 'static')?.sort((a: any, b: any) => a.order - b.order);

      this.timesheets = timesheets.data;
      if (!this.timesheets.length) {
        this.loading = false;
        return;
      }

      this.sortedTimesheet = this.timesheets.slice();
      this.timesheets.forEach((singleSheet: any, i: number) => {

        singleSheet.fields = [];
        if (this.agency_uuid === this.AGENCY.HYPHEN) {
          singleSheet.fields.push({});
          singleSheet.fields.push({});
        } else {
          singleSheet.fields = this.getBlankArrayOfHours();
        }

        this.updateHeight();

        if (i === this.timesheets.length - 1) {
          this.loading = false;
        }

        const timesheetDetailFields = singleSheet?.timesheet_details;
        const timesheetFormDetails = singleSheet?.timesheet_form_details[0]?.fields;
        const hoursField = timesheetFormDetails?.find((x: any) => x.label === 'Work Duration' || x.label === 'Hours');
        const unitField = timesheetFormDetails?.find((x: any) => x.label === 'Time Unit');

        timesheetDetailFields.forEach((singleField: any, fieldIdx: number) => {
          const submittedHourField = singleField?.field_values?.find((x: any) => x.uuid === hoursField?.uuid);
          const submittedUnitField = singleField?.field_values?.find((x: any) => x.uuid === unitField?.uuid);
          if (this.agency_uuid === this.AGENCY.HYPHEN) {

            if (!this.extraColumns.includes('Work unit') && !this.extraColumns.includes('Work duration')) {
              this.extraColumns = [
                'Work duration',
                'Work unit'
              ]
            }

            if (submittedUnitField?.values === 'Days') {
              if (singleSheet.fields[0]?.days === undefined) {
                singleSheet.fields[0].days = Number(submittedHourField.values);
              } else {
                singleSheet.fields[0].days += Number(submittedHourField.values);
              }
              singleSheet.fields[1].days = 'Days'
            } else if (submittedUnitField?.values === 'Hours') {
              if (singleSheet.fields[0]?.hours === undefined) {
                singleSheet.fields[0].hours = Number(submittedHourField.values);
              } else {
                singleSheet.fields[0].hours += Number(submittedHourField.values);
              }
              singleSheet.fields[1].hours = 'Hours'
            } else {
              singleSheet.fields[0].days = '--'
              singleSheet.fields[1].days = '--'
            }

          } else {
            this.extraColumns = ['mon', 'tues', 'wed', 'thurs', 'fri', 'sat', 'sun'];
            const day = singleField.day_number == 0 ? 6 : singleField.day_number - 1;
            singleSheet.fields[day] = { ...singleField, value: submittedHourField?.values, unit: submittedUnitField?.values };
          }
        });

        this.updateHeight();

        // })
      });

    });

  }

  getBlankArrayOfHours() {
    return [
      { value: '--' },
      { value: '--' },
      { value: '--' },
      { value: '--' },
      { value: '--' },
    ]
  }

  sortData(sort: Sort) {
    const data = this.timesheets.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedTimesheet = data;
      return;
    }

    this.sortedTimesheet = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'job':
          return compare(a.job, b.job, isAsc);
        case 'candidate':
          return compare(a.candidate, b.candidate, isAsc);
        case 'client':
          return compare(a.client, b.client, isAsc);
        case 'start_date':
          return compare(a.start_date, b.start_date, isAsc);
        case 'end_date':
          return compare(a.end_date, b.end_date, isAsc);
        case 'status':
          return compare(a.status, b.status, isAsc);
        default:
          return 0;
      }
    });
  }

  filterTimesheetWithSelection() {
    const filteredArray: any = [];
    this.sortedTimesheet.forEach(singleSheet => {
      if (!this.selecting || singleSheet.selected) {
        filteredArray.push(singleSheet);
      }
    });
    return filteredArray;
  }

  exportTimeSheet(isSelected: boolean) {
    if (this.selecting && !isSelected) {
      return
    }
    const timesheets: any = [];
    const filteredArray: any = this.filterTimesheetWithSelection();
    filteredArray.forEach((singleSheet: any, tIdx: number) => {
      timesheets.push({
        job: singleSheet.job,
        candidate: singleSheet.candidate,
        client: singleSheet.client,
        start_date: singleSheet.start_date,
        end_date: singleSheet.end_date,
        status: singleSheet.status,
        uuid: singleSheet.uuid,
      })
      if (this.agency_uuid === this.AGENCY.HYPHEN) {
        this.extraColumns.forEach((column, i) => {
          timesheets[tIdx][column] = (singleSheet?.fields[i]?.days ? singleSheet.fields[i].days : '') + (singleSheet?.fields[i]?.hours ? singleSheet.fields[i].hours : '')
        });
      } else {
        this.extraColumns.forEach((column, i) => {
          timesheets[tIdx][column] = singleSheet?.fields[i]?.value ? singleSheet.fields[i].value : '--'
        });
      }
    });
    console.log(timesheets);
    const csv = new AngularCsv(timesheets, 'Timesheets');
  }

  openTimesheetDetails(timesheet: any, status: string, readonly: boolean) {
    const modalRef = this.modalService.open(TimesheetDetailsComponent, {
      size: 'lg',
      centered: false,
      keyboard: false,
      backdrop: "static",
      // modalDialogClass: 'timesheet-detail-modal',
      // backdropClass: 'viewClientContacts',
    });

    modalRef.componentInstance.status = status;
    modalRef.componentInstance.readonly = readonly;
    modalRef.componentInstance.timesheet = timesheet;
    modalRef.componentInstance.timesheetId = timesheet.uuid;

    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  // viewTimesheetDetails(timesheet: any, status: string) {
  //   let modalRef = this.dialog.open(TimesheetDetailsComponent, {
  //     data: {
  //       timesheetId: timesheet.uuid,
  //       timesheet,
  //       readonly: true,
  //       status: status,
  //     },
  //   });

  //   modalRef.afterClosed().subscribe(result => {
  //     if (result?.refresh) {
  //       this.ngOnInit();
  //     }
  //   });
  // }

  deleteTimesheet(timesheetId: string) {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to delete the Timesheet?',
      yesAction: 'Delete',
      noAction: 'Cancel',
      yesActionColor: 'red',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.timesheetService.deleteTimeSheet(timesheetId).subscribe(res => {
          this.activeModalService.close();
        });
      }
    });
  }

  editTimesheet(timesheet?: any) {
    const modalRef = this.modalService.open(EditTimesheetComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'edit-timesheet-modal',
    });
    modalRef.componentInstance.timesheet = timesheet
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });

  }

  onRefresh() {
    this.ngOnInit();
  }

}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {
  @Input() dataArray: any;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  currentPageStep: number = 0;
  limit: number = 20;
  pageSteps: any = [1, 2, 3];

  constructor() { }

  ngOnInit(): void {
    this.createPageSteps()
    this.setDataWithPageStep('next')
  }

  addIndexInArray(array: any[]) {
    array.forEach((element: any, i: number) => {
      element.index = i + 1;
    });
    return array;
  }

  createPageSteps() {
    const steps = this.dataArray.length / this.limit;
    if (steps > this.pageSteps.length) {
      this.pageSteps = [];
      for (let i = 0; i < steps; i++) {
        this.pageSteps.push(i + 1)
      }
    }
  }

  setDataWithPageStep(move: string) {
    const newArr: any = [];
    this.dataArray.forEach((element: any, i: number) => {
      if (move === 'next') {
        if (i >= (this.limit * this.currentPageStep) && i < (this.limit * (this.currentPageStep + 1))) {
          newArr.push(element);
        }
      } else {
        if (i >= (this.limit * (this.currentPageStep - 2)) && i < (this.limit * (this.currentPageStep - 1))) {
          newArr.push(element);
        }
      }
    });
    if (newArr.length) {
      this.onStepChange.emit(newArr);
      if (move === 'next') {
        this.currentPageStep++
      } else {
        this.currentPageStep--
      }
    }
  }

  changeStepWithNumber(step: number, disabled: boolean) {
    const newArr: any = [];
    if (disabled || step === this.currentPageStep) {
      return;
    }
    this.dataArray.forEach((element: any, i: number) => {
      if (i >= (this.limit * (step - 1)) && i < (this.limit * step)) {
        newArr.push(element);
      }
    });
    if (newArr.length) {
      this.onStepChange.emit(newArr);
      this.currentPageStep = step
    }
  }

}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../app.model';
import { apiConstant } from '../app.constant';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;


  constructor(private http: HttpClient, private router: Router) {

    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user') || '{}'));
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  onGoogleLogin(access_token: string){
    return this.http.post<User>(`${environment.baseUrl}` + apiConstant.VERIFY_GOOGLE_TOKEN, { access_token })
      .pipe(map(user => {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      })); 
  }

  getInitials(name: string){
    const firstName = name.split(' ')[0]
    const secondName = name.split(' ')[1]??''
    return firstName.substring(0, 1)+secondName.substring(0, 1)
  }

  login(email: string, password: string) {
    return this.http.post<User>(`${environment.baseUrl}` + apiConstant.LOGIN, { email, password })
      .pipe(map(user => {
        localStorage.setItem('user', JSON.stringify(user));
        this.userSubject.next(user);
        return user;
      })); 
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('agency_uuid');
    localStorage.removeItem('permissions');
    localStorage.removeItem('profile_pic');
    localStorage.removeItem('dateFormat');
    localStorage.removeItem('candidateTrackingHrs');
    localStorage.removeItem('timesheetSubmissionEmails');
    localStorage.removeItem('timesheetApprovalEmails');
    this.router.navigate(['login']);
    this.http.post<User>(`${environment.baseUrl}` + apiConstant.LOGOUT, {}).subscribe(res => {
      console.log(res);
    })
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-api-paginator',
  templateUrl: './api-paginator.component.html',
  styleUrls: ['./api-paginator.component.css']
})
export class ApiPaginatorComponent implements OnInit {
  @Input() totalCount: any;
  @Output() onStepChange: EventEmitter<any> = new EventEmitter();
  currentPageStep: number = 1;
  limit: number = 20;
  pageSteps: any = [1, 2, 3];

  constructor() { }

  ngOnInit(): void {
    // setInterval(() => {
      this.createPageSteps()
    // }, 1);
  }

  addIndexInArray(array: any[]) {
    array.forEach((element: any, i: number) => {
      element.index = i + 1;
    });
    return array;
  }

  createPageSteps() {
    const steps = this.totalCount / this.limit;
    if (steps > this.pageSteps.length) {
      this.pageSteps = [];
      for (let i = 0; i < steps; i++) {
        this.pageSteps.push(i + 1)
      }
    }
  }

  changePage(step: number, ) {
    this.currentPageStep = step
    this.onStepChange.emit(this.currentPageStep);
  }

}

<div class="modal-header align-items-start p-0 border-bottom-0 d-flex mb-8 justify-content-between flex-sm-row-reverse">
    <div>
      <button type="button" class="btn-close btn-neutral btn-square"
        aria-label="Close" (click)="close()"></button>
    </div>
    <div class="text-dark align-items-center d-flex flex-column">
      <label class="pt-2 title">Share Timesheet Approval Link</label>
    </div>
    <div class="transparent"></div>
  </div>
 
  <div class="modal-body p-0">
    <table matSort class=" table table-borderless datatable">
        <thead class="table-light">
            <tr>
                <th scope="col" class="ps-4">Approver</th>
                <th scope="col">Link</th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let timesheetApprovalLink of timesheetApprovalLinks; let i = index" class="dataColumn">
                <td class="text-heading font-bold ps-4">
                    <div class="textWithIcon">
                        <div class="avatar avatar-sm bg-warning rounded-circle text-white h-7 w-7 me-2"
                            *ngIf="timesheetApprovalLink.approver_pic">
                            <img alt="..." src="{{dataSharedService.getImage(timesheetApprovalLink.approver_pic)}}">
                        </div>
                        <div *ngIf="!timesheetApprovalLink.approver_pic" class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                            <span class="text-primary fs-10x">{{authService.getInitials(timesheetApprovalLink.approver)}}</span>
                        </div>
                        <span>{{timesheetApprovalLink.approver}}</span>
                    </div>
                </td>
                <td>
                    <input type="text" class="input-form" value="{{timesheetApprovalLink.approver_link}}"/>
                </td>
                <td>
                    <div class="copyLink">
                        <button (click)="copyLink(timesheetApprovalLink.approver_link)" type="button">Copy</button>
                    </div>
                </td>
            </tr>
        </tbody>    
    </table>
  </div>
 
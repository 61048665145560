<!-- <div class="accordion" id="accordionMissingTimesheets">
    <div class="card h-full">
        <div class="card-header" data-toggle="collapse" data-target="#collapseMissingTimesheets" aria-expanded="true" (click)="isCollapsed = !isCollapsed"  
        [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample">     
            <span class="font-semibold text-muted">Missing Timesheets ({{missingTimesheetsCount}})</span>
            <span class="accicon"><i [ngClass]="isCollapsed ? 'bi bi-chevron-down':'bi bi-chevron-up'"></i></span>
        </div>
        <div id="collapseMissingTimesheets" class="collapse show" [ngbCollapse]="isCollapsed" data-parent="#accordionMissingTimesheets">
            <div class="card-body">
                <div class="ms-auto text-end">
                    <a  [routerLink]="" (click)="openAllMissingtimesheets()" class="text-sm font-semibold see-all">See all</a>
                </div>
                <div class="mt-2 mb-0 text-sm">
                    <div class="list-group gap-4">
                        <div *ngFor="let missingTimesheet of missingTimesheets; let i = index" class="list-group-item d-flex align-items-center border rounded">
                            <div class="me-4">
                                <div class="avatar rounded-circle" *ngIf="missingTimesheet.profile_pic">
                                    <img alt="{{missingTimesheet.candidate}}" src="{{dataSharedService.getImage(missingTimesheet.profile_pic)}}">
                                  </div>
                                  <div *ngIf="!missingTimesheet.profile_pic" class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                                    <span class="text-primary fs-10x">{{authService.getInitials(missingTimesheet.candidate)}}</span>
                                  </div>
                            </div>
                            <div class="flex-fill">
                                <a [routerLink]="" (click)="redirect('/candidates', missingTimesheet.candidate)" class="d-block h6 font-semibold mb-1">{{missingTimesheet.candidate}}</a>
                                <span class="d-block text-sm text-muted">{{missingTimesheet.job}}, {{missingTimesheet.client}}</span>
                            </div>
                            <div class="ms-auto text-end">
                                <button type="button" (click)="openSendReminderPopUp(missingTimesheet.candidate_uuid, missingTimesheet.candidate_first_name, missingTimesheet.job, missingTimesheet.client)"  class="btn btn-sm btn-square btn-neutral text-danger-hover">
                                    <i class="bi bi-envelope"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
            </div>
        </div>
    </div>
</div> -->




<div class="card h-full">
    <div class="card-body">
        <div class="row">
            <div class="col">
                <span class="h6 font-semibold text-muted text-sm d-block mb-2">Missing Timesheets
                    ({{missingTimesheetsCount}})</span>
            </div>
            <div class="col-auto">
                <a [routerLink]="" (click)="openAllMissingtimesheets()" class="text-sm font-semibold see-all">See
                    all</a>
            </div>
        </div>
        <div class="mt-2 mb-0 text-sm">
            <div class="list-group gap-4">
                <div *ngFor="let missingTimesheet of missingTimesheets; let i = index"
                    class="list-group-item d-flex align-items-center border rounded">

                    <div class="me-4 profile_pic">
                        <div class="" (click)="redirect('/candidates', missingTimesheet.candidate)"
                            *ngIf="missingTimesheet.profile_pic">
                            <img alt="{{missingTimesheet.candidate}}"
                                src="{{dataSharedService.getImage(missingTimesheet.profile_pic)}}"
                                class="avatar avatar-sm rounded-circle me-2"
                                >
                        </div>
                        <div *ngIf="!missingTimesheet.profile_pic"
                            (click)="redirect('/candidates', missingTimesheet.candidate)"
                            class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                            <span
                                class="text-primary fs-10x">{{authService.getInitials(missingTimesheet.candidate)}}</span>
                        </div>
                    </div>

                    <div class="flex-fill">
                        <a [routerLink]="" (click)="redirect('/candidates', missingTimesheet.candidate)"
                            class="d-block h6 font-semibold mb-1 candidate_name">{{missingTimesheet.candidate}}</a>
                        <span class="d-block text-sm text-muted">{{missingTimesheet.job}},
                            {{missingTimesheet.client}}</span>
                    </div>
                    <div class="ms-auto text-end">
                        <button type="button"
                            (click)="openSendReminderPopUp(missingTimesheet.candidate_uuid, missingTimesheet.candidate_first_name, missingTimesheet.job, missingTimesheet.client)"
                            class="btn btn-sm btn-square btn-neutral text-danger-hover">
                            <i class="bi bi-envelope"></i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
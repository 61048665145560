import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { HttpClient } from '@angular/common/http';
import { Invoices } from '../app.model';



@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private http: HttpClient) { }

  getInvoices(page: number, filters: any = []) {
    let apiUrl = `${environment.baseUrl+apiConstant.INVOICES}?page=${page}`;
    if(filters.active != undefined) {
      apiUrl+= '&filters[active]='+filters.active;
    }

    return this.http.get<Invoices>(apiUrl);
  }
}

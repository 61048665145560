<section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
        <div class="d-flex justify-content-center py-4">
          <a href="index.html" class="logo d-flex align-items-center w-auto">
            <span class="d-none d-lg-block">Clug</span>
          </a>
        </div>
        <div class="card mb-3">
          <div class="card-body">
            <div class="loginLoader">
              <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
            </div>
            <div class="pt-4 pb-2">
              <h5 class="card-title text-center pb-0 fs-4">Login to Your Account</h5>
              <p class="text-center small">Enter your Email & password to login</p>
            </div>
            <form class="row g-3 needs-validation" [formGroup]="loginForm" (ngSubmit)="login()">
              <div class="col-12">
                <label for="email" class="form-label">Email</label>
                <input type="text" formControlName="email" name="somethingAutofillDoesntKnow" class="form-control"
                  id="email" [ngClass]="{ 'is-invalid': submitted && lForm['email'].errors }">
                <div *ngIf="lForm['email'].errors" class="invalid-feedback">
                  <div *ngIf="lForm['email'].errors['required']">Email is required</div>
                </div>
              </div>
              <div class="col-12">
                <label for="password" class="form-label">Password</label>
                <div class="input-group d-flex align-items-center" id="form">
                  <input [type]="show ? 'text' : 'password'" formControlName="password"
                    name="somethingAutofillDoesntKnow" class="form-control" id="password"
                    [ngClass]="{ 'is-invalid': submitted && lForm['password'].errors }"
                    style="border: none !important;">
                  <i [class]="show ? 'bi bi-eye' : 'bi bi-eye-slash'" class="px-2" (click)="show = !show"></i>
                  <div *ngIf="lForm['password'].errors" class="invalid-feedback">
                    <div *ngIf="lForm['password'].errors['required']">Password is required</div>
                    <div *ngIf="lForm['password'].errors['minlength']">Password must be at least 6 characters</div>
                  </div>
                </div>
              </div>
              <div class="rememberMe">
                <mat-checkbox class="me-1" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}"></mat-checkbox>
                <span>Remember me</span>
              </div>
              <a class="dropdown-item" style="cursor: pointer;" (click)="onForgotPassword()">
                Forgot Password
              </a>
              <span style="color: red; font-size: 13px; text-align: center;" *ngIf="err">{{err}}</span>
              <span style="color: #00ad00; font-size: 13px; text-align: center;" *ngIf="emailSent">Password reset email
                sent</span>
              <div class="col-12">
                <button class="btn btn-primary w-100" type="submit">Login</button>
              </div>
              <!-- <div class="col-12">
                <button class="btn btn-primary w-100 googleBtn" type="button" (click)="onGoogleLogin()">
                  <img class="logo" src="../../assets/img/googleIcon.svg">
                  <span style="margin-left: 10px;">Sign in with Google</span>
                </button>
              </div> -->
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { JobAssignmentService } from 'src/app/services/job-assignment.service';
import { CreateTimesheetComponent } from '../create-timesheet/create-timesheet.component';

@Component({
  selector: 'app-candidate-assignments',
  templateUrl: './candidate-assignments.component.html',
  styleUrls: ['./candidate-assignments.component.css']
})
export class CandidateAssignmentsComponent implements OnInit {
  jobAssignments: any = ['', '', '', '', '', '']

  constructor(
    private jobAssignmentService: JobAssignmentService,
    // private authService: AuthenticationService,
    // public dialog: MatDialog,
    private modalService: NgbModal,
    public dataSharedService: DataSharedService
  ) { }

  ngOnInit(): void {
    this.getJobAssignments(1)
  }

  getJobAssignments(page: any) {
    this.jobAssignments = [];
    this.jobAssignmentService
      .getJobAssignments(page, {
        'active': 1,
      })
      .subscribe((jobassignments) => {
        this.jobAssignments = jobassignments.data.splice(0, 4);
      });
  }

  createTimesheet(assignment?: any) {
    const modalRef = this.modalService.open(CreateTimesheetComponent, {
      size: 'lg',
      backdrop: false,
      modalDialogClass: 'add-reminder-modal',
    });

    modalRef.componentInstance.assignment = assignment

    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }
}

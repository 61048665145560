<div class="paginatorContainer">
    <nav aria-label="...">
        <ul class="pagination">
            <li class="page-item action" (click)="setDataWithPageStep('prev')"
                [ngClass]="((currentPageStep-1)*limit) > 0?'':'disabled'">
                <a class="page-link"><span>Previous</span></a>
            </li>
            <li [class]="step === currentPageStep ? 'page-item active':'page-item'" *ngFor="let step of pageSteps"
                (click)="changeStepWithNumber(step, ((step-1)*limit) > (dataArray.length-1))"
                [ngClass]="((step-1)*limit) > (dataArray.length-1)?'disabled':'action'"
                [style.display]="(currentPageStep === step || currentPageStep === step+1 || currentPageStep === step-1) || (step === 3 && currentPageStep === 1) || (step === (pageSteps.length-2) && currentPageStep === pageSteps.length) ?'':'none'">
                <a class="page-link"><span>{{step}}</span></a>
            </li>
            <li class="page-item action" (click)="setDataWithPageStep('next')"
                [ngClass]="(currentPageStep*limit) > (dataArray.length-1)?'disabled':''">
                <a class="page-link"><span>Next</span></a>
            </li>
        </ul>
    </nav>
</div>

import * as moment from 'moment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';



import { DateFilter } from '../app.model';
import { apiConstant } from '../app.constant';
import { PayBillReport, PayBillReportExport } from '../app.model';


@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(private http: HttpClient) { }

  filterDateRangePickerOptions: DateFilter[] = [
    {
      label: 'This week',
      start_date: moment().startOf('week').format('YYYY-MM-DD'),
      end_date: moment().endOf('week').format('YYYY-MM-DD'),
    },
    this.startAndEndOfWeek('Last 2 weeks'),
    {
      label: 'Last Week',
      start_date: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
      end_date: moment().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD'),
    },
    {
      label: 'This month',
      start_date: String(new Date(new Date().setDate(1))),
      end_date: String(new Date(new Date().setDate(this.getDays(new Date())))),
    },
    {
      label: 'Last month',
      start_date: String(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1))),
      end_date: String(new Date(new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(this.getDays(new Date(new Date().setMonth(new Date().getMonth() - 1)))))),
    },
  ]

  startAndEndOfWeek(label: string) {
    var now = new Date();
    now.setHours(0, 0, 0, 0);

    var monday = new Date(now);
    monday.setDate(monday.getDate() - monday.getDay() + 1);
    if (label === 'Last 2 weeks') {
      monday.setDate(monday.getDate() - 14);
    }

    var sunday = new Date(now);
    sunday.setDate(sunday.getDate() - sunday.getDay() + 7);
    if (label === 'Last 2 weeks') {
      sunday.setDate(sunday.getDate() - sunday.getDay() - 7);
    }
    return { label, start_date: String(monday), end_date: String(sunday) }
  }

  getDays(date: any) {
    var yyyy = date.getFullYear(),
      mm = date.getMonth()
    return new Date(yyyy, mm + 1, 0).getDate();
  }

  getPayBillReportData(payload: any) {
    return this.http.post<PayBillReport>(`${environment.baseUrl}` + apiConstant.PAY_BILL_REPORT_DATA, payload);

  }

  getPayBillReportDataExport(payload: any) {
    return this.http.post<PayBillReportExport>(`${environment.baseUrl}` + apiConstant.PAY_BILL_REPORT_DATA, payload);
  }
}

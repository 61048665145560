import { Component, OnInit, ViewChild, Input} from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrls: ['./notification-panel.component.css']
})
export class NotificationPanelComponent implements OnInit {
  @Input() isSideBarOpen: boolean | undefined;
  @Input() notifications: any;
  @Input() notificationCurrentPage: any;
  @Input() notificationLastPage: any;
  
  @ViewChild(MatMenuTrigger) trigger!: MatMenuTrigger;
  tab: string = 'all'
  //notifications: any = []
  notificationLoading: boolean = false;
	
  constructor(
	public notificationService: NotificationService,
  ) {
  }
  
  ngOnInit(): void {	
  }
  
  loadNotifications(){
	this.notificationLoading = true;
	var nextPage = this.notificationCurrentPage+1;
    this.notificationService.getNotifications(nextPage).subscribe((notificationData) => {
		
		for(let data of notificationData.data){
          if(data){
            this.notifications.push(data);   
          }
        }
		
		if(notificationData.meta){
		  this.notificationCurrentPage = notificationData.meta.current_page; 
		  this.notificationLastPage = notificationData.meta.last_page; 
		}
		this.notificationLoading = false;
		
    });
  }

}
<header>
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-sm col-12 d-flex">
                <h4 class="modal-title fs-5">{{preview ? 'Preview' : formData? 'Edit' :'Create'}} Timesheet Form</h4>

                <button type="button" class="btn btn-sm mx-2 btn-success" (click)="changeForm(fromEdit)"
                    *ngIf="preview">{{preview
                    ?'Edit Form':'Preview Form'}}</button>
            </div>
            <div class="col-sm-auto col-12 mt-4 mt-sm-0">
                <button type="button" class="btn-close btn-neutral btn-square" aria-label="Close"
                    (click)="close()"></button>
            </div>
        </div>
    </div>
</header>

<main class="py-6 bg-surface-secondary" *ngIf="fromEdit">
    <div class="container-fluid max-w-screen-md vstack gap-6">
        <div class="vstack gap-5">
            <div class="" [ngClass]="preview ? 'previewFormEnabled':''">
                <div cdkDropList (cdkDropListDropped)="drop($event)" class="example-list">
                    <div class="card example-box p-5">
                        <div class="">
                            <input class="form-control" placeholder="Form Name" type="text" [(ngModel)]="formName">
                        </div>
                    </div>

                    <div cdkDrag class="card" *ngFor="let field of fields; let i = index"
                        [style.display]="preview ? 'none':''">
                        <div class="card-body">
                            <div class="row g-3">
                                <div class="col-md-7">
                                    <div class="d-flex">
                                        <div class="d-flex justify-content-between align-items-center me-2"
                                            cdkDragHandle>
                                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                                            <div class="dragIcon">
                                                <mat-icon>drag_indicator</mat-icon>
                                            </div>
                                        </div>
                                        <div class="w-100">
                                            <div class="mb-2">
                                                <input type="text" class="form-control" placeholder="Question"
                                                    [(ngModel)]="field.label"
                                                    [disabled]="field.field_type === 'static' 
                                                    || field.type === 'time_range' 
                                                    || (field.label === 'Days' && field.has_hours === 1)
                                                    || (field.label === 'Hours' && field.has_hours === 1)">
                                            </div>
                                            <!-- <div class="mb-2">
                                                <div class="nameAndType" *ngIf="field.type !== 'dropdown'"
                                                    [ngSwitch]="field.type">
                                                    <input class="form-control" disabled *ngSwitchCase="'number'"
                                                        placeholder="Number Answer Field">
                                                    <input class="form-control" disabled *ngSwitchCase="'time_range'"
                                                        placeholder="Time Range Field">
                                                    <input class="form-control" disabled *ngSwitchCase="'readonly'"
                                                        placeholder="Readonly Field">
                                                    <input class="form-control" disabled *ngSwitchDefault
                                                        placeholder="Text Answer Field">
                                                </div>
                                            </div> -->
                                            <div class="mb-2 d-flex">
                                                <div class="dropdown-values w-100" *ngIf="field.type === 'dropdown'">
                                                    <div class="d-flex mb-2"
                                                        *ngFor="let option of field?.options; let optionIdx = index">
                                                        <!-- <span class="d-flex align-items-center">{{optionIdx+1}}.</span> -->
                                                        <input type="text" class="form-control" placeholder="Add option"
                                                        [disabled]="field.label === 'Days'"
                                                            [(ngModel)]="option.name" />

                                                        <i class="bi bi-x ms-2"
                                                            *ngIf="optionIdx !== 0 || fields[i].options.length !== 1"
                                                            (click)="removeOption(i, optionIdx)"></i>

                                                        <!-- <button type="button" class=""
                                                            (click)="removeOption(i, optionIdx)"
                                                            *ngIf="optionIdx !== 0 || fields[i].options.length !== 1">
                                                            <i class="bi bi-x"></i>
                                                        </button> -->

                                                        <div class="transparent"
                                                            *ngIf="optionIdx === 0 && fields[i].options.length === 1">
                                                        </div>
                                                    </div>
                                                    <div class="d-flex w-100" (click)="addOption(i)">
                                                        <!-- <span class="d-flex align-items-center">{{fields[i]?.options?.length+1}}.</span> -->
                                                        <input type="text" class="form-control"
                                                            placeholder="Add option" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <div class="mb-2 d-flex">
                                        <select class="form-select" [(ngModel)]="field.type"
                                            (change)="onTypeChange($event, i)"
                                            [disabled]="field.field_type === 'static' 
                                            || field.label === 'Hours' 
                                            || field.label === 'Days' 
                                            || field.type === 'time_range'">
                                            <option value="text">Text</option>
                                            <option value="textarea">Textarea</option>
                                            <option value="number">Number</option>
                                            <option value="time_range" *ngIf="field.type === 'time_range'">Time Range
                                            </option>
                                            <option value="dropdown">Dropdown</option>
                                            <option value="readonly" disabled>Readonly</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-section-footer">
                                        <div class="requiredFlag showFlag" *ngIf="field.field_type === 'static'">
                                            <span>Show</span>
                                            <mat-slide-toggle [(ngModel)]="field.values"
                                                [ngModelOptions]="{standalone: true}">
                                            </mat-slide-toggle>
                                        </div>
                                        <i class="bi bi-trash3" (click)="removeField(i)"
                                            *ngIf="(i !== 6 || fields.length !== 7) && field.field_type !== 'static' && (field.label !== 'Hours' && field.has_hours !== 1) && field.type !== 'time_range'"></i>
                                        <div class="requiredFlag">
                                            <span>Show to client</span>
                                            <input type="checkbox" [(ngModel)]="field.show_to_client"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                        <div class="requiredFlag">
                                            <span>Hours</span>
                                            <input type="checkbox" [id]="'hours_' + i" (click)="enableHours($event,i)"
                                                [(ngModel)]="field.has_hours" [ngModelOptions]="{standalone: true}"
                                                [disabled]="field.type === 'time_range' || field.label === 'Hours'">
                                        </div>
                                        <div class="requiredFlag">
                                            <span>Required</span>
                                            <input type="checkbox" [(ngModel)]="field.is_required"
                                                [ngModelOptions]="{standalone: true}">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="error">
                        <span *ngIf="error">{{error}}</span>
                    </div>
                    <div class="loader" *ngIf="loading">
                        <mat-spinner diameter="30"></mat-spinner>
                    </div>
                    <div class="submitForm mt-10">
                        <button type="button" class="btn btn-sm mx-2 btn-success"
                            (click)="changeForm(fromEdit)">{{preview
                            ?'Back':'Preview Form'}}</button>
                        <button type="button" class="btn btn-sm mx-2 btn-primary" (click)="submitForm()"
                            [disabled]="loading">Submit</button>
                        <button type="button" class="btn btn-sm mx-2 btn-secondary" (click)="addField()">Add New
                            Section</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<main class="py-6 bg-surface-secondary" *ngIf="preview">
    <div class="container-fluid vstack gap-6">
        <div class="vstack gap-4">
            <!-- form preview html starts here -->
            <div class="table-responsive">
                <table matSort class="table table-hover table-nowrap">
                    <thead class="table-light">
                        <tr>
                            <ng-container *ngFor="let field of fields">
                                <th mat-sort-header="job" scope="col"
                                    *ngIf="field.field_type !== 'static' || field.values">
                                    {{field.label}}</th>
                            </ng-container>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let timesheet of timesheetDetails" class="dataColumn">
                            <ng-container *ngFor="let field of fields">
                                <td *ngIf="field.field_type !== 'static' || field.values" [ngSwitch]="field.label">
                                    <div *ngSwitchCase="'Client'" class="textWithIcon text-heading font-bold">
                                        <i class="bi bi-people"></i>
                                        <span>{{timesheet?.client_name}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Job'" class="textWithIcon text-heading font-bold">
                                        <i class="bi bi-briefcase"></i>
                                        <span>{{timesheet?.job_occupation}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Contractor'" class="textWithIcon text-heading font-bold">
                                        <div class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                                            <span class="text-primary fs-10x">JN</span>
                                        </div>
                                        <span>{{timesheet?.contractor_detail}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Date'" class="textWithIcon text-heading font-bold">
                                        <i class="bi bi-briefcase"></i>
                                        <span>{{timesheet?.date}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Start Date'" class="textWithIcon text-heading font-bold">
                                        <i class="bi bi-briefcase"></i>
                                        <span>{{timesheet?.start_date}}</span>
                                    </div>

                                    <div *ngSwitchCase="'End Date'" class="textWithIcon text-heading font-bold">
                                        <i class="bi bi-briefcase"></i>
                                        <span>{{timesheet?.end_date}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Hours'" class="textWithIcon text-heading font-bold">
                                        <i class="bi bi-briefcase"></i>
                                        <span>{{timesheet?.hours}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Status'" class="textWithIcon text-heading font-bold">
                                        <span>{{timesheet?.status}}</span>
                                    </div>

                                    <div *ngSwitchCase="'Days'" class="textWithIcon text-heading font-bold">
                                        <span>{{timesheet?.days}}</span>
                                    </div>

                                    <span *ngSwitchDefault class="textWithIcon text-heading font-bold">Default value
                                        text</span>
                                </td>
                            </ng-container>
                        </tr>
                </table>
            </div>
        </div>
    </div>
</main>
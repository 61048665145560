<div class="modal-header align-items-start p-0 border-bottom-0 d-flex mb-8 justify-content-between flex-sm-row-reverse">
    <div>
      <button type="button" class="btn-close btn-neutral btn-square"
        aria-label="Close" (click)="close()"></button>
    </div>
    <div class="text-dark align-items-center d-flex flex-column">
      <label class="pt-2 title">Awaiting Approvals</label>
    </div>
    <div class="transparent"></div>
  </div>
 
  <div class="modal-body p-0">
    <table matSort class=" table table-borderless datatable">
        <thead class="table-light">
            <tr>
                <th scope="col">Contractor</th>
                <th scope="col">Job</th>
                <th scope="col">Client</th>
                <th scope="col">Start Date</th>
                <th scope="col">End Date</th>
                <th scope="col">Hours</th>
                <th scope="col">Actions</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let awaitingApproval of allAwaitingApprovals; let i = index" class="dataColumn">
                <td>
                    <div class="textWithIcon">
                        <div class="avatar avatar-sm bg-warning rounded-circle text-white h-7 w-7 me-2"
                            *ngIf="awaitingApproval.profile_pic">
                            <img alt="..." src="{{dataSharedService.getImage(awaitingApproval.profile_pic)}}">
                        </div>
                        <div *ngIf="!awaitingApproval.profile_pic" class="cotractorInitialIcon bg-primary bg-opacity-20 h-7 w-7">
                            <span class="text-primary fs-10x">{{authService.getInitials(awaitingApproval.candidate)}}</span>
                        </div>
                        <span>{{awaitingApproval.candidate}}</span>
                    </div>
                </td>
                <td>{{awaitingApproval.job}}</td>
                <td>{{awaitingApproval.client}}</td>
                <td>{{dataSharedService.getFormatedDateUTC(awaitingApproval.start_date)}}</td>
                <td>{{dataSharedService.getFormatedDateUTC(awaitingApproval.end_date)}}</td>
                <td>{{awaitingApproval.total_hours}}</td>
                <td class="text-center">
                    <button type="button" (click)="openSendReminderPopUp(awaitingApproval.client_uuid, awaitingApproval.candidate, awaitingApproval.job)" class="btn btn-sm btn-square btn-neutral ms-1 px-3 py-1 fs-7"  matTooltip="Send Reminder">
                        <i class="bi bi-envelope"></i>
                    </button>
                    <button type="button"  (click)="openTimesheetDetails(awaitingApproval, awaitingApproval.status, true)" class="btn btn-sm btn-square btn-neutral ms-1 px-3 py-1 fs-7" matTooltip="View Timesheet">
                        <i class="bi bi-eye"></i>
                    </button>
                </td>
            </tr>
        </tbody>    
    </table>
  </div>
 
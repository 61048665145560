<header>
  <div class="container-fluid">
    <div class="pt-1">
      <div class="row align-items-center">
        <div class="col-sm col-12">
          <h1 class="h2 ls-tight">👋 Hi, {{user.first_name ?? user.name}}!</h1>
        </div>
      </div>
    </div>
  </div>
</header>

<main class="py-6 bg-surface-secondary">
  <div class="container-fluid vstack gap-6">
    <div class="row g-6">
      <app-board  *ngIf="user.role_uuid === ROLES.AGENCY"></app-board>
    </div>
    <div class="row g-6 mb-6">
      <app-candidate-assignments *ngIf="user.role_uuid === ROLES.CANDIDATE"></app-candidate-assignments>
      <app-timesheets [needMargins]="true"></app-timesheets>
    </div>
  </div>
</main>
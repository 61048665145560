<!-- <div class="modal-header p-0 border-bottom-0" *ngIf="isModalCloseIcon">
  <button type="button" class="btn-close btn-neutral btn-square" aria-label="Close" (click)="close()"></button>
</div> -->

<div class="modal-header p-0 border-bottom-0" *ngIf="isModalCloseIcon">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <mat-icon class="close clickable" (click)="close()">close</mat-icon>
</div>

<div class="audit-trail py-3">
    <div class="audit-details bg-white pb-2">
        <div *ngIf="from !== 'timeSheetDetail'" class="detail d-flex py-3">
            <div class="left d-flex justify-content-center align-items-center" style="width: 20%;">
                <div class="icon icon-shape bg-white border-2 border-primary text-primary text-lg rounded-circle">
                    <i [class]="header?.icon"></i>
                </div>
            </div>
            <div class="right flex-grow-1 text-dark d-flex flex-column">
                <label class="fw-bold ">{{header?.title}}</label>
                <label>{{header?.subTitle}}</label>
            </div>
        </div>
        <hr *ngIf="from !== 'timeSheetDetail'" class="divider my-3 opacity-70"
            style="width:96% ; background: lavender;">
        <div class="loader" *ngIf="loading">
            <mat-spinner diameter="40"></mat-spinner>
        </div>
        <div class="activities" *ngFor="let activity of auditTrailActivities; let idx = index">
            <div class="steps d-flex">
                <div class="auditTrailCircleLineSection">
                    <div [ngSwitch]="activity?.new_values?.status">
                        <div style="width: 15px; height: 15px; border-radius: 50%;" class="circle"
                            *ngSwitchCase="'Pending'" class="bg-warning"></div>
                        <div style="width: 15px; height: 15px; border-radius: 50%;" class="circle"
                            *ngSwitchCase="'Approved'" class="bg-success"></div>
                        <div style="width: 15px; height: 15px; border-radius: 50%;" class="circle"
                            *ngSwitchCase="'Rejected'" class="bg-tertiary"></div>
                        <div style="width: 15px; height: 15px; border-radius: 50%;" class="circle"
                            *ngSwitchCase="'Submitted'" class="bg-primary"></div>
                        <div style="width: 15px; height: 15px; border-radius: 50%;" class="circle" *ngSwitchDefault
                            class="bg-primary"></div>
                    </div>
                    <div class="line bg-secondary"
                        [style.background-color]="idx === auditTrailActivities.length - 1 ? 'transparent !important' : ''"
                        style="width: 2px; height: 38px;"></div>
                </div>
                <div class="text auditTrailText">
                    <label>{{activity.day}} {{dataSharedService.getFormatedDate(activity.event_date)}},
                        {{activity.time}} - {{activity.user}}
                        {{activity?.text}}.</label>
                </div>
            </div>
        </div>
        <div class="emptyAuditTrail" *ngIf="!loading && !auditTrailActivities.length">
            <span>No audit trail found</span>
        </div>
    </div>
</div>
<div class="vstack gap-5">
    <mat-select class="me-2 form-control" type="text" [(ngModel)]="dateFormat.new" placeholder="YYYY-MM-DD">
        <mat-option *ngFor="let format of dataSharedService.dateFormatList" [value]="format">
            {{format}} ({{currDate | date:format}})</mat-option>
    </mat-select>
</div>
<div class="hstack gap-2 justify-content-end mt-5">
    <!-- <button class="btn btn-sm btn-primary" (click)="saveDateFormat()"
        [disabled]="dateFormat.new === dateFormat.old">Save</button> -->
    <button class="btn btn-sm btn-primary" (click)="saveDateFormat()">Save</button>
</div>
import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';

import { DateFilter } from '../app.model';
import { apiConstant } from 'src/app/app.constant';
import { ReportService } from '../services/report.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pay-bill-report',
  templateUrl: './pay-bill-report.component.html',
  styleUrls: ['./pay-bill-report.component.css']
})
export class PayBillReportComponent implements OnInit {

  payBillReportData: any = [];
  payBillReportDates: any = [];
  trackHours: any = 0;
  payBillSearchForm: FormGroup | any;
  loading: boolean = false;
  error!: string;
  reportType: string = 'general_report';

  public start_date: any
  public end_date: any
  public custom_datepicker_text: string = "Custome Date picker";
  public filterOptions: any = this.reportService.filterDateRangePickerOptions;
  public selectedFilterOption: any = this.filterOptions[3].label;

  public API_CONSTANT = apiConstant;
  public currencyFormat: any;

  constructor(private reportService: ReportService, private datePipe: DatePipe,) {
    this.setStartEndDate()
  }

  ngOnInit(): void {
    this.currencyFormat = localStorage.getItem('currencyFormat') ? localStorage.getItem('currencyFormat') : '';
    this.FormGroupUpdate();
    this.onSubmit();
  }

  FormGroupUpdate() {
    this.payBillSearchForm = new FormGroup({
      candidate_uuid: new FormControl(''),
      start_date: new FormControl(new Date(this.start_date), Validators.required),
      end_date: new FormControl(new Date(this.end_date), Validators.required),
      export_type: new FormControl(''),
      report_type: new FormControl('general_report'),
    });
  }

  public DatepcikerEndDateChanged(event: MatDatepickerInputEvent<Date>): void {
    let start_date = this.datePipe.transform(this.payBillSearchForm.value.start_date, 'yyyy-MM-dd');
    let end_date = this.datePipe.transform(this.payBillSearchForm.value.end_date, 'yyyy-MM-dd');

    if (start_date && end_date) {
      this.custom_datepicker_text = `${start_date} - ${end_date}`;
      this.selectedFilterOption = `${start_date} - ${end_date}`;
    }
  }

  onFilterChange(picker: any) {
    if (this.selectedFilterOption === 'datePicker') {
      picker.open();
    } else {
      this.setStartEndDate()
      this.FormGroupUpdate()
    }
  }

  setStartEndDate() {
    const selectedFilter: DateFilter = this.filterOptions.find((x: DateFilter) => x.label === this.selectedFilterOption);
    if (selectedFilter.start_date != '' && selectedFilter.end_date != '') {
      this.start_date = selectedFilter.start_date;
      this.end_date = selectedFilter.end_date;
    }
  }

  async onSubmit() {
    this.error = '';
    this.loading = true;

    const payload = {
      ...this.payBillSearchForm.value,
      start_date: this.datePipe.transform(this.payBillSearchForm.value.start_date, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.payBillSearchForm.value.end_date, 'yyyy-MM-dd'),
      export_type: '',
      type: 'bill'
    };

    this.reportService.getPayBillReportData(payload).subscribe((response) => {
      this.payBillReportData = response.report_data;
      this.payBillReportDates = response.report_days;
      this.trackHours = response.track_hours;
      this.loading = false;
      this.error = '';
    }, error => {
      this.loading = false;
      this.error = error;
    });
  }

  exportIn(exportType: string) {
    this.error = '';
    this.loading = true;
    const payload = {
      ...this.payBillSearchForm.value,
      start_date: this.datePipe.transform(this.payBillSearchForm.value.start_date, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.payBillSearchForm.value.end_date, 'yyyy-MM-dd'),
      export: true,
      export_type: exportType,
      type: 'bill'
    };
    this.reportService.getPayBillReportDataExport(payload).subscribe((response: any) => {
      window.open(environment.baseUrl.replace('api/', '') + response.report_path, '_blank');
      this.loading = false;
    }, error => {
      this.loading = false;
      this.error = error;
    });
  }

  onSearchChange(event: any) {
    this.payBillSearchForm.patchValue({
      [event.formControlName]: event.value.uuid
    })
    this.onSubmit();
  }

  selectedReportType(event: any) {
    this.reportType = event.value;
  }

  maxLimit() {
    return this.datePipe.transform(new Date(), 'yyyy-MM-dd');
  }
}
<div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h3 class="modal-title">Send Message</h3>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<div class="modal-body">
    <form [formGroup]="sendEmailForm" class="reminderForm">
        <div class="row g-3">
            <div class="col-md-12">
                <label for="form-label">Subject</label>
                <input type="text" class="form-control" formControlName="subject"
                    [id]="sendEmailForm.controls.subject.errors && sendEmailForm.controls.subject.touched?'invalidField':''">
            </div>
            <div class="col-md-12">
                <label for="form-label">Message</label>
                <textarea class="form-control" formControlName="message"
                    [id]="sendEmailForm.controls.message.errors && sendEmailForm.controls.message.touched?'invalidField':''"></textarea>
            </div>
        </div>
    </form>
</div>

<div class="modal-footer justify-content-center">
    <div class="text-center">
        <button type="submit" class="btn btn-sm btn-primary" (click)="onSend()" [disabled]="!sendEmailForm.valid">Send
            Message</button>
    </div>
</div>
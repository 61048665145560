import { Component, Inject, Input, OnInit } from '@angular/core';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SuccessModal } from 'src/app/app.model';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-timesheet-approval-link-modal',
  templateUrl: './timesheet-approval-link-modal.component.html',
  styleUrls: ['./timesheet-approval-link-modal.component.css']
})
export class TimesheetApprovalLinkModalComponent implements OnInit {
  @Input() timesheetUuid: any;
  timesheetApprovalLinks:any = [];
 
  constructor(public timesheetService: TimesheetService,
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TimesheetApprovalLinkModalComponent>,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,) { 
      this.timesheetUuid = data.timesheetUuid;
    }

  ngOnInit(): void {
    this.timesheetService.getTimesheetApprovalLinks(this.timesheetUuid).subscribe((response)=> {
      this.timesheetApprovalLinks = response.approval_links;
    });
  }

  close(){
    this.dialogRef.close();
  }

  copyLink(timesheetApprovalLink:any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = timesheetApprovalLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.successModal('Link copied');
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}

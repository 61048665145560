<div class="formContainer p-7">
    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="40"></mat-spinner>
    </div>
    <div class="d-flex justify-content-end">
        <mat-icon class="clickable" (click)="onClose()">close</mat-icon>
    </div>
    <div class="commentsContainer {{cssClass}}">
        <div class="commentsColumn">
            <div class="commentContainer" *ngFor="let comment of commentsList">
                <div class="userColumn">
                    <div class="cotractorInitialIcon bg-primary bg-opacity-20 h-10 w-10">
                        <span class="text-primary fs-16x">{{authService.getInitials(comment.comment_by)}}</span>
                    </div>
                    <div class="nameAndDate">
                        <h5>{{comment.comment_by}}</h5>
                        <span>{{dataSharedService.getFormatedDate(comment.created_at)}}, {{comment.created_at | date:
                            'hh:mm a' }} </span>
                    </div>
                </div>
                <span class="comment" [innerText]="comment.comment"></span>
            </div>
        </div>
        <div class="enterCommentColumn">
            <form [formGroup]="commentForm" (ngSubmit)="onComment()">
                <div class="commentInput">
                    <textarea class="form-control" formControlName="comment" placeholder="Type a comment.."></textarea>
                    <button type="submit" class="text-primary" [disabled]="!commentForm.valid || loading"><i
                            class="bi bi-send-fill"></i></button>
                </div>
            </form>
        </div>
    </div>
</div>
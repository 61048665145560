import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { apiConstant } from 'src/app/app.constant';
import { Tasks } from 'src/app/app.model';
import { TaskService } from 'src/app/services/task.service';
import { CommentsComponent } from '../comments/comments.component';

@Component({
  selector: 'app-tasks-list',
  templateUrl: './tasks-list.component.html',
  styleUrls: ['./tasks-list.component.css'],
})
export class TasksListComponent implements OnInit {
  public tab = 'all';
  public taskArr: any = []

  constructor(
    public taskService: TaskService,
    public router: Router,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.getListData();
  }
  getListData() {
    this.taskService.getTasks().subscribe((res: any) => {
      this.taskArr = res.data;
    })
  }

  onCheckedChange(task: Tasks) {
    task.status = task.status === 'completed' ? 'in progress' : 'completed';
    const payload: Tasks = {
      name: task.name,
      description: task.description,
      status: task.status,
      assigned_to_uuid: task.assigned_to_uuid,
      uuid: task.uuid,
    };
    this.taskService.createTasks(payload).subscribe((res: any) => {
      console.log(res);
    }, (error) => {
      console.log(error);
    });
  }

  editTasks(taskUuid: string) {
    this.router.navigate(['/tasks'], { queryParams: { taskUuid } })
  }

  getCount(status: string) {
    return this.taskArr.filter((x: any) => x.status === status).length;
  }

  openComments(task_uuid: string){
    const modalRef = this.modalService.open(CommentsComponent, {
      size: 'md',
      modalDialogClass: 'add-reminder-modal',
      backdrop: true,
    });
    modalRef.componentInstance.timesheet_uuid = task_uuid
    modalRef.componentInstance.endPoint = apiConstant.TASK_COMMENTS;
    modalRef.componentInstance.parentKey = 'task_uuid';
    modalRef.componentInstance.cssClass = 'taskCommentContainer'
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
      }
    });
  }
}

import { Component, Inject, Input, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { roleConstant, statusConstant } from '../app.constant';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-submit-timesheet',
  templateUrl: './submit-timesheet.component.html',
  styleUrls: ['./submit-timesheet.component.css']
})
export class SubmitTimesheetComponent implements OnInit {
  @Input() timesheetId: any;
  @Input() readonly: any;
  @Input() status: any;
  timesheetDetails: any;
  timesheetForm: any;
  fields: any = [];
  arFlag: boolean = false;
  ROLES = roleConstant;
  STATUS = statusConstant;
  loading: boolean = false;


  constructor(
    public activeModal: NgbActiveModal,
    private timesheetService: TimesheetService,
    private dataSharedService: DataSharedService,
    private authService: AuthenticationService,
    private dialogRef: MatDialogRef<SubmitTimesheetComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private ngZone: NgZone
  ) {
    this.timesheetId = data.timesheetId
    this.readonly = data.readonly
    this.status = data.status
  }

  async ngOnInit(): Promise<void> {
    const user: any = this.authService.userValue;
    this.loading = true;
    this.fields = await new Promise((resolve) => this.timesheetService.getTimesheetForm().subscribe((res: any) => resolve(res.data[2])));
    console.log(this.fields);
    this.timesheetService.getTimesheetDetails(this.timesheetId).subscribe(timesheetDetails => {
      this.loading = false;
      if (user.role_uuid === this.ROLES.AGENCY || user.role_uuid === this.ROLES.CLIENT) {
        this.readonly = true;
        this.arFlag = this.status === this.STATUS.SUBMITTED ? true : false;
      } else if (user.role_uuid === this.ROLES.CANDIDATE) {
        this.arFlag = false;
      }
      this.timesheetDetails = timesheetDetails.data;

      // this.fields = this.timesheetDetails.timesheet_form_details.pop();
      const form: any = {};
      this.timesheetForm = new FormGroup(form);
      this.timesheetDetails.timesheet_details.forEach((formData: any, i: number) => {
        this.fields.fields.forEach((element: any) => {
          form[element.label + '_' + i] = new FormControl();
          const field = formData?.field_values.find((x: any) => x.uuid === element.uuid);
          this.timesheetForm.patchValue({
            [element.label + '_' + i]: field?.values,
          })
        });
      })
      console.log(this.timesheetForm);
    });
  }

  onBlurField(fieldName: string) {
    setTimeout(() => {
      this.timesheetForm.controls[fieldName].focused = false
    }, 100);
  }

  copyValues(fieldLabel: string, fieldName: string) {
    const fieldValue = this.timesheetForm.controls[fieldName].value;
    this.timesheetDetails.timesheet_details.forEach((element: any, i: number) => {
      this.timesheetForm.patchValue({
        [fieldLabel + '_' + i]: fieldValue
      })
    });
  }

  onReject() {
    this.timesheetService.rejectTimeSheet(this.timesheetId).subscribe(res => {
      console.log(res);
      this.dialogRef.close({ refresh: true });
    }, error => {
      console.error(error);
      this.dialogRef.close({ refresh: true });
    });
  }

  onApprove() {
    this.timesheetService.approveTimeSheet(this.timesheetId).subscribe(res => {
      console.log(res);
      this.dialogRef.close({ refresh: true });
    }, error => {
      console.error(error);
      this.dialogRef.close({ refresh: true });
    });
  }

  submitTimesheet() {
    const fullForm: any = {}
    fullForm['form_uuid'] = this.fields.uuid
    fullForm['timesheet_details'] = []
    this.timesheetDetails.timesheet_details.forEach((formData: any, i: number) => {
      const singleForm: any = {};
      singleForm['uuid'] = formData.uuid;
      singleForm['date'] = formData.date;
      singleForm['fields'] = [];
      this.fields.fields.forEach((field: any) => {
        singleForm['fields'].push({
          uuid: field.uuid,
          values: this.timesheetForm.controls[field.label + '_' + i].value
        })
      });
      fullForm['timesheet_details'].push(singleForm);
    });
    console.log(fullForm);
    this.timesheetService.submitTimeSheet(this.timesheetId, fullForm).subscribe(res => {
      console.log(res);
      this.dialogRef.close({ refresh: true });
    }, error => {
      console.error(error);
      this.dialogRef.close({ refresh: true });
    });
  }
  close() {
    this.ngZone.run(() => {
      this.dialogRef.close();
    });
  }
}

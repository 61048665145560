<div class="loader" *ngIf="loading">
    <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header">
    <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
    <h3 class="modal-title">Add New Client</h3>
    <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>
<div class="modal-body">
    <form [formGroup]="clientForm" class="row g-3">
        <div class="vstack gap-5">
            <div class="row">
                <div class="col-md-12">
                    <label class="form-label">Client Name</label>
                    <input type="text" class="form-control" formControlName="name" id="clientName"
                        (blur)="clientValidation($event)" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label class="form-label">Identifier</label>
                    <input type="text" class="form-control" formControlName="identifier" id="clientIdentifier"
                        (blur)="clientValidation($event)" />
                </div>
            </div>
            <div class="row">
                <div class="col-md-6">
                    <label class="form-label">Address 1</label>
                    <input type="text" class="form-control" formControlName="address_1" id="address_1"
                        (blur)="clientValidation($event)" />
                </div>
                <div class="col-md-6">
                    <label class="form-label">Address 2</label>
                    <input type="text" class="form-control" formControlName="address_2" id="address_2"
                        (blur)="clientValidation($event)" />
                </div>
                <div class="col-md-6">
                    <label class="form-label">Zip Code</label>
                    <input type="text" class="form-control" formControlName="post_zip_code" id="post_zip_code"
                        (blur)="clientValidation($event)" />
                </div>
                <div class="col-md-6">
                    <label class="form-label">Country</label>
                    <input type="text" class="form-control" formControlName="country" id="country"
                        (blur)="clientValidation($event)" />
                </div>
            </div>
        </div>

        <div class="vstack gap-3 mt-10" *ngIf="client_contact">
            <div class="d-flex align-items-between">
                <h4 class="me-auto">Client Contacts</h4>
                <button type="button" class="btn btn-sm btn-neutral mr-auto" (click)="ResetClientContact()">
                    Reset
                </button>
            </div>
            <div class="contact-section border-2 shadow-2-hover rounded p-4">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label">First Name</label>
                        <input type="text" id="first_name" class="form-control" id="first_name" name="first_name"
                            [(ngModel)]="client_contact.first_name" [ngModelOptions]="{standalone: true}"
                            (change)="ClientContactValidation($event)" />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">Last Name</label>
                        <input type="text" id="last_name" class="form-control" name="last_name"
                            [(ngModel)]="client_contact.last_name" [ngModelOptions]="{standalone: true}"
                            (change)="ClientContactValidation($event)" />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">Email</label>
                        <input type="email" id="email" class="form-control" name="email"
                            [(ngModel)]="client_contact.email" [ngModelOptions]="{standalone: true}"
                            (change)="ClientContactValidation($event)" />
                    </div>
                    <div class="col-md-6">
                        <label class="form-label">
                            Type of Email
                            <span class="bi bi-info-circle-fill tooltip-custom">
                                <span class="tooltiptext">
                                    Individual Email Approval: Sends timesheet details of single candidate for
                                    Approval.<br />
                                    Bulk Email Approval: Sends timesheet details of multiple candidates for Approval.
                                </span>
                            </span>
                        </label>
                        <mat-select class="me-2 input-form" type="text" name="email_type"
                            [(ngModel)]="client_contact.timesheet_notification_settings.email_type"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option [value]="0">Individual</mat-option>
                            <mat-option [value]="1">Bulk</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-md-6" *ngIf="client_contact.timesheet_notification_settings.email_type == 1">
                        <label class="form-label">Frequency</label>
                        <mat-select class="me-2 input-form" name="frequency"
                            [(ngModel)]="client_contact.timesheet_notification_settings.frequency"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option [value]="1">Weekly</mat-option>
                            <mat-option [value]="2">Monthly</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-md-6" *ngIf="client_contact.timesheet_notification_settings.email_type == 1">
                        <label class="form-label">Day</label>
                        <mat-select class="me-2 input-form" name="day"
                            [(ngModel)]="client_contact.timesheet_notification_settings.day"
                            [ngModelOptions]="{standalone: true}">
                            <mat-option [value]="0">Sunday</mat-option>
                            <mat-option [value]="1">Monday</mat-option>
                            <mat-option [value]="2">Tuesday</mat-option>
                            <mat-option [value]="3">Wednesday</mat-option>
                            <mat-option [value]="4">Thursday</mat-option>
                            <mat-option [value]="5">Friday</mat-option>
                            <mat-option [value]="6">Saturday</mat-option>
                        </mat-select>
                    </div>
                    <div class="col-md-6" *ngIf="client_contact.timesheet_notification_settings.email_type == 1">
                        <label class="form-label">Time</label>
                        <input type="time" class="form-control" min="06:00:00" max="20:00:00" name="time"
                            [(ngModel)]="client_contact.timesheet_notification_settings.time"
                            [ngModelOptions]="{standalone: true}" id="time"
                            (change)="ClientContactValidation($event)" />
                    </div>
                    <div class="col-md-6" *ngIf="client_contact.timesheet_notification_settings.email_type == 1"></div>
                    <div class="col-md-6">
                        <mat-checkbox [(ngModel)]="client_contact.send_invite" [ngModelOptions]="{standalone: true}">
                            Send invite to the contact
                        </mat-checkbox>
                    </div>
                </div>
                <div class="d-flex justify-content-end text-center mt-3">
                    <button type="button" class="btn btn-sm btn-primary" [disabled]="loading"
                        (click)="AddClientContact($event)">
                        Save Contact
                    </button>
                </div>
            </div>
        </div>

        <div class="vstack gap-5">
            <div class="row g-6 mt-2" *ngIf="client_contacts.length > 0">
                <ng-container *ngFor="let contact of client_contacts; index as contact_index">
                    <div class="col-xxl-4 col-md-4 col-sm-6 mt-2">
                        <div class="card shadow-none border border-primary-hover mb-4">
                            <div class="card-body p-2">
                                <div class="position-relative">
                                    <div class="p-1 d-flex align-items-center cursor-pointer">
                                        <div class="me-0" (click)="EditClientContact(contact, contact_index)">
                                            <div
                                                class="avatar avatar-sm rounded-circle me-2 cotractorInitialIcon bg-primary bg-opacity-20 h-10 w-10">
                                                <span
                                                    class="text-primary fs-10x">{{authService.getInitials(contact.name)}}</span>
                                            </div>
                                        </div>
                                        <div class="flex-fill text-limit"
                                            (click)="EditClientContact(contact, contact_index)">
                                            <span class="d-block h6 font-semibold">{{contact.first_name}}
                                                {{contact.last_name}}</span>
                                            <span class="d-block text-xs text-muted">{{contact.email}}</span>
                                        </div>
                                        <div class="text-end" (click)="DeleteClientContact(contact_index)">
                                            <div href="javascript:void(0);" class="remove-agency-user-section">
                                                <i class="bi bi-x"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </form>
    <div class="error" *ngIf="error">
        <span>{{error}}</span>
    </div>
</div>
<div class="modal-footer justify-content-center">
    <div class="text-center">
        <button type="button" class="btn btn-sm btn-neutral me-5" (click)="onClose()">
            Cancel
        </button>
        <button type="submit" class="btn btn-sm btn-primary" [disabled]="loading || !formValid"
            (click)="onSubmit()">Save</button>
    </div>
</div>
<div class="mainContainer card overflow-auto margin mt-4">
    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="reminder mb-0 d-flex bg-light justify-content-between flex-column flex-sm-row">
        <h5 class="card-title ps-4 mb-0 tableTitle"> {{totalCount}} Job(s)</h5>
        <div class="form-inline ms-auto me-4 d-none d-md-flex hstack card-title ps-4 mb-0">
            <div class="input-group input-group-sm input-group-inline"><span class="input-group-text pe-2"><i
                class="bi bi-search"></i> </span><input type="email" class="form-control" placeholder="Search Jobs"
              aria-label="Search" [(ngModel)]="jobSearch" (keyup)="searchJob()">
          </div>
        </div>
    </div>
    <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap">
        <thead class="table-light">
          <tr>
            <th mat-sort-header="title" scope="col" class="ps-5">Name</th>
            <th mat-sort-header="title" scope="col" class="ps-5">Client</th>
            <th mat-sort-header="title" scope="col" class="ps-5">Contractors</th>
            <th mat-sort-header="pay_rate" scope="col">Pay Rate</th>
            <th mat-sort-header="positions" scope="col">Positions</th>
            <th mat-sort-header="start_date" scope="col">Dates</th>
            <th mat-sort-header="end_date" scope="col">End Date</th>
            <th mat-sort-header="created_at" scope="col">Created At</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
            <tr *ngFor="let job of allJobs"  style="cursor: pointer;" class="dataColumn">
                <td (click)="openAuditTrail(job)">
                  <div class="textWithIcon" matTooltip="{{job.description ?? '--'}}" [matTooltipPosition]="'below'">
                    <i class="bi bi-briefcase"></i>
                    <span class="text-heading font-semibold">{{job.title}}</span>
                  </div>
                </td>
                <td matTooltip="{{job.location ?? '--'}}" [matTooltipPosition]="'below'">{{job.client ?? '--'}}</td>
                <td>{{job.contractors.join(', ') ?? '--'}}</td>
                <td>{{job.pay_rate ?? '--'}}</td>
                <td>{{job.positions ?? '--'}}</td>
                <td>{{dataSharedService.getFormatedDateUTC(job.start_date)}}</td>
                <td>{{dataSharedService.getFormatedDateUTC(job.end_date)}}</td>
                <td>{{dataSharedService.getFormatedDateUTC(job.created_at)}}</td>
                <td>
                  <div *ngIf="user?.role_uuid === ROLES?.AGENCY || user?.role_uuid === ROLES?.CLIENT" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" (click)="activateJob(job.uuid)" matTooltip="Activate job" [matTooltipPosition]="'left'">
                    <i class="bi bi-lock"></i>
                  </div>
                </td>
              </tr>
        </tbody>
    </table>
    <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
      </app-api-paginator>
</div>
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfirmPasswordValidator } from '../common/confirm-password-validator';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loading = false;
  token!: string;
  setPasswordForm: FormGroup = new FormGroup({
    email: new FormControl(''),
    password: new FormControl(),
    password_confirmation: new FormControl()
  });
  submitted = false;
  err: string = '';
  successfullySet = false;
  showPassword = false;
  showConfirm = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private timesheetService: TimesheetService,
  ) { }

  ngOnInit(): void {
    if (this.route.snapshot.queryParams['token']) {
      this.token = this.route.snapshot.queryParams['token'];
    }
    this.setPasswordForm = this.formBuilder.group(
      {
        email: ['',
          [Validators.required, Validators.email]
        ],
        password: ['',
          [Validators.required, Validators.minLength(8)]
        ],
        password_confirmation: ['',
          [Validators.required, Validators.minLength(8), ConfirmPasswordValidator.confirmPasswordValidator(this.setPasswordForm, 'password')]
        ],
      }
    );
  }



  get rForm(): { [key: string]: AbstractControl } {
    return this.setPasswordForm.controls;
  }

  validatePasswordConfirmation(){
    this.setPasswordForm.get('password_confirmation')?.setValidators([
      Validators.required, Validators.minLength(8), ConfirmPasswordValidator.confirmPasswordValidator(this.setPasswordForm, 'password')
    ]);
  }

  setPassword() {
    this.err = '';
    this.submitted = true;
    this.successfullySet = false;
    if (this.setPasswordForm.valid) {
      this.loading = true;
      this.timesheetService.setPassword({...this.setPasswordForm.value, token: this.token}).subscribe({
        next: (response) => {
          this.successfullySet = true;
          setTimeout(() => {
            this.router.navigate(['login']);
          }, 3000);
        },
        error: (err) => {
          this.loading = false;
          this.err = err
        }
      });
    }
  }

}

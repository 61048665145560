import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPasswordValidator } from '../common/confirm-password-validator';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  loading = false;
  changePasswordForm: FormGroup = new FormGroup({
    old_password: new FormControl(''),
    new_password: new FormControl(),
    confirm_password: new FormControl(),
  });
  submitted = false;
  err: string = '';
  successfullySet = false;
  showOld = false;
  showNew = false;
  showConfirm = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private timesheetService: TimesheetService,
    private modalService: NgbActiveModal,

  ) {}

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      old_password: ['', [Validators.required, Validators.minLength(8)]],
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          ConfirmPasswordValidator.confirmPasswordValidator(
            this.changePasswordForm,
            'new_password'
          ),
        ],
      ],
    });
  }

  get rForm(): { [key: string]: AbstractControl } {
    return this.changePasswordForm.controls;
  }

  validatePasswordConfirmation() {
    this.changePasswordForm
      .get('confirm_password')
      ?.setValidators([
        Validators.required,
        Validators.minLength(8),
        ConfirmPasswordValidator.confirmPasswordValidator(
          this.changePasswordForm,
          'new_password'
        ),
      ]);
  }

  changePassword() {
    this.err = '';
    this.submitted = true;
    this.successfullySet = false;
    if (this.changePasswordForm.valid) {
      this.loading = true;
      this.timesheetService
        .changePassword(this.changePasswordForm.value)
        .subscribe({
          next: (response) => {
            this.loading = false;
            this.successfullySet = true;
            setTimeout(() => {
              this.successfullySet = false;
            }, 2000);
          },
          error: (err) => {
            this.loading = false;
            this.err = err;
          },
        });
    }
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { permissionConstant, roleConstant } from '../app.constant';
import { User } from '../app.model';
import { CreateAgencyComponent } from '../components/create-agency/create-agency.component';
import { CreateClientComponent } from '../components/create-client/create-client.component';
import { CreateJobAssignmentComponent } from '../components/create-job-assignment/create-job-assignment.component';
import { CreateJobComponent } from '../components/create-job/create-job.component';
import { CreateTimesheetComponent } from '../components/create-timesheet/create-timesheet.component';
import { CreateUserComponent } from '../components/create-user/create-user.component';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { NotificationService } from '../services/notification.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() isSideBarOpen: boolean | undefined;
  user: User
  showDropDown: Boolean = false;
  ROLES = roleConstant;
  bellNotificationData: any = [];
  newNotificationStatus: any = false;
  notificationCurrentPage = 0;
  notificationLastPage = 0;
  subscription !: Subscription;
  shortcuts: any = [
    {
      modalClass: "add-client-modal",
      color: "#FA5799",
      bgColor: "#ffdfec",
      name: "Client",
      img: "assets/img/people.svg",
      navigateUrl: "/clients",
      icon: "bi bi-people",
      action: "Create Client",
      component: CreateClientComponent,
      class: "bg-tertiary text-tertiary",
      role: this.ROLES.AGENCY,
    },
    {
      modalClass: "add-client-modal",
      color: "#0566EA",
      bgColor: "#dfdffe",
      name: "Job",
      img: "assets/img/suitcase.svg",
      navigateUrl: "/jobs",
      icon: "bi bi-briefcase",
      action: "Create Job",
      component: CreateJobComponent,
      class: "bg-primary text-primary",
      role: this.ROLES.AGENCY,
    },
    {
      modalClass: "add-reminder-modal",
      color: "#FF8C00",
      bgColor: "#ffe8d5",
      name: "Assignment",
      img: "assets/img/assignment.svg",
      navigateUrl: "/assignments",
      icon: "bi bi-person-workspace",
      action: "Create Assignment",
      component: CreateJobAssignmentComponent,
      class: "bg-warning text-warning",
      role: this.ROLES.CLIENT,
    },
    {
      modalClass: "add-reminder-modal",
      color: "#FF8C00",
      bgColor: "#ffe8d5",
      name: "Assignment",
      img: "assets/img/assignment.svg",
      navigateUrl: "/assignments",
      icon: "bi bi-person-workspace",
      action: "Create Assignment",
      component: CreateJobAssignmentComponent,
      class: "bg-warning text-warning",
      role: this.ROLES.AGENCY,
    },
    {
      modalClass: "add-reminder-modal",
      color: "#FF8C00",
      bgColor: "#ffe8d5",
      name: "Timesheet",
      img: "assets/img/assignment.svg",
      navigateUrl: "/timesheets",
      icon: "bi bi-layout-text-window-reverse",
      action: "Create Timesheet",
      component: CreateTimesheetComponent,
      class: "bg-warning text-warning",
      role: this.ROLES.CANDIDATE,
    },
    {
      modalClass: "add-reminder-modal",
      color: "#00CC88",
      bgColor: "#d9f5e7",
      name: "Candidate",
      img: "assets/img/user.svg",
      navigateUrl: "/candidates",
      icon: "bi bi-person",
      action: "Create Candidate",
      component: CreateUserComponent,
      class: "bg-success text-success",
      role: this.ROLES.AGENCY,
    },
    {
      modalClass: "add-reminder-modal",
      color: "#0566EA",
      bgColor: "#dfdffe",
      name: "Agency",
      img: "assets/img/agency-icon.svg",
      navigateUrl: "/agencies",
      icon: "bi bi-building",
      action: "Create Agency",
      component: CreateAgencyComponent,
      class: "bg-primary text-primary",
      role: this.ROLES.SUPER_ADMIN,
    },
  ];

  constructor(
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    private modalService: NgbModal,
    public dialog: MatDialog,
    public router: Router,
    public notificationService: NotificationService
  ) {
    this.user = this.authService.userValue;
  }

  async ngOnInit(): Promise<void> {
    const d: any = await localStorage.getItem('permissions');
    this.dataSharedService.permissionData = JSON.parse(d);
    const isAgencyPermission = this.dataSharedService.permissionData?.find((x: any) => x.permission === permissionConstant.AGENCIES)
    if (isAgencyPermission) {
      this.user.role_uuid = this.ROLES.SUPER_ADMIN;
    }

    this.getNotifications();
    this.subscription = timer(0, 50000).pipe(
      switchMap(() => this.getUnReadNotificationCounts())
    ).subscribe(result => {
      //console.log(result)
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onAdd(shortcut: any) {
    let popupSize = 'md';
    if (shortcut.navigateUrl == '/agencies') {
      popupSize = 'lg';
    }
    const modalRef = this.modalService.open(shortcut.component, {
      size: popupSize,
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: shortcut.modalClass,
    });
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.router.navigate([shortcut.navigateUrl]);
      }
    });
  }
  async getNotifications() {
    this.notificationService.getNotifications(1).subscribe((notificationData) => {
      //this.bellNotificationData = notificationData;

      this.bellNotificationData = notificationData.data;
      if (notificationData.meta) {
        this.notificationCurrentPage = notificationData.meta.current_page;
        this.notificationLastPage = notificationData.meta.last_page;
      }

      this.getNotificationStatus();
    });
  }
  getNotificationStatus() {
    this.newNotificationStatus = false;
    if (this.bellNotificationData) {
      for (let not of this.bellNotificationData) {
        if (not.is_read == false) {
          this.newNotificationStatus = true;
          break;
        }
      }
    }
  }

  readAllNotifications() {
    this.getNotificationStatus();
    if (this.newNotificationStatus) {
      this.notificationService.setReadAllNotifications().subscribe(res => {
        //console.log(res);
        this.getNotifications();
      })
    }
  }
  async getUnReadNotificationCounts() {
    this.notificationService.getNotifications(1, 'unread_count').subscribe((res) => {
      if (res.unread_count > 0) {
        this.getNotifications();
      }
    });
  }
}
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from 'src/app/app.model';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.css'],
})
export class AlertModalComponent implements OnInit {
  @Input() data: AlertModal = {
    message: '',
    yesAction: '',
    noAction: '',
    yesActionColor: '',
    noActionColor: '',
    image: ''
  };

  constructor(private activeModalService: NgbActiveModal) {}

  ngOnInit(): void {}

  onClose(action: string) {
    this.activeModalService.close({ action });
  }
}

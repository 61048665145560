<div class="mainContainer card overflow-auto mx-5 margin">
  <div class="taskHeader">
    <div class="newTask d-flex">
      <i class="bi bi-chevron-left back fa-3x" style="cursor: pointer;" (click)="location.back()"></i>
      <label for="newTask">Create a new Task</label>
      <div class="loader" *ngIf="loading">
        <mat-spinner diameter="25"></mat-spinner>
      </div>
    </div>
    <div class="buttons">
      <button class="firstButton me-2">Save and create another</button>
      <button class="secondButton" (click)="onCreate()">Save</button>
    </div>
  </div>
  <form class="taskContainer" [formGroup]="taskForm">
    <div class="leftContainer">
      <div class="nameAndDescription">
        <input class="taskName" type="text" placeholder="Enter your task name..." formControlName="taskName"  [id]="taskForm.controls.taskName.errors && taskForm.controls.taskName.touched?'invalidField':''"/>
        <textarea class="descritpion" placeholder="Enter Description" formControlName="description" [id]="taskForm.controls.description.errors && taskForm.controls.description.touched?'invalidField':''"></textarea>
      </div>
      <div class="attachments">
        <label class="heading">Attachments</label>
        <div class="componentContainer">
          <button class="dragAndDrop" type="button" (click)="selectFile($event, inputFile)">
            <i class="bi bi-upload"></i>
            <div class="text d-flex flex-sm-column">
              <label class="upload">Upload a file or drag and drop</label>
              <input [accept]="acceptFiles.join(',')" #inputFile formControlName="file" id="filechose_button"
                type="file" (change)="onFileChange($event)">
              <label class="types">PDF, JPG, PNG upto 3MB</label>
            </div>
          </button>
          <div class="subBody d-flex justify-content-between align-items-center"
            *ngFor="let attachment of attachmentsArr">
            <div class="nameText d-flex flex-sm-column">
              <label class="name">{{ attachment.name }}</label>
              <label class="size">{{ formatBytes(attachment.size) }}</label>
            </div>
            <div class="subtext d-flex justify-content-end">
              <i class="bi bi-three-dots-vertical"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rightContainer">
      <div class="taskSettings">
        <label for="taskSettings">Task Settings</label>
      </div>
      <div class="dropdowns mt-8">
        <div class="assignee mt-5" *ngIf="user?.role_uuid === ROLES?.CLIENT">
          <label class="ddLabel">Assignee</label>
          <mat-select class="dropdown" formControlName="assigned_to_uuid" [id]="taskForm.controls.assigned_to_uuid.errors && taskForm.controls.assigned_to_uuid.touched?'invalidField':''">
            <mat-option *ngFor="let assignee of assignees" [value]="assignee.uuid">{{assignee.name}}</mat-option>
          </mat-select>
        </div>
        <div class="assignee mt-5">
          <label class="ddLabel">Status</label>
          <mat-select class="dropdown" formControlName="status" [id]="taskForm.controls.status.errors && taskForm.controls.status.touched?'invalidField':''">
            <mat-option [value]="option.key " *ngFor="let option of statusOptions">{{option.name}}</mat-option>
          </mat-select>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- <div class="modal-header">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <h4 class="modal-title">Alert</h4>
  <mat-icon class="close clickable" (click)="onClose('no')">close</mat-icon>
</div>
<div class="modal-body">
  <div class="d-flex justify-content-center py-2">
    <label class="alertMsg" for="">{{data.message}}</label>
  </div>
</div>
<div class="modal-footer justify-content-between">
  <button [style.background]="data.yesActionColor" class="btn btn-sm btn-primary"
    (click)="onClose('yes')">{{data.yesAction}}</button>
  <button [style.background]="data.noActionColor" class="btn btn-sm btn-secondary"
    (click)="onClose('no')">{{data.noAction}}</button>
</div> -->

<div class="modal-body p-4 px-5">
  <div class="main-content text-center mb-3">
    <mat-icon class="close-btn close clickable" (click)="onClose('no')">close</mat-icon>
    <div class="warp-icon">
      <i class="bi bi-exclamation-circle"></i>
    </div>
    <h3 class="mb-3">Confirm</h3>
    <p class="mb-3">{{data.message}}</p>
  </div>
  <div class="d-flex justify-content-center pt-3 pb-3">
    <button type="button" class="btn btn-sm btn-primary me-3" (click)="onClose('yes')">{{data.yesAction}}</button>
    <button type="button" class="btn btn-sm btn-secondary ms-2" (click)="onClose('no')">{{data.noAction}}</button>
  </div>
</div>
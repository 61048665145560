import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommentsService } from 'src/app/services/comments.service';
import { DataSharedService } from 'src/app/services/data-shared.service';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.css']
})
export class CommentsComponent implements OnInit {
  @Output() onCommentEnter: EventEmitter<any> = new EventEmitter();
  @Input() timesheet_uuid!: string;
  @Input() commentsList!: any;
  @Input() endPoint!: string;
  @Input() parentKey!: string;
  @Input() cssClass?: string;
  loading: boolean = false;
  commentForm = new FormGroup({
    comment: new FormControl('', Validators.required),
  })

  constructor(
    private commentService: CommentsService,
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    private activeModalService: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    if (!this.commentsList) {
      this.fetchComments()
    }
  }

  fetchComments() {
    this.loading = true;
    this.commentService.getComments(this.timesheet_uuid, this.endPoint, this.parentKey).subscribe(res => {
      this.commentsList = res.data;
      this.onCommentEnter.emit(this.commentsList);
      this.loading = false;
    })
  }

  onComment() {
    this.loading = true;
    this.commentService.postComment({[this.parentKey]: this.timesheet_uuid,comment: this.commentForm.value.comment}, this.endPoint).subscribe(res => {
      this.loading = false;
      this.fetchComments();
    })
    this.commentForm.patchValue({
      comment: ''
    })
  }

  onClose() {
    this.activeModalService.close();
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { TimesheetAttachments } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  constructor(
    private http: HttpClient
  ) { }

  uploadAttachment(payload: any) {
    return this.http.post<TimesheetAttachments>(`${environment.baseUrl}` + apiConstant.ATTACH_DOCUMENTS, payload);
  }

  getAttachments(attachment_uuid: string){
    return this.http.get<TimesheetAttachments>(`${environment.baseUrl + apiConstant.GET_DOCUMENTS}/${attachment_uuid}`);
  }

  deleteAttachment(attachment_uuid: string){
    return this.http.delete<TimesheetAttachments>(`${environment.baseUrl + apiConstant.DELETE_DOCUMENT}/${attachment_uuid}`);
  }

  base64IntoFile(base64: string, fileName: string, type: string) {
    const imageBlob = this.dataURItoBlob(base64, type);
    return new File([imageBlob], fileName, { type });
  }

  dataURItoBlob(dataURI: string, type: string) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type });    
    return blob;
 }
}

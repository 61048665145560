import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { ActivateDeactivateUser, ResendUserLogin } from '../app.model';
import { apiConstant } from '../app.constant';
import { AccountCreationLink } from '../app.model';

@Injectable({
  providedIn: 'root'
})

export class UserService {

  constructor(private http: HttpClient) { }

  resendUserLogin(payload: any) {
    return this.http.post<ResendUserLogin>(`${environment.baseUrl}` + apiConstant.RESEND_USER_LOGIN, payload);
  }

  getAccounCreationLink(userUuid: any) {
    return this.http.get<AccountCreationLink>(`${environment.baseUrl + userUuid}/` + apiConstant.GENERATE_ACCOUNT_CREATION_LINK, {});
  }

  activateAccount(userUuid: any) {
    return this.http.post<ActivateDeactivateUser>(`${environment.baseUrl + 'users/' + userUuid}/` + apiConstant.ACTIVATE, {});
  }

  activateUserAccount(userUuid: any) {
    return this.http.post<ActivateDeactivateUser>(`${environment.baseUrl + apiConstant.USERS}/${userUuid}/${apiConstant.ACTIVATE}`, {});
  }

  deactivateAccount(userUuid: any, payload: any) {
    return this.http.post<ActivateDeactivateUser>(`${environment.baseUrl + apiConstant.USERS}/${userUuid}/${apiConstant.DEACTIVATE}`, payload);
  }
  deactivateUserAccount(userUuid: any) {
    return this.http.post<ActivateDeactivateUser>(`${environment.baseUrl + apiConstant.USERS}/${userUuid}/${apiConstant.DEACTIVATE}`, {});
  }
}
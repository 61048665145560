import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateFormComponent } from '../components/create-form/create-form.component';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-timesheet-forms',
  templateUrl: './timesheet-forms.component.html',
  styleUrls: ['./timesheet-forms.component.css']
})
export class TimesheetFormsComponent implements OnInit {
  timesheets: any = [];
  sortedForms: any = [];
  forms: any = [];
  loading: boolean = false;

  constructor(
    private timesheetService: TimesheetService,
    public dialog: MatDialog,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getForms();
  }

  async getForms() {
    this.sortedForms = [];
    this.forms = [];
    this.loading = true;
    this.forms = await new Promise((resolve) => this.timesheetService.getTimesheetForm().subscribe((res: any) => resolve(res.data)));
    this.loading = false;
    this.sortedForms = this.forms.slice();
  }

  sortData(sort: Sort) {
    const data = this.forms.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedForms = data;
      return;
    }

    this.sortedForms = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'count':
          return compare(a.fields.length, b.fields.length, isAsc);
        default:
          return 0;
      }
    });
  }

  onAdd(edit: boolean, preview?: boolean, formData?: string) {
    if (edit) {
      this.router.navigate(['create-form'], { queryParams: { preview, formData: JSON.stringify(formData) } })
    } else {
      this.router.navigate(['create-form'], { queryParams: { preview } })
    }
  }

  // onAdd(edit: boolean, preview?: boolean, formData?: string) {
  //   let modalRef = this.dialog.open(CreateFormComponent, {
  //     panelClass: 'creteFormModal',
  //     position: { top: '30px' },
  //     width: '100%',
  //     height: '90%',
  //     data: edit? {
  //       formData,
  //       preview
  //     }:{},
  //   });

  //   modalRef.afterClosed().subscribe((result: any) => {
  //     if (result?.refresh) {
  //       this.ngOnInit();
  //     }
  //   });
  // }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
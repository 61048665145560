<div class="py-6 bg-surface-secondary">
    <div class="container-fluid max-w-screen-sm vstack gap-6">

        <input style="display: none;" type="file" class="profileImagePicker" #agencyLogoInput
            (change)="fileChangeEvent($event, 'agencyLogo')" accept="image/*" />
        <input style="display: none;" type="file" class="profileImagePicker" #profileImageInput
            (change)="fileChangeEvent($event, 'profileImage')" accept="image/*">

        <div class="card mb-2" *ngIf="user.role_uuid === ROLES.AGENCY">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Agency Logo</h4>
                    <!-- <p class="text-sm text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </p> -->
                </div>
                <div class="vstack gap-5 image-upload-section">
                    <div class="image-upload" *ngIf="agencyLogo.imageChangedEvent === ''">
                        <img class="" (click)="agencyLogoInput.click()"
                            [src]="agencyLogo.selectedImage ? agencyLogo.selectedImage : 'assets/img/upload-image.png'"
                            alt="" />
                    </div>
                    <div class="image-upload"
                        *ngIf="agencyLogo.imageChangedEvent && agencyLogo.uploadButtonText !== 'Uploaded'">
                        <h5>Crop Image</h5>
                        <div class="row mt-3" style="display: block; height: 100px;">
                            <image-cropper [resizeToHeight]="120" [imageChangedEvent]="agencyLogo.imageChangedEvent"
                                [maintainAspectRatio]="false" [aspectRatio]="4 / 2" format="png"
                                (imageCropped)="imageCropped($event, 'agencyLogo')" (imageLoaded)="imageLoaded()"
                                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                        </div>
                    </div>
                </div>
                <div class="hstack gap-2 justify-content-end mt-5">
                    <button class="btn btn-sm btn-secondary me-2" data-bs-toggle="offcanvas"
                        *ngIf="agencyLogo.imageSelected && agencyLogo.uploadButtonText !== 'Uploaded'"
                        (click)="reset(agencyLogo, 'agencyLogo')">
                        <span>Cancel</span>
                    </button>
                    <button class="btn btn-primary btn-sm" data-bs-toggle="offcanvas"
                        [disabled]="agencyLogo.uploadButtonText === 'Uploaded'"
                        (click)="agencyLogo.selectedImage && agencyLogo.imageSelected ? uploadImage('agencyLogo'):agencyLogoInput.click()">
                        <span>{{agencyLogo.uploadButtonText}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Profile Image</h4>
                    <!-- <p class="text-sm text-muted">Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry
                    </p> -->
                </div>
                <div class="vstack gap-5 image-upload-section">
                    <div class="image-upload" *ngIf="profileImage.imageChangedEvent === ''">
                        <img class="border" (click)="profileImageInput.click()"
                            [src]="profileImage.selectedImage ? profileImage.selectedImage : 'assets/img/upload-image.png'"
                            alt="" />
                    </div>
                    <div class="image-upload"
                        *ngIf="profileImage.imageChangedEvent && profileImage.uploadButtonText !== 'Uploaded'">
                        <h5>Crop Image</h5>
                        <div class="row mt-3" style="display: block; height: 100px;">
                            <image-cropper [resizeToHeight]="120" [imageChangedEvent]="profileImage.imageChangedEvent"
                                [maintainAspectRatio]="false" [aspectRatio]="4 / 4" format="png"
                                (imageCropped)="imageCropped($event, 'profileImage')" (imageLoaded)="imageLoaded()"
                                (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
                        </div>
                    </div>
                </div>
                <div class="hstack gap-2 justify-content-end mt-5">
                    <button class="btn btn-sm btn-secondary me-2" data-bs-toggle="offcanvas"
                        *ngIf="profileImage.imageSelected && profileImage.uploadButtonText !== 'Uploaded'"
                        (click)="reset(profileImage, 'profileImage')">
                        <span class="pe-2"><i class="bi bi-x-circle-fill"></i></span>
                        <span>Cancel</span>
                    </button>
                    <button class="btn btn-sm btn-primary" data-bs-toggle="offcanvas"
                        [disabled]="profileImage.uploadButtonText === 'Uploaded'"
                        (click)="profileImage.selectedImage && profileImage.imageSelected ? uploadImage('profileImage'):profileImageInput.click()">
                        <span>{{profileImage.uploadButtonText}}</span>
                    </button>
                </div>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Date Format</h4>
                </div>
                <app-date-format></app-date-format>
            </div>
        </div>

        <div class="card mb-2">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Currency Format</h4>
                </div>
                <app-currency-format></app-currency-format>
            </div>
        </div>

        <div class="card md-2" *ngIf="user.role_uuid === ROLES.AGENCY">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Email Template Settings</h4>
                </div>
                <div class="vstack gap-5">
                    <p class="text-sm text-muted">Use Agency Logo in all emails ? <span class="bi bi-info-circle-fill tooltip-custom"><span class="tooltiptext">This will show the agency logo instead of default Clug logo in all the emails</span></span>
                    </p>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="showAgencyLogoEmail">
                        <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
                        <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
                      </mat-radio-group>
                </div>
                <div class="hstack gap-2 justify-content-end mt-5"><button class="btn btn-sm btn-primary" (click)="saveAgencyLogoEmail()">Save</button></div>
            </div>
        </div>

        <div class="card mb-2" *ngIf="user.role_uuid === ROLES.AGENCY">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Candidate Hours Tracking</h4>
                    <p class="text-sm text-muted">In what terms do you want to track the candidate's working hours?</p>
                </div>
                <div class="vstack gap-5">
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="candidateTrackingHrs">
                        <mat-radio-button class="me-3" value="0">Using the Start & End Time</mat-radio-button>
                        <mat-radio-button class="me-3" value="1">Number of Working Hours</mat-radio-button>
                        <mat-radio-button class="me-3" value="2">Number of Working Days</mat-radio-button>
                    </mat-radio-group>
                </div>
                <span style="color: #ff0000; font-size: 13px;" *ngIf="trackingTimeErr"
                    class="mt-1">{{trackingTimeErr}}</span>
                <div class="hstack gap-2 justify-content-end mt-5">
                    <button class="btn btn-sm btn-primary" (click)="saveCandidateTrackingHrs()">Save</button>
                </div>
            </div>
        </div>

        <div class="card mb-2" *ngIf="user.role_uuid === ROLES.AGENCY">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Timesheet Approval Email</h4>
                    <p class="text-sm text-muted">Type of Email <span
                            class="bi bi-info-circle-fill tooltip-custom"><span class="tooltiptext">Individual Email
                                Approval: Sends timesheet details of single
                                candidate
                                for Approval.<br>Bulk Email Approval: Sends timesheet details of multiple candidates
                                for
                                Approval.</span></span>
                    </p>
                </div>
                <div class="vstack gap-5">
                    <div class="row gx-3 gy-5">
                        <div class="col-md-4">
                            <div class="">
                                <mat-radio-group aria-label="Select an option"
                                    [(ngModel)]="timesheetNotificationSettings.email_type">
                                    <mat-radio-button class="me-3" [value]="0">Individual</mat-radio-button>
                                    <mat-radio-button class="" [value]="1">Bulk</mat-radio-button>
                                </mat-radio-group>
                            </div>
                        </div>
                    </div>
                    <div class="row gx-3 gy-5" *ngIf="timesheetNotificationSettings.email_type == 1">
                        <div class="col-md-4">
                            <label class="form-label">Frequency</label>
                            <mat-select class="form-control" type="text"
                                [(ngModel)]="timesheetNotificationSettings.frequency"
                                [ngModelOptions]="{standalone: true}">
                                <mat-option [value]="1">Weekly</mat-option>
                                <mat-option [value]="2">Monthly</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Day</label>
                            <mat-select class="form-control" type="text" [(ngModel)]="timesheetNotificationSettings.day"
                                [ngModelOptions]="{standalone: true}">
                                <mat-option [value]="0">Sunday</mat-option>
                                <mat-option [value]="1">Monday</mat-option>
                                <mat-option [value]="2">Tuesday</mat-option>
                                <mat-option [value]="3">Wednesday</mat-option>
                                <mat-option [value]="4">Thursday</mat-option>
                                <mat-option [value]="5">Friday</mat-option>
                                <mat-option [value]="6">Saturday</mat-option>
                            </mat-select>
                        </div>
                        <div class="col-md-4">
                            <label class="form-label">Time</label>
                            <input type="time" class="form-control input-time" placeholder="HH:mm:ss" min="06:00:00"
                                max="20:00:00" [(ngModel)]="timesheetNotificationSettings.time"
                                [ngModelOptions]="{standalone: true}" />
                        </div>
                    </div>
                </div>

                <span style="color: #ff0000; font-size: 13px;" *ngIf="timesheetApprovalErr"
                    class="mt-1">{{timesheetApprovalErr}}</span>
                <div class="hstack gap-2 justify-content-end mt-5">
                    <button class="btn btn-sm btn-primary" (click)="saveTimesheetNotificationSettings()">Save</button>
                </div>
            </div>
        </div>

        <div class="card md-2" *ngIf="user.role_uuid === ROLES.AGENCY">
            <div class="card-body">
                <div class="mb-5">
                    <h4 class="mb-1">Timesheet Notifications</h4>
                </div>
                <div class="vstack gap-5">
                    <p class="text-sm text-muted">Receive the contractor timesheet submission emails? <span class="bi bi-info-circle-fill tooltip-custom"><span class="tooltiptext">Receive a copy of the candidate submission email. Note: this is read only and does not include the client approval link.</span></span>
                    </p>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="timesheetSubmissionEmails">
                        <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
                        <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
                      </mat-radio-group>
                    <p class="text-sm text-muted">Receive the client timesheet approval emails? <span class="bi bi-info-circle-fill tooltip-custom"><span class="tooltiptext">Receive a copy of the client timesheet approval email.</span></span>
                    </p>
                    <mat-radio-group aria-label="Select an option" [(ngModel)]="timesheetApprovalEmails">
                        <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
                        <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
                      </mat-radio-group>
                </div>
                <div class="hstack gap-2 justify-content-end mt-5"><button class="btn btn-sm btn-primary" (click)="saveAgencyTimesheetSettings()">Save</button></div>
            </div>
        </div>

        <div class="card md-2" *ngIf="user.role_uuid === ROLES.AGENCY">
            <app-overtime-settings></app-overtime-settings>
        </div>

    </div>
</div>

<!-- <div class="settingsColumn margin" *ngIf="user.role_uuid === ROLES.AGENCY">
	<div class="heading pb-5">
		<h3>Candidate Hours Tracking</h3>
	</div>
	<div>	
		<label class="form-label">In what terms do you want to track the candidate's working hours?</label>
	</div>
	<div class="candidateHrsTrackingInput mt-1">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="candidateTrackingHrs">
		  <mat-radio-button value="1" style="margin-right:10px;">Number of Working Hours</mat-radio-button>
		  <mat-radio-button value="0" class="mt-2">Using the Start & End Time</mat-radio-button>
		</mat-radio-group>
	</div>
	<span style="color: #ff0000; font-size: 13px;" *ngIf="trackingTimeErr" class="mt-1">{{trackingTimeErr}}</span>
	<div class="dateFormatInput mt-5">	
		<button class="btn btn-sm btn-primary" (click)="saveCandidateTrackingHrs()">Save</button>
	</div>
</div> -->
<!-- <div class="settingsColumn margin" *ngIf="user.role_uuid === ROLES.AGENCY">
	<div class="heading pb-5">
		<h3>Timesheet Notifications</h3>
	</div>
	<div>	
		<label class="form-label">Receive the contractor timesheet submission emails? <span class="bi bi-info-circle-fill tooltip-custom"><span class="tooltiptext">Receive a copy of the candidate submission email. Note: this is read only and does not include the client approval link.</span></span></label>
	</div>
	<div class="candidateHrsTrackingInput mt-1">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="timesheetSubmissionEmails">
		  <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
		  <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
		</mat-radio-group>
	</div>
    <div>	
		<label class="form-label">Receive the client timesheet approval emails? <span class="bi bi-info-circle-fill tooltip-custom"><span class="tooltiptext">Receive a copy of the client timesheet approval email.</span></span></label>
	</div>
	<div class="candidateHrsTrackingInput mt-1">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="timesheetApprovalEmails">
		  <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
		  <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
		</mat-radio-group>
	</div>
	<span style="color: #ff0000; font-size: 13px;" *ngIf="saveAgencyTimesheetSettingsErr" class="mt-1">{{saveAgencyTimesheetSettingsErr}}</span>
	<div class="dateFormatInput mt-5">	
		<button class="btn btn-sm btn-primary" (click)="saveAgencyTimesheetSettings()">Save</button>
	</div>
</div> -->
<!-- <div class="settingsColumn margin" *ngIf="user.role_uuid === ROLES.AGENCY">
	<div class="heading pb-5">
		<h3>Over Time Settings</h3>
	</div>
	<div>	
		<h5>Daily Overtime</h5>
	</div>
	<div class="candidateHrsTrackingInput mt-1">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="timesheetSubmissionEmails">
		  <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
		  <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
		</mat-radio-group>
	</div>
	<div class="row g-5 mt-1">
		<div class="col-md-2">
			<div><label class="form-label">OT Start After</label> <input type="text" class="form-control" id="first_name"></div>
		</div>
		<div class="col-md-2">
			<div><label class="form-label">OT Multiplier</label> <input type="text" class="form-control"></div>
		</div>
	</div>
	<div class="row g-5">
		<div class="col-md-2">
			<div><label class="form-label">Double OT Start After</label> <input type="text" class="form-control" id="first_name"></div>
		</div>
		<div class="col-md-2">
			<div><label class="form-label">Double OT Multiplier</label> <input type="text" class="form-control"></div>
		</div>
	</div>
    <div class="mt-5">	
		<h5>Weekly Overtime</h5>
	</div>
	<div class="candidateHrsTrackingInput mt-1">
		<mat-radio-group aria-label="Select an option" [(ngModel)]="timesheetApprovalEmails">
		  <mat-radio-button value="1" style="margin-right:10px;">Yes</mat-radio-button>
		  <mat-radio-button value="0" class="mt-2">No</mat-radio-button>
		</mat-radio-group>
	</div>
	<div class="row g-5 mt-1">
		<div class="col-md-2">
			<div><label class="form-label">OT Start After</label> <input type="text" class="form-control" id="first_name"></div>
		</div>
		<div class="col-md-2">
			<div><label class="form-label">OT Multiplier</label> <input type="text" class="form-control"></div>
		</div>
	</div>
	<div class="dateFormatInput mt-5">	
		<button class="btn btn-sm btn-primary" (click)="saveOverTimeSettings()">Save</button>
	</div>
</div> -->

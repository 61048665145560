import { Component, OnInit, Injectable } from '@angular/core';

import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { roleConstant } from '../app.constant';
import { ClientContactsComponent } from '../client-contacts/client-contacts.component';
import { CreateClientComponent } from '../components/create-client/create-client.component';
import { EditClientComponent } from '../components/edit-client/edit-client.component';

import { AlertModal } from 'src/app/app.model';
import { AlertModalComponent } from '../components/alert-modal/alert-modal.component';

import { UserService } from '../services/user.service';
import { ClientService } from '../services/client.service';
import { DataSharedService } from '../services/data-shared.service';
import { AuthenticationService } from '../services/authentication.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css']
})

@Injectable({
  providedIn: 'root',
})

export class ClientsComponent implements OnInit {

  ROLES = roleConstant;
  user: any;
  redirectedName!: string;

  clients: any = [];
  allClients: any = [];
  totalCount: number = 0;
  loading: boolean = false;
  clientSearch: string = '';

  active_client: number = 1;
  active_contact: number = 1;

  selected_client: any = [];
  selected_contact: any = [];

  private modalRef: NgbModalRef;

  constructor(
    private router: Router,
    private route: ActivatedRoute,

    private modalService: NgbModal,

    public userService: UserService,
    private clientService: ClientService,
    public dataSharedService: DataSharedService,
    public authService: AuthenticationService,
  ) {
    this.user = this.authService.userValue;
  }

  ngOnInit(page = 1): void {
    this.route.queryParams.subscribe((params: any) => {
      this.redirectedName = params?.name;
      this.getClients(page);
    });
  }

  async getClients(page: number, search?: string) {
    this.loading = true;

    const params: any = {
      search,
      page,
      limit: 20,
      order: 'desc',
      order_by: 'created_at',
      active_client: this.active_client,
      active_contact: this.active_contact
    };

    this.clients = []
    this.allClients = []
    this.clientService.getClients(params).subscribe(clients => {
      this.loading = false;

      this.clients = clients.data;
      this.totalCount = clients.meta.total;

      if (this.redirectedName) {
        this.clients = this.clients.filter((x: any) => x.name === this.redirectedName);
      }
      this.allClients = this.clients.slice();
    });
  }

  searchClient() {
    if (this.clientSearch != '') {
      this.getClients(1, this.clientSearch);
    } else {
      this.getClients(1);
    }

  }

  async getClientByID() {
    let selected_client = this.selected_client;

    const params: any = {
      active_client: this.active_client,
      active_contact: this.active_contact
    };
    this.clientService.getClientByIDWithFilter(selected_client?.uuid, params).subscribe(response => {

      this.selected_client = response?.data;
      this.selected_contact = response?.data?.contacts;

      if (this.modalRef) {
        this.modalRef.componentInstance.updateData({
          client: this.selected_client,
          active_client: this.active_client,
          active_contact: this.active_contact
        });
      }
    });
  }

  showCLientContact(contact_type: number) {
    this.active_contact = contact_type;
    this.getClients(1);
  }

  viewClientsContacts(client: any) {
    this.selected_client = client;

    this.modalRef = this.modalService.open(ClientContactsComponent, {
      size: 'lg',
      modalDialogClass: 'view-client-modal',
      backdropClass: 'viewClientContacts',
    });

    this.modalRef.componentInstance.client = this.selected_client;
    this.modalRef.componentInstance.active_client = this.active_client;
    this.modalRef.componentInstance.active_contact = this.active_contact;

    this.modalRef.componentInstance.showEditClientModal.subscribe((client: any) => {
      this.editClient(client)
    });

    this.modalRef.componentInstance.activateEditClientContactModal.subscribe((response: any) => {
      this.selected_contact = response?.contact;

      this.activateClientContactConfirm();
    });

    this.modalRef.componentInstance.deactivateEditClientContactModal.subscribe((response: any) => {
      this.selected_contact = response?.contact;

      this.deactivateClientContactConfirm();
    });

    this.modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  createClient() {
    const modalRef = this.modalService.open(CreateClientComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-client-modal',
    });
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  onStepChange(event: any) {
    this.ngOnInit(event)
  }

  sortData(sort: Sort) {
    const data = this.clients.slice();
    if (!sort.active || sort.direction === '') {
      this.allClients = data;
      return;
    }

    this.allClients = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'name':
          return compare(a.name, b.name, isAsc);
        case 'created_at':
          return compare(a.created_at, b.created_at, isAsc);
        default:
          return 0;
      }
    });
  }

  editClient(client?: any) {
    this.selected_client = client;

    this.modalRef = this.modalService.open(EditClientComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'update-client-modal',
    });
    this.modalRef.componentInstance.client = this.selected_client;

    this.modalRef.componentInstance.deactivateEditClientContactModal.subscribe((response: any) => {
      this.selected_contact = response?.contact;

      this.deactivateClientContactConfirm();
    });

    this.modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.ngOnInit();
      }
    });
  }

  activateClientContactConfirm() {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'alert-modal modal-dialog-centered',
    });

    let data: AlertModal = {
      message: 'Are you sure you want to activate contact?',
      yesAction: 'Activate',
      yesActionColor: '#0566EA',
      noAction: 'Cancel',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activateActionClientContact();
      } else {
        this.modalRef.componentInstance.updateData();
      }
    });
  }

  activateActionClientContact() {
    let selected_contact = this.selected_contact;

    this.userService.activateUserAccount(selected_contact?.uuid).subscribe(response => {
      this.getClients(1);
      this.getClientByID();
    });
  }

  deactivateClientContactConfirm() {
    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      backdrop: false,
      modalDialogClass: 'alert-modal modal-dialog-centered',
    });

    let data: AlertModal = {
      message: 'Are you sure you want to deactivate contact?',
      yesAction: 'Deactivate',
      yesActionColor: '#0566EA',
      noAction: 'Cancel',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.deactivateActionClientContact();
      } else {
        this.modalRef.componentInstance.updateData();
      }
    });
  }

  deactivateActionClientContact() {
    let client = this.selected_contact;
    let contact = this.selected_contact;

    this.userService.deactivateUserAccount(contact?.uuid).subscribe(response => {
      this.getClients(1);
      this.getClientByID();
    });
  }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-authorization-flow-tab',
  templateUrl: './authorization-flow-tab.component.html',
  styleUrls: ['./authorization-flow-tab.component.css'],
})
export class AuthorizationFlowTabComponent implements OnInit {
  ngOnInit(): void {
  }
  movies = [
    'Band 1',
    'Band 2',
    'Band 3',
    'Band 4',
    'Band 5',
    'Band 6',
    'Band 7',
    'Band 8',
    'Band 9',
  ];

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.movies, event.previousIndex, event.currentIndex);
  }
}

<div class="mainContainer card overflow-auto margin mt-4">
    <div class="loader" *ngIf="loading">
        <mat-spinner diameter="30"></mat-spinner>
    </div>
    <div class="reminder mb-0 d-flex bg-light justify-content-between flex-column flex-sm-row">
        <h5 class="card-title ps-4 mb-0 tableTitle"> {{totalCount}} Assignments(s)</h5>
        <div class="form-inline ms-auto me-4 d-none d-md-flex hstack card-title ps-4 mb-0">
        </div>
    </div>
    <table matSort (matSortChange)="sortData($event)" class=" table table-borderless datatable">
        <thead class="table-light">
          <tr>
            <th mat-sort-header="job" scope="col" class="ps-5">Job</th>
            <th mat-sort-header="candidate" scope="col">Candidate</th>
            <th mat-sort-header="client" scope="col">Client</th>
            <th mat-sort-header="start_date" scope="col">Start Date</th>
            <th mat-sort-header="end_date" scope="col">End Date</th>
            <th mat-sort-header="created_at" scope="col">Created At</th>
            <th *ngIf="user?.role_uuid !== ROLES?.CANDIDATE" mat-sort-header="action" scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr class="assignmentsRow dataColumn" *ngFor="let jobAssignment of jobAssignments">
            <td *ngIf="user?.role_uuid === ROLES?.CANDIDATE" class="text-heading font-bold ps-5">
              <div class="textWithIcon">
                <i class="bi bi-briefcase"></i>
                <span>{{jobAssignment.job}}</span>
              </div>
            </td>
            <td *ngIf="user?.role_uuid !== ROLES?.CANDIDATE" class="text-heading font-bold ps-5">
              <div class="textWithIcon">
                <i class="bi bi-briefcase"></i>
                <span>{{jobAssignment.job}}</span>
              </div>
            </td>
            <td>{{jobAssignment.candidate}}</td>
            <td>{{jobAssignment.client}}</td>
            <td>{{dataSharedService.getFormatedDateUTC(jobAssignment.start_date)}}</td>
            <td>{{dataSharedService.getFormatedDateUTC(jobAssignment.end_date)}}</td>
            <td>{{dataSharedService.getFormatedDateUTC(jobAssignment.created_at)}}</td>
            <td *ngIf="user?.role_uuid !== ROLES?.CANDIDATE">
              <div *ngIf="user?.role_uuid === ROLES?.AGENCY || user?.role_uuid === ROLES?.CLIENT" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" (click)="activateAssignment(jobAssignment.uuid)" matTooltip="Activate assignment" [matTooltipPosition]="'left'">
                <i class="bi bi-lock"></i>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
    </app-api-paginator>
</div>
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetService } from 'src/app/services/timesheet.service';
import { SuccessModalComponent } from '../success-modal/success-modal.component';
import { SuccessModal } from 'src/app/app.model';

@Component({
  selector: 'app-timesheet-view-link-modal',
  templateUrl: './timesheet-view-link-modal.component.html',
  styleUrls: ['./timesheet-view-link-modal.component.css']
})
export class TimesheetViewLinkModalComponent implements OnInit {
  @Input() timesheetUuid: any;
  timesheetViewLink:any = [];
  constructor(
    public timesheetService: TimesheetService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<TimesheetViewLinkModalComponent>,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
  ) { 
    this.timesheetUuid = data.timesheetUuid;
  }

  ngOnInit(): void {
    this.timesheetService.getTimesheetViewLink(this.timesheetUuid).subscribe((response)=> {
      this.timesheetViewLink = response.timesheet_link;
    });
  }

  copyLink(timesheetApprovalLink:any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = timesheetApprovalLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.successModal('Link copied');
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  close(){
    this.dialogRef.close();
  }

}

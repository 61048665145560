import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal, SuccessModal } from 'src/app/app.model';

@Component({
  selector: 'app-success-modal',
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {
  @Input() data: SuccessModal = {
    message: '',
    image: '',
    duration: 0,
  };

  constructor(private activeModalService: NgbActiveModal) {}

  ngOnInit(): void {
    this.onClose();
  }


  onClose() {
    setTimeout(() => {
      this.activeModalService.close();
    }, this.data.duration);
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiConstant } from '../app.constant';
import { Clients, Client } from '../app.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }

  getClients(params: any) {
    let request_url = `${environment.baseUrl + apiConstant.CLIENTS}?page=${params?.page}`;

    if (params?.limit) {
      request_url += `&per_page=${params?.limit}`;
    }

    if (params?.active_client != undefined) {
      request_url += `&filters[active]=${params?.active_client}`;
    }

    if (params?.active_contact != undefined) {
      request_url += `&filters[active_contact]=${params?.active_contact}`;
    }

    if (params?.search != undefined) {
      request_url += `&search=${params?.search}`;
    }

    return this.http.get<Client>(request_url);
  }

  createClient(payload: any) {
    return this.http.post<Clients>(`${environment.baseUrl + apiConstant.CLIENTS}`, payload);
  }

  getClientByID(client_uuid: "") {
    let url = `${environment.baseUrl + apiConstant.CLIENTS}/${client_uuid}`;
    return this.http.get<Client>(url);
  }

  getClientByIDWithFilter(client_uuid: "", params: any) {
    let request_url = `${environment.baseUrl + apiConstant.CLIENTS}/${client_uuid}?`;

    if (params?.active_client != undefined) {
      request_url += `&filters[active]=${params?.active_client}`;
    }

    if (params?.active_contact != undefined) {
      request_url += `&filters[active_contact]=${params?.active_contact}`;
    }

    return this.http.get<Client>(request_url);
  }

  updateClient(client_uuid: '', payload: any) {
    return this.http.patch<Client>(`${environment.baseUrl + apiConstant.CLIENTS}` + `/` + client_uuid, payload);
  }

  activateAccount(client_uuid: any) {
    return this.http.post<Client>(`${environment.baseUrl + apiConstant.CLIENTS}/${client_uuid}/${apiConstant.ACTIVATE}`, {});
  }

  deactivateAccount(client_uuid: any) {
    return this.http.post<Client>(`${environment.baseUrl + apiConstant.CLIENTS}/${client_uuid}/${apiConstant.DEACTIVATE}`, {});
  }
}
import { CreateClientComponent } from './../create-client/create-client.component';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { apiConstant } from 'src/app/app.constant';
import { AlertModal } from 'src/app/app.model';
import { ClientService } from 'src/app/services/client.service';
import { JobService } from 'src/app/services/job.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { JobAssignmentService } from 'src/app/services/job-assignment.service';

@Component({
  selector: 'app-create-job',
  templateUrl: './create-job.component.html',
  styleUrls: ['./create-job.component.css']
})
export class CreateJobComponent implements OnInit {

  loading: boolean = false;

  jobForm: FormGroup | any;

  error!: string;
  clients: any = [];
  clientSearch: string = '';

  client_uuid_default: string = '';

  searchingClient: boolean = false;
  timesheetApprovers: any = [];

  created_client: any = '';
  // created_client: any = 'd52200ef-5d6e-4f6d-b453-736458a4b57a';

  public API_CONSTANT = apiConstant;

  constructor(
    private clientService: ClientService,
    private jobService: JobService,
    private activeModalService: NgbActiveModal,
    private modalService: NgbModal,
    private datePipe: DatePipe,
    private assignmentService: JobAssignmentService,
  ) { }

  ngOnInit(): void {
    this.loading = false;
    this.jobForm = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      location: new FormControl(''),
      positions: new FormControl(''),
      start_date: new FormControl('', Validators.required),
      end_date: new FormControl('', Validators.required),
      client_uuid: new FormControl(this.created_client.uuid, Validators.required),
      candidate_uuid: new FormControl(''),
      pay_rate: new FormControl('', Validators.required),
      bill_rate: new FormControl('', Validators.required),
      timesheet_approvers: new FormControl(''),
    });
  }

  async ngOnChanges(): Promise<void> {
    console.log("CreateJobComponent ngOnChanges this.jobForm --- ", this.jobForm);
  }

  onSearchChange(event: any) {
    this.jobForm.patchValue({
      [event.formControlName]: event.value.uuid
    });

    // load the timesheet approvers for the timesheet
    if (event.formControlName == 'client_uuid' && event.value.uuid != undefined) {
      this.getTimesheetApprovers(event.value.uuid);
    }
  }

  getTimesheetApprovers(clientUuid: string) {
    this.assignmentService.getTimesheeetApprovers(clientUuid).subscribe(res => {
      this.timesheetApprovers = res.data;
      this.loading = false;
    }, err => {
      this.loading = false;
      console.log(err);
    });
  }

  createClient() {
    const modalRef = this.modalService.open(CreateClientComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'add-client-modal',
    });
    modalRef.result.then((data: any) => {
      if (data?.refresh === true) {
        this.created_client = data?.client_response?.client;
        this.ngOnInit();
      }
    });
  }

  get hasValue() {
    let hasValue = false;
    Object.keys(this.jobForm.value).forEach(element => {
      if (this.jobForm.value[element]) {
        hasValue = true;
      }
    });
    return hasValue
  }

  onClose() {
    if (!this.hasValue) {
      this.activeModalService.close();
      return
    }

    const modalRef = this.modalService.open(AlertModalComponent, {
      size: 'md',
      modalDialogClass: 'alert-modal modal-dialog-centered',
      backdrop: false,
    });
    let data: AlertModal = {
      message: 'Are you sure you want to discard Job Creation?',
      yesAction: 'Discard',
      noAction: 'Cancel',
      yesActionColor: '#0566EA',
      noActionColor: 'transparent',
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

  onSubmit() {
    this.error = '';
    this.loading = true;

    const payload = {
      ...this.jobForm.value,
      start_date: this.datePipe.transform(this.jobForm.value.start_date, 'yyyy-MM-dd'),
      end_date: this.datePipe.transform(this.jobForm.value.end_date, 'yyyy-MM-dd'),
    };
    console.log("CreateJobComponent onSubmit payload ---", payload);

    this.jobService.createJob(payload).subscribe(res => {
      this.activeModalService.close({ refresh: true });
      this.loading = false;
    }, error => {
      this.loading = false;
      this.error = error;
    });
  }
}

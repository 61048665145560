import { Component, OnInit } from '@angular/core';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModalComponent } from '../../components/success-modal/success-modal.component';
import { AlertModal, SuccessModal } from 'src/app/app.model';


@Component({
  selector: 'app-overtime-settings',
  templateUrl: './overtime-settings.component.html',
  styleUrls: ['./overtime-settings.component.css']
})
export class OvertimeSettingsComponent implements OnInit {
  weeklyOverTimeSettings: any = {
    ot_start_after: '',
    ot_multiplier: '',
  };
  dailyOverTimeSettings: any = {
    ot_start_after: '',
    ot_multiplier: '',
    double_ot_start_after: '',
    double_ot_multiplier: '',
    is_active: '',
  };

  constructor(
    public dataSharedService: DataSharedService,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.overTimeSettings();
  }

  async overTimeSettings() {
    this.dataSharedService.getOverTimeSettings().subscribe((res: any) => {
      this.dailyOverTimeSettings = res.overtime_settings.find((x: any) => x.type === 1);
      this.weeklyOverTimeSettings = res.overtime_settings.find((x: any) => x.type === 2);
    })
  }

  
  async saveOverTimeSettings() {
    let overTimeSettings = [this.dailyOverTimeSettings, this.weeklyOverTimeSettings];
    await this.dataSharedService.updateOverTimeSettings(overTimeSettings).subscribe({
      next: (response) => {
        this.successModal('Updated Successfully!');
      },
      error: (err) => {
      },
    });
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TimesheetService } from '../services/timesheet.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  formControl: any;
  loading = false;
  error = '';

  constructor(
    private timesheetService: TimesheetService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<ForgotPasswordComponent>
  ) { 
    this.formControl = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
  }

  submitForm() {
    this.loading = true;
    this.error = ''
    this.timesheetService.forgotPassword({email: this.formControl.value.email}).subscribe(res => {
      this.loading = false;
      this.dialogRef.close({emailSent: true});
    }, error => {
      this.error = error;
      this.loading = false;
    });
  }
}

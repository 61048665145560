import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { JobAssignment, TimesheetApprovers } from '../app.model';

@Injectable({
  providedIn: 'root'
})
export class JobAssignmentService {

  constructor(private http: HttpClient) { }

  getJobAssignments(page: number, filters: any = []) {
    let apiUrl = `${environment.baseUrl+apiConstant.JOB_ASSINGMENTS}?page=${page}`;
    if(filters.active != undefined) {
      apiUrl+= '&filters[active]='+filters.active;
    }

    return this.http.get<JobAssignment>(apiUrl);
  }

  getJobAssignmentsWithSearch(search: string) {
    return this.http.get<JobAssignment>(`${environment.baseUrl+apiConstant.JOB_ASSINGMENTS}?search=${search}`);
  }

  addJobAssignments(payload: any) {
    return this.http.post<JobAssignment>(`${environment.baseUrl}`+apiConstant.JOB_ASSINGMENTS, payload);
  }
  
  updateJobAssignments(assignment_uuid:'',payload: any) {
    return this.http.patch<JobAssignment>(`${environment.baseUrl}`+apiConstant.JOB_ASSINGMENTS+`/`+assignment_uuid, payload);
  }

  getTimesheeetApprovers(clientUuid: string) {
    return this.http.get<TimesheetApprovers>(`${environment.baseUrl}`+clientUuid+`/`+apiConstant.TIMESHEET_APPROVERS);
  }

  deactivateAssignment(assignmentUuid: string) {
    return this.http.post<JobAssignment>(`${environment.baseUrl + apiConstant.JOB_ASSINGMENTS + '/' +assignmentUuid}/`+ apiConstant.DEACTIVATE , {});
  }

  activateAssignment(assignmentUuid: string) {
    return this.http.post<JobAssignment>(`${environment.baseUrl + apiConstant.JOB_ASSINGMENTS +  '/' + assignmentUuid}/`+ apiConstant.ACTIVATE , {});
  }
}
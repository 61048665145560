<div class="loader" *ngIf="loading">
  <mat-spinner diameter="40"></mat-spinner>
</div>
<div class="modal-header p-5">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <h3 class="modal-title">Create Timesheet</h3>
  <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<div class="modal-body p-5">
  <ng-container *ngIf="!preview">
    <!-- Other Agencies Form -->
    <!-- <form [formGroup]="timesheetForm" *ngIf="timesheetForm && !is31T">
      <div class="selectTimeSheet">
        <label for="selectTimeSheet">Select Assignment</label>
        <mat-select class="datePickerRangeContainer" (selectionChange)="onAssignmentChange()"
          formControlName="assignment_uuid" [disabled]="disableAssignmentSelect"
          [ngClass]="{'invalidField':timesheetForm.controls.assignment_uuid?.errors && timesheetForm.controls.assignment_uuid?.touched}">
          <mat-option *ngFor="let option of assignments" [value]="option.uuid">{{option.job}}
          </mat-option>
        </mat-select>
      </div>

      <div class="formsRow" *ngIf="!start_date || !end_date">
        <ng-container *ngFor="let field of fields?.fields">
          <div class="customField {{field.type}}" *ngIf="field.field_type !== 'static'">
            <label for="customField">{{field.label}} <span *ngIf="field.is_required" class="requiredStar">*</span></label>
            <input *ngIf="field.type === 'text' || field.type === 'number'" type="text" [type]="field.type"
              class="input-form remover" [(ngModel)]="copyableValues[field.label]"
              [ngModelOptions]="{standalone: true}">
            <textarea *ngIf="field.type === 'textarea'" class="input-form" [(ngModel)]="copyableValues[field.label]"
              [ngModelOptions]="{standalone: true}" id="floatingTextarea"></textarea>
            <mat-select *ngIf="field.type === 'dropdown' || field.type === 'single-select'" class="input-form"
              [(ngModel)]="copyableValues[field.label]" [ngModelOptions]="{standalone: true}">
              <mat-option *ngFor="let option of field.values.split(',')" [value]="option">{{option}}
              </mat-option>
            </mat-select>
          </div>
        </ng-container>
      </div>
      <div class="selectTimeSheet">
        <div class="datePickerRangeContainer" (click)="picker.open()">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <i class="bi bi-calendar4"></i>
          <mat-date-range-input [rangePicker]="picker"
            [disabled]="!timesheetForm?.value?.assignment_uuid || !copyableFieldsIsValid" [min]="getLimit('min')"
            [max]="getLimit('max')">
            <input matStartDate [ngModelOptions]="{standalone: true}" [(ngModel)]="start_date" placeholder="Start Date">
            <input matEndDate [ngModelOptions]="{standalone: true}" [(ngModel)]="end_date" placeholder="End Date"
              (dateChange)="dateChanged($event)">
          </mat-date-range-input>
        </div>
      </div>
      <div class="timesheetColumn" *ngIf="timesheets.length">
        <swiper [config]="config">
          <div class="swiper-wrapper" [ngClass]="{'spaceEvenly' : timesheets.length <= 2}">
            <div class="swiper-slide" *ngFor="let timesheet of timesheets; let i = index;"
              (click)="selectedSlideDate = timesheet.index"
              [class.slide-active]="selectedSlideDate === timesheet.index">
              <span>{{dataSharedService.getFormatedDate(timesheet.date)}}</span>
              <i class="bi bi-x-circle" *ngIf="selectedSlideDate === timesheet.index"
                (click)="removeSlide(i, timesheet.index)"></i>
            </div>
          </div>
          <div class="swiper-button swiper-button-next"></div>
          <div class="swiper-button swiper-button-prev"></div>
        </swiper>
      </div>
      <div class="formsRow" *ngIf="(selectedSlideDate || selectedSlideDate === 0) && timesheets.length">
        <ng-container *ngFor="let timesheet of timesheets">
          <ng-container *ngIf="timesheet.index === selectedSlideDate">
            <ng-container *ngFor="let field of fields?.fields">
              <div class="customField {{field.type}}" *ngIf="field.field_type !== 'static'">
                <label for="customField">{{field.label}} <span *ngIf="field.is_required" class="requiredStar">*</span></label>
                <div class="fieldAndCopyButton">
                  <input *ngIf="field.type === 'text' || field.type === 'number'" type="text" [type]="field.type"
                    class="input-form remover" [formControlName]="field.label+'_'+timesheet.index"
                    [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}">
                  <textarea *ngIf="field.type === 'textarea'" class="input-form"
                    [formControlName]="field.label+'_'+timesheet.index"
                    [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}"
                    id="floatingTextarea"></textarea>
                  <mat-select *ngIf="field.type === 'dropdown' || field.type === 'single-select'" class="input-form"
                    [formControlName]="field.label+'_'+timesheet.index"
                    [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}">
                    <mat-option *ngFor="let option of field.values.split(',')" [value]="option">{{option}}
                    </mat-option>
                  </mat-select>
                  <div class="copy">
                    <a class="btn btn-neutral btn-sm bt-square ms-1"
                      (click)="copyValues(field.label, field.label+'_'+timesheet.index, timesheet.index)">
                      <i class="bi bi-clipboard-fill"></i>
                    </a>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </form> -->
    <!-- Other Agencies Form -->

    <!-- 31T Form -->
    <ng-container *ngIf="timesheetForm">
      <form [formGroup]="timesheetForm">
        <div class="row g-3">

          <div class="col-md-12">
            <label for="form-label">Select Assignment</label>
            <mat-select class="datePickerRangeContainer" (selectionChange)="onAssignmentChange()"
              formControlName="assignment_uuid" [disabled]="disableAssignmentSelect"
              [ngClass]="{'invalidField':timesheetForm.controls.assignment_uuid?.errors && timesheetForm.controls.assignment_uuid?.touched}">
              <mat-option *ngFor="let option of assignments" [value]="option.uuid">{{option.job}}
              </mat-option>
            </mat-select>
          </div>

          <div class="col-md-12">
            <div class="datePickerRangeContainer" (click)="picker.open()">
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
              <i class="bi bi-calendar4"></i>
              <mat-date-range-input [rangePicker]="picker" [disabled]="!timesheetForm?.value?.assignment_uuid"
                [min]="getLimit('min')" [max]="getLimit('max')">
                <input matStartDate [ngModelOptions]="{standalone: true}" [(ngModel)]="start_date"
                  placeholder="Start Date">
                <input matEndDate [ngModelOptions]="{standalone: true}" [(ngModel)]="end_date" placeholder="End Date"
                  (dateChange)="dateChanged($event)">
              </mat-date-range-input>
            </div>
          </div>

          <div class="col-md-12">
            <div class="formsRow" *ngIf="(selectedSlideDate || selectedSlideDate === 0) && timesheets.length">
              <ng-container *ngFor="let timesheet of timesheets; let i = index">
                <div class="timesheetFormTitleContainer">
                  <div class="timesheetDateTitle">
                    <label for="customField" *ngIf="i === 0">Date</label>
                  </div>
                  <ng-container *ngFor="let field of fields?.fields">
                    <div class="timesheetFieldTitle" *ngIf="field.field_type !== 'static'">
                      <label *ngIf="i === 0">{{field.label}} <span *ngIf="field.is_required"
                          class="requiredStar">*</span></label>
                    </div>
                  </ng-container>
                </div>
                <div class="singleDateTimesheetForm">
                  <div class="timesheetDateContainer">
                    <!-- <label for="customField" *ngIf="i === 0">Date</label> -->
                    <div class="timesheetDate">
                      <span>{{timesheet.date | date: 'EEE' }}
                        {{dataSharedService.getFormatedDate(timesheet.date)}}</span>
                      <div class="removeDateIcon">
                        <i class="bi bi-x-circle" (click)="removeSlide(i, timesheet.index)"></i>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngFor="let field of fields?.fields">
                    <div class="customField {{field.type}}" *ngIf="field.field_type !== 'static'">
                      <!-- <label for="customField" *ngIf="i === 0">{{field.label}}</label> -->
                      <div class="fieldAndCopyButton">
                        <input *ngIf="field.type === 'text' || field.type === 'number'" type="text" [type]="field.type"
                          class="input-form remover" [formControlName]="field.label+'_'+timesheet.index"
                          [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}">
                        <textarea *ngIf="field.type === 'textarea'" class="input-form"
                          [formControlName]="field.label+'_'+timesheet.index"
                          [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}"
                          id="floatingTextarea"></textarea>
                        <mat-select *ngIf="field.type === 'dropdown' || field.type === 'single-select'"
                          class="input-form" [formControlName]="field.label+'_'+timesheet.index"
                          [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}">
                          <mat-option *ngFor="let option of field.values.split(',')" [value]="option">{{option}}
                          </mat-option>
                        </mat-select>

                        <input type="time" class="input-form remover input-time"
                          [formControlName]="field.label+'_'+timesheet.index" ng-model="startTime.value"
                          placeholder="HH:mm:ss" min="06:00:00" max="20:00:00"
                          *ngIf="field.type === 'time_range' && field.label === 'Start Time'"
                          [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}" />
                        <input type="time" class="input-form remover input-time"
                          [formControlName]="field.label+'_'+timesheet.index" ng-model="endTime.value"
                          placeholder="HH:mm:ss" min="06:00:00" max="20:00:00"
                          *ngIf="field.type === 'time_range' && field.label === 'End Time'"
                          [ngClass]="{'invalidField':timesheetForm.controls[field.label+'_'+timesheet.index]?.errors && timesheetForm.controls[field.label+'_'+timesheet.index]?.touched}" />


                        <div class="copy">
                          <a class="btn btn-neutral btn-sm bt-square ms-1"
                            (click)="copyValues(field.label, field.label+'_'+timesheet.index, timesheet.index)">
                            <i class="bi bi-clipboard-fill"></i>
                          </a>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
      <div class="totalHoursContainer" *ngIf="timesheets.length && !showTotalDays">
        <div class="totalHours">
          <label>Total Hours</label>
          <input type="number" [placeholder]="totalHours">
        </div>
      </div>
      <div class="totalHoursContainer" *ngIf="timesheets.length && showTotalDays">
        <div class="totalHours">
          <label>Total Days</label>
          <input type="number" [placeholder]="totalDays">
        </div>
      </div>
    </ng-container>
    <!-- 31T Form -->
    <span *ngIf="error" class="error">{{error}}</span>
  </ng-container>
  <ng-container *ngIf="preview">
    <app-timesheet-details
      [fields]="fields"
      [jobName]="jobName"
      [end_date]="end_date"
      [start_date]="start_date"
      [from]="'create-timesheet'"
      [timesheetForm]="timesheetFormToPreview"
      [timesheetDetails]="timesheetDetailsToPreview"
    ></app-timesheet-details>
  </ng-container>
</div>

<div class="modal-footer justify-content-center p-2">
  <div class="text-center">
    <button type="button" class="btn btn-sm btn-success me-5" [disabled]="loading || !timesheets.length"
      (click)="preview ? preview = false:previewTimesheet()">{{preview ? 'Back':'Preview Timesheet'}}</button>
    <button type="submit" class="btn btn-sm btn-primary" [disabled]="loading || !timesheets.length"
      (click)="onSubmit()">Submit</button>
  </div>
</div>
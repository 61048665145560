<nav class="navbar navbar-light position-lg-sticky top-lg-0 d-none d-lg-block overlap-10 flex-none bg-white border-bottom px-0 py-3"
    id="topbar">
    <div class="container-fluid">

        <div class="hstack gap-2">
            <button type="button" [matMenuTriggerFor]="aboveMenu"
                class="shortcutPopupButton btn btn-primary text-white d-flex align-items-center justify-content-sm-between"
                data-bs-container="body" data-bs-toggle="popover" data-bs-placement="bottom"
                data-bs-content="Bottom popover">
                <div class="createAndIcon d-flex align-items-center">
                    <i class="bi bi-plus-circle-dotted"></i>
                    <span>Create</span>
                </div>
                <i class="bi bi-chevron-down"></i>
            </button>
            <mat-menu #aboveMenu="matMenu" id="menu" class="shortcutsMenu">
                <button class="shortcutBtn" *ngFor="let shortcut of shortcuts"
                    [style.display]="user?.role_uuid === shortcut.role? '':'none'" (click)="onAdd(shortcut)">
                    <div class="background" [style.background]="shortcut.bgColor">
                        <i class="icon" [class]="shortcut.icon" [style.color]="shortcut.color"></i>
                    </div>
                    {{shortcut.name}}
                </button>
            </mat-menu>
        </div>

        <!-- Form -->
        <form class="form-inline ms-auto me-4 d-none d-md-flex">
            <div class="input-group input-group-inline shadow-none">
                <span class="input-group-text border-0 shadow-none ps-0 pe-3">
                    <i class="bi bi-search"></i>
                </span>
                <input type="text" class="form-control form-control-flush" placeholder="Search" aria-label="Search">
            </div>
        </form>

        <!-- Right menu -->
        <div class="navbar-user d-none d-sm-block">
            <div class="hstack gap-3 ms-4">
                <div class="dropdown">
                    <a href="#" class="nav-link px-3 text-base text-muted text-opacity-70 text-opacity-100-hover"
                        id="dropdown-notifications" data-bs-toggle="dropdown" aria-expanded="false">
                        <!-- <i class="bi bi-bell-fill"></i> -->
                    </a>
                    <div class="dropdown-menu dropdown-menu-end px-2" aria-labelledby="dropdown-notifications">
                        <div class="dropdown-item d-flex align-items-center">
                            <h6 class="dropdown-header p-0 m-0 font-semibold">Notifications</h6>
                            <a href="#" class="text-sm font-semibold ms-auto">Clear all</a>
                        </div>
                        <div class="dropdown-item py-3 d-flex">
                            <div>
                                <div class="avatar bg-tertiary text-white rounded-circle">
                                    RF
                                </div>
                            </div>
                            <div class="flex-fill ms-3">
                                <div class="text-sm lg-snug w-64 text-wrap">
                                    <a href="#" class="font-semibold text-heading text-primary-hover">Robert</a>
                                    sent a message to <a href="#"
                                        class="font-semibold text-heading text-primary-hover">Webpixels</a>
                                </div>
                                <span class="text-muted text-xs">30 mins ago</span>
                            </div>
                        </div>

                        <div class="dropdown-item py-3 d-flex">
                            <div>
                                <div class="avatar bg-success text-white rounded-circle">
                                    KW
                                </div>
                            </div>
                            <div class="flex-fill ms-3">
                                <div class="text-sm lg-snug w-64 text-wrap">
                                    <a href="#" class="font-semibold text-heading text-primary-hover">Robert</a>
                                    sent a message to <a href="#"
                                        class="font-semibold text-heading text-primary-hover">Webpixels</a>
                                </div>
                                <span class="text-muted text-xs">30 mins ago</span>
                            </div>
                        </div>

                        <div class="dropdown-item py-3 d-flex">
                            <div>

                                <div class="avatar bg-success text-white rounded-circle">
                                    KW
                                </div>

                            </div>
                            <div class="flex-fill ms-3">
                                <div class="text-sm lg-snug w-64 text-wrap">
                                    <a href="#" class="font-semibold text-heading text-primary-hover">Robert</a>
                                    sent a message to <a href="#"
                                        class="font-semibold text-heading text-primary-hover">Webpixels</a>
                                </div>
                                <span class="text-muted text-xs">30 mins ago</span>
                            </div>
                        </div>

                        <div class="dropdown-item py-3 d-flex">
                            <div>
                                <div class="avatar bg-success text-white rounded-circle">
                                    KW
                                </div>
                            </div>
                            <div class="flex-fill ms-3">
                                <div class="text-sm lg-snug w-64 text-wrap">
                                    <a href="#" class="font-semibold text-heading text-primary-hover">Robert</a>
                                    sent a message to <a href="#"
                                        class="font-semibold text-heading text-primary-hover">Webpixels</a>
                                </div>
                                <span class="text-muted text-xs">30 mins ago</span>
                            </div>
                        </div>

                        <div class="dropdown-item py-3 d-flex">
                            <div>
                                <div class="avatar bg-success text-white rounded-circle">
                                    KW
                                </div>
                            </div>
                            <div class="flex-fill ms-3">
                                <div class="text-sm lg-snug w-64 text-wrap">
                                    <a href="#" class="font-semibold text-heading text-primary-hover">Robert</a>
                                    sent a message to <a href="#"
                                        class="font-semibold text-heading text-primary-hover">Webpixels</a>
                                </div>
                                <span class="text-muted text-xs">30 mins ago</span>
                            </div>
                        </div>

                        <div class="dropdown-divider"></div>
                        <div class="dropdown-item py-2 text-center">
                            <a href="#" class="font-semibold text-muted text-primary-hover">View all</a>
                        </div>
                    </div>
                </div>
                <button [matMenuTriggerFor]="notificationPenal" class="notificationIcon"
                    (click)="showDropDown = false; readAllNotifications()">
                    <i class="bi bi-bell"></i>
                    <div class="notificationIndicator" *ngIf="newNotificationStatus">
                        <div></div>
                    </div>
                </button>

                <mat-menu #notificationPenal="matMenu" id="menu" class="notificationPanel" [hasBackdrop]="false">
                    <app-notification-panel [notifications]="bellNotificationData"
                        [notificationCurrentPage]="notificationCurrentPage"
                        [notificationLastPage]="notificationLastPage"></app-notification-panel>
                </mat-menu>

                <div class="dropdown">
                    <a class="d-flex align-items-center" role="button" aria-haspopup="false" aria-expanded="false"
                        (click)="showDropDown = !showDropDown">
                        <div>
                            <div class="avatar avatar-sm bg-warning rounded-circle text-white"
                                *ngIf="dataSharedService.profileImage">
                                <img alt="..." src="{{dataSharedService.profileImage}}">
                            </div>
                            <div class="cotractorInitialIcon bg-primary bg-opacity-20 h-10 w-10"
                                *ngIf="!dataSharedService.profileImage">
                                <span class="text-primary fs-16x">{{authService.getInitials(user.name)}}</span>
                            </div>
                        </div>
                        <div class="d-none d-sm-block ms-3">
                            <span class="h6">{{user.name}}</span>
                        </div>
                        <div class="d-none d-md-block ms-md-2">
                            <i class="bi bi-chevron-down text-muted text-xs"></i>
                        </div>
                    </a>
                    <div class="dropdown-menu dropdown-menu-end show" id="dropdown-starter" *ngIf="showDropDown">
                        <div class="dropdown-header">
                            <span class="d-block text-sm text-muted mb-1 text-start">Signed in as</span>
                            <span class="d-block text-heading font-semibold text-start">{{user.name}}</span>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/dashboard">
                            <i class="bi bi-house me-3"></i>Home
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" href="/settings">
                            <i class="bi bi-gear me-3"></i>Settings
                        </a>
                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item" routerLink="/logout">
                            <i class="bi bi-person me-3"></i>Logout
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</nav>
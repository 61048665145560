<div class="container-fluid max-w-screen-lg vstack gap-6 mt-4">
  <div class="card">
    <div class="card-body">
      <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
        <div class="example-box" *ngFor="let movie of movies; let i = index" cdkDrag>
          <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
          <span>{{i}}</span>
          <label>{{movie}}</label>
          <mat-icon cdkDragHandle>drag_indicator</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="mainContainer pb-4 card overflow-auto mx-5 margin">
  <div class="listHeader">
    <label>Tasks</label>
    <div class="submitForm d-flex justify-content-center align-items-center" routerLink="/tasks">
      <button class="submitForm btn btn-sm px-3 btn-neutral d-flex align-items-center text-white">
        <i id="add" class="bi bi-plus-circle-dotted me-2"></i>
        Create
      </button>
    </div>
  </div>
  <div class="bodyContainer">
    <ul class="nav nav-tabs">
      <li class="nav-item firstChild">
        <a class="nav-link" style="cursor: pointer;" [class]="tab === 'all' ? 'active':''" (click)="tab = 'all'">All ({{taskArr.length}})</a>
      </li>
      <li class="nav-item secondChild" *ngFor="let status of taskService.statusOptions">
        <a class="nav-link" style="cursor: pointer;" [class]="tab === status.key ? 'active':''"
          (click)="tab = status.key">{{status.name}} ({{getCount(status.key)}})</a>
      </li>
    </ul>
    <div class="tabBody mt-5 px-8">
      <div class="py-4" *ngFor="let status of taskService.statusOptions">
        <ng-container *ngIf="status.key === tab || tab === 'all'">
          <div class="tabheader">
            <div class="left" [ngSwitch]="status.key">
              <img *ngSwitchCase="'not started'" src="./assets/img/expired.svg" class=" me-5">
              <p *ngSwitchCase="'in progress'" class="me-5">🚧</p>
              <p *ngSwitchCase="'completed'" class="me-5">🏆</p>
              <p *ngSwitchCase="'signed'" class="me-5">✍</p>
              <i *ngSwitchDefault class="bi bi-circle-fill me-5"></i>
              <label>{{status.name}}</label>
            </div>
            <div class="right">
              <i class="bi bi-three-dots"></i>
            </div>
          </div>
          <ng-container *ngFor="let task of taskArr">
            <ng-container *ngIf="status.key === task.status">
              <div class="task py-4 d-flex justify-content-between align-items-center">
                <div class="leftTabName d-flex align-items-center">
                  <mat-checkbox class="checkbox me-5" [checked]="task.status === 'completed' || task.status === 'signed'" (change)="onCheckedChange(task)"
                    [ngClass]="{'noBorder' : task.checked}"></mat-checkbox>
                  <label class="taskName me-5" (click)="editTasks(task.uuid)">{{task?.name}}</label>
                  <label class="description">{{task?.description}}</label>
                </div>
                <div class="rightTabName d-flex align-items-center">
                  <div class="comments ms-4" (click)="openComments(task.uuid)">
                    <i class="bi bi-chat-left-text me-2"></i>
                    <label>{{task.comments.length}}</label>
                  </div>
                  <div class="images ms-4">
                    <div class="img mb-2">
                      <img src="assets/img/candidate-icon.png" alt="">
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>
          </ng-container>
          <div class="emptyTask" *ngIf="!getCount(status.key)">
            <h3>No any "{{status.name}}" task</h3>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
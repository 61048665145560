<div class="container p-2">
    <!-- <div class="d-flex justify-content-end">
        <mat-icon class="clickable" (click)="onClose('no')">close</mat-icon>
    </div>
        <mat-icon class="clickable" (click)="onClose('no')">close</mat-icon>
    </div> -->
    <div class="d-flex justify-content-center">
        <div class="img">
            <img [src]="data?.image ? data.image: '/assets/img/alertTrash.svg'" alt="">
        </div>
    </div>
    <div class="d-flex justify-content-center py-2">
        <label class="alertMsg" for="">{{data.message}}</label>
    </div>
    <!-- <div class="d-flex justify-content-center pt-5">
    <div class="d-flex justify-content-center py-2 ">
      <button [style.background]="data.yesActionColor" class="btn-discard"
        (click)="onClose('yes')">{{data.yesAction}}</button>
    </div>
    <div class="d-flex justify-content-center py-2">
      <button [style.background]="data.noActionColor" class="btn-cancel"
        (click)="onClose('no')">{{data.noAction}}</button>
    </div>
  </div> -->
</div>
<div class="loader" *ngIf="loading">
  <mat-spinner diameter="30"></mat-spinner>
</div>

<header>
  <div class="container-fluid">
    <div class="border-bottom pt-6">
      <div class="row align-items-center">
        <div class="col-sm col-12">
          <h2 class="h2 ls-tight">Clients</h2>
        </div>
        <div class="col-sm-auto col-12 mt-4 mt-sm-0">
          <div class="hstack gap-2 justify-content-sm-end">
            <a class="btn btn-sm px-3 mx-5 d-flex align-items-center btn btn-sm btn-primary"
              *ngIf="user?.role_uuid === ROLES?.AGENCY" (click)="createClient()">
              <i class="bi bi-plus-circle-dotted me-2"></i> Add Client
            </a>
          </div>
        </div>
      </div>
      <ul class="nav nav-tabs overflow-x border-0">
        <li class="nav-item">
          <a class="nav-link cursor-pointer" [ngClass]="{'active': active_contact===1}"
            (click)="showCLientContact(1)">Active</a>
        </li>
        <li class="nav-item">
          <a class="nav-link cursor-pointer" [ngClass]="{'active': active_contact===0}"
            (click)="showCLientContact(0)">Inactive</a>
        </li>
      </ul>
    </div>
  </div>
</header>


<div class="container-fluid vstack gap-6">
  <div class="vstack gap-4">

    <div class="card mt-4">

      <div class="card-header">
        <div class="row align-items-center">
          <div class="col-sm col-12">
            <h5 class="h5 ls-tight">{{totalCount}} Client(s)</h5>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
            <div class="hstack gap-2 justify-content-sm-end">
              <div class="input-group input-group-sm input-group-inline">
                <span class="input-group-text pe-2"><i class="bi bi-search"></i> </span>
                <input type="email" class="form-control" placeholder="Search Clients" aria-label="Search"
                  [(ngModel)]="clientSearch" (keyup)="searchClient()">
              </div>
              <!-- <a class="btn btn-sm btn-primary add-client-button" *ngIf="user?.role_uuid === ROLES?.AGENCY"
                (click)="createClient()">
                Add Client
              </a> -->
            </div>
          </div>
        </div>
      </div>

      <!-- Table with hoverable rows -->
      <div class="table-responsive">
        <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap">
          <thead class="table-light">
            <tr>
              <th mat-sort-header="name" scope="col">Name</th>
              <th mat-sort-header="name" scope="col">Identifier</th>
              <th mat-sort-header="created_at" scope="col">Created At</th>
              <th scope="col">Contacts</th>
              <th scope="col" *ngIf="user.role_uuid === ROLES.AGENCY && active_contact == 1">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let client of allClients" class="dataColumn">
              <td>
                <a href="javascript:void(0);" class="text-heading font-semibold" (click)="editClient(client)">
                  <div class="avatar avatar-sm rounded-circle bg-primary bg-opacity-20 me-2 h-7 w-7">
                    <span>{{authService.getInitials(client.name)}}</span>
                  </div>
                  <span>{{client.name}}</span>
                </a>
              </td>
              <td>{{client.identifier}}</td>
              <td>{{dataSharedService.getFormatedDate(client.created_at)}}</td>
              <td>
                <div class="avatar-group" (click)="viewClientsContacts(client)">
                  <ng-container *ngFor="let contact of client.contacts">
                    <img alt="..." src="{{dataSharedService.getImage(contact.profile_pic)}}"
                      class="avatar avatar-sm rounded-circle me-2 cursor-pointer" *ngIf="contact.profile_pic" />
                    <div
                      class="avatar avatar-sm rounded-circle bg-primary text-white border border-2 border-solid border-card cursor-pointer"
                      *ngIf="!contact.profile_pic">
                      {{authService.getInitials(contact.name)}}
                    </div>
                  </ng-container>
                </div>

              </td>
              <td class="" *ngIf="user.role_uuid === ROLES.AGENCY && active_contact == 1">
                <button type="button" class="btn btn-sm btn-square btn-neutral" (click)="editClient(client)">
                  <i class="bi bi-pencil-fill"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
      </app-api-paginator>
    </div>
  </div>
</div>
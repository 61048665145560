import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModal } from 'src/app/app.model';

import { ClientService } from 'src/app/services/client.service';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';
import { AuthenticationService } from 'src/app/services/authentication.service';
declare var angular: any;

@Component({
	selector: 'app-create-client',
	templateUrl: './create-client.component.html',
	styleUrls: ['./create-client.component.css']
})
export class CreateClientComponent implements OnInit {

	error!: string;
	loading: boolean = false;

	formValid: boolean = false;
	clientForm: FormGroup | any;

	// first_name!: string;
	// last_name!: string;
	// email!: string;

	email_type: number = 0;
	frequency: number = 1;
	day_to_receive: number = 1;
	time_to_receive: string = '17:00';
	clientformValid: boolean = false;
	client_contact: any = {
		uuid: '',
		first_name: '',
		last_name: '',
		email: '',
		send_invite: true,
		timesheet_notification_settings: {
			email_type: this.email_type,
			frequency: this.frequency,
			day: this.day_to_receive,
			time: this.time_to_receive,
		}
	};
	contact_edit_index!: number;
	client_contacts: any = [];

	constructor(
		private clientService: ClientService,
		public authService: AuthenticationService,

		private modalService: NgbModal,
		private activeModalService: NgbActiveModal
	) { }

	ngOnInit(): void {
		this.clientForm = new FormGroup({
			name: new FormControl('', Validators.required),
			identifier: new FormControl('', []),
			address_1: new FormControl('', []),
			address_2: new FormControl('', []),
			post_zip_code: new FormControl('', []),
			country: new FormControl('', []),
		});
	}

	onClose() {
		const modalRef = this.modalService.open(AlertModalComponent, {
			size: 'md',
			backdrop: false,
			modalDialogClass: 'alert-modal modal-dialog-centered',
		});

		let data: AlertModal = {
			message: 'Are you sure you want to discard Client Creation?',
			yesAction: 'Discard',
			yesActionColor: '#0566EA',
			noAction: 'Cancel',
			noActionColor: 'transparent',
		}
		modalRef.componentInstance.data = data;
		modalRef.result.then((data) => {
			if (data?.action === 'yes') {
				this.activeModalService.close();
			}
		});
	}

	ValidateEmail(email: string) {
		var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		let email_regexp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
		return email_regexp.test(email);
	}

	async ClientContactValidation(event: any) {
		this.error = '';
		let hasErr = false;

		if (this.client_contact.first_name.trim() == '') {
			hasErr = true;
		}

		if (!hasErr && this.client_contact.last_name.trim() == '') {
			hasErr = true;
		}

		if (this.client_contact.email.trim() == '') {
			hasErr = true;
		} else {
			if (!this.ValidateEmail(this.client_contact.email.trim())) {
				hasErr = true;
			}
		}

		if (this.client_contact.timesheet_notification_settings.email_type == 1) {
			if (this.client_contact.timesheet_notification_settings.time.trim() == '') {
				hasErr = true;
			}
		}

		this.clientformValid = (hasErr) ? false : true;
	}

	async AddClientContact(event: any) {
		await this.ClientContactValidation(event);
		if (this.clientformValid) {
			if (typeof this.contact_edit_index !== "undefined") {
				this.client_contacts[this.contact_edit_index] = {
					uuid: this.client_contact?.id,
					first_name: this.client_contact?.first_name,
					last_name: this.client_contact?.last_name,
					name: `${this.client_contact?.first_name} ${this.client_contact?.last_name}`,
					email: this.client_contact?.email,
					send_invite: this.client_contact?.send_invite,
					timesheet_notification_settings: this.client_contact?.timesheet_notification_settings
				}
			} else {
				this.client_contacts.push({
					uuid: this.client_contacts.length + 1,
					first_name: this.client_contact?.first_name,
					last_name: this.client_contact?.last_name,
					name: `${this.client_contact?.first_name} ${this.client_contact?.last_name}`,
					email: this.client_contact?.email,
					send_invite: this.client_contact?.send_invite,
					timesheet_notification_settings: this.client_contact?.timesheet_notification_settings
				});
			}
			this.ResetClientContact();
			this.contact_edit_index;
		} else {
			this.error = "Please fill out this field";
		}
	}

	async EditClientContact(contact: any, contact_index: any) {
		this.clientformValid = false;
		this.contact_edit_index = contact_index;
		this.client_contact = {
			uuid: contact.uuid,
			first_name: contact?.first_name,
			last_name: contact?.last_name,
			email: contact?.email,
			send_invite: contact?.send_invite,
			timesheet_notification_settings: contact?.timesheet_notification_settings
		};
	}

	async DeleteClientContact(contact_index: any) {
		this.client_contacts.splice(contact_index, 1);

		this.error = '';
		this.ResetClientContact();
	}

	ResetClientContact() {
		this.client_contact = {
			uuid: '',
			first_name: '',
			last_name: '',
			email: '',
			send_invite: true,
			timesheet_notification_settings: {
				email_type: this.email_type,
				frequency: this.frequency,
				day: this.day_to_receive,
				time: this.time_to_receive,
			}
		}
	}

	async clientValidation(event: any) {
		this.error = '';
		let hasErr = false;
		let clientNameElement = document.getElementById('clientName');
		if (this.clientForm.controls.name.errors || this.clientForm.controls.name.value.trim() == '') {
			if (clientNameElement !== null) {
				hasErr = true;
			}
		}

		if (hasErr) {
			this.error = "Client name field is required";
			this.formValid = false;
		} else {
			this.formValid = true;
		}
	}

	onSubmit() {
		this.loading = true;
		let client_address = {
			'address_1': this.clientForm.value.address_1,
			'address_2': this.clientForm.value.address_2,
			'post_zip_code': this.clientForm.value.post_zip_code,
			'country': this.clientForm.value.country,
		};
		const payload = { client: this.clientForm.value, client_contacts: this.client_contacts, address: client_address };
		this.clientService.createClient(payload).subscribe(response => {
			this.activeModalService.close({
				refresh: true,
				client_response: response
			});

			this.loading = false;
		}, error => {
			this.loading = false;
			this.error = error;
		});
	}
}
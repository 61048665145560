<div class="loader" *ngIf="loading">
    <mat-spinner diameter="30"></mat-spinner>
</div>
<div class="container-fluid">
    <div class="row g-6 mb-6">
        <div class="col-xl-12">
            <div class="card" #mainContainer>
                <div class="card-header">
                    <div class="row w-100 justify-content-between align-items-center">
                        <div class="col-sm col-12 mb-2 gap-2 d-flex">
                            <h3 class="h3 ls-tight px-2 py-2">Invoices</h3>
                        </div>
                    </div>
                </div>
                <div class="table-responsive">
                    <table matSort class="table table-hover table-nowrap">
                        <thead class="table-light">
                            <tr>
                                <th mat-sort-header="candidate" scope="col">Contractor</th>
                                <th mat-sort-header="client" scope="col">Client</th>
                                <th mat-sort-header="job" scope="col">Job</th>
                                <th mat-sort-header="invoice_date" scope="col">Invoice Date</th>
                                <th mat-sort-header="due_date" scope="col">Due Date</th>
                                <th mat-sort-header="total_pay" scope="col">Total Pay</th>
                                <th mat-sort-header="total_bill" scope="col">Total Bill</th>
                                <th mat-sort-header="actions" scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="invoices.length > 0">
                                <tr class="dataColumn" *ngFor="let invoice of invoices">
                                    <td>{{invoice.candidate}}</td>
                                    <td>{{invoice.client}}</td>
                                    <td>{{invoice.job}}</td>
                                    <td>{{invoice.invoice_date}}</td>
                                    <td>{{invoice.due_date}}</td>
                                    <td>{{invoice.currency_format}} {{invoice.total_pay}}</td>
                                    <td>{{invoice.currency_format}} {{invoice.total_bill}}</td>
                                    <td>
                                        <div (click)="viewInvoiceDetails(invoice)" style="color: #6b7b93;" class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="View Invoice" [matTooltipPosition]="'left'">
                                            <i class="bi bi-eye"></i>
                                        </div>
                                        <div class="btn btn-neutral btn-sm bt-square ms-1 px-3 py-1 fs-7" matTooltip="Edit Invoice" [matTooltipPosition]="'left'">
                                            <i class="bi bi-pencil"></i>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                            <ng-container *ngIf="invoices.length == 0">
                                <tr>
                                  <td class="text-heading font-bold text-center">No records found</td>
                                </tr>
                              </ng-container>
                        </tbody>
                    </table>
                </div>
            </div>
            <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount" (onStepChange)="onStepChange($event)">
            </app-api-paginator>
        </div>
    </div>
</div>
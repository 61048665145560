<section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
        <div class="d-flex justify-content-center py-4">
          <a href="index.html" class="logo d-flex align-items-center w-auto">
            <span class="d-none d-lg-block">Clug</span>
          </a>
        </div>
        <div class="card successColumnCard mb-3" *ngIf="successfullySet">
          <div class="successColumn">
            <i class="bi bi-check-circle"></i>
            <h5>Your Password has been successfully set</h5>
          </div>
        </div>
        <div class="card mb-3" *ngIf="!successfullySet">
          <div class="card-body">
            <div class="loginLoader d-flex justify-content-center">
              <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
            </div>
            <div class="pt-4 pb-2">
              <h5 class="card-title text-center pb-0 fs-4">Set Your Password</h5>
              <p class="text-center small">Enter your Email & New Password</p>
            </div>
            <form class="row g-3 needs-validation" [formGroup]="setPasswordForm" (ngSubmit)="setPassword()">
              <div class="col-12">
                <label for="email" class="form-label">Email</label>
                <div class="input-group">
                  <input type="text" formControlName="email" name="email" class="form-control" id="email"
                    [ngClass]="{ 'is-invalid': submitted && rForm['email'].errors }">
                  <div *ngIf="rForm['email'].errors" class="invalid-feedback">
                    <div *ngIf="rForm['email'].errors['required']">Email is required</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label for="password" class="form-label">Password</label>
                <div class="input-group d-flex align-items-center" id="form">
                  <input (keyup)="validatePasswordConfirmation()" [type]="showPassword ? 'text' : 'password'"
                    formControlName="password" name="password" class="form-control" id="password"
                    [ngClass]="{ 'is-invalid': submitted && rForm['password'].errors }"
                    style="border: none !important;">
                  <i [class]="showPassword ? 'bi bi-eye' : 'bi bi-eye-slash'" class="pe-4"
                    (click)="showPassword = !showPassword"></i>
                  <div *ngIf="rForm['password'].errors" class="invalid-feedback">
                    <div *ngIf="rForm['password'].errors['required']">Password is required</div>
                    <div *ngIf="rForm['password'].errors['minlength']">Password must be at least 8 characters</div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <label for="password_confirmation" class="form-label">Password Confirmation</label>
                <div class="input-group d-flex align-items-center" id="form">
                  <input (keyup)="validatePasswordConfirmation()" [type]="showConfirm ? 'text' : 'password'"
                    formControlName="password_confirmation" name="password_confirmation" class="form-control"
                    id="password" [ngClass]="{ 'is-invalid': submitted && rForm['password_confirmation'].errors }"
                    style="border: none !important;">
                  <i [class]="showConfirm ? 'bi bi-eye' : 'bi bi-eye-slash'" class="pe-4"
                    (click)="showConfirm = !showConfirm"></i>
                  <div *ngIf="rForm['password_confirmation'].errors" class="invalid-feedback">
                    <div *ngIf="rForm['password_confirmation'].errors['required']">Password Confirmation is required
                    </div>
                    <div *ngIf="rForm['password_confirmation'].errors['minlength']">Password must be at least 8
                      characters
                    </div>
                    <div *ngIf="rForm['password_confirmation'].errors['confirmPassword']">Password not match</div>
                  </div>
                </div>
              </div>
              <span style="color: red; font-size: 13px; text-align: center;" *ngIf="err">{{err}}</span>
              <div class="col-12">
                <button class="btn btn-primary w-100" type="submit">Set Password</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DataSharedService } from 'src/app/services/data-shared.service';
import { UserService } from 'src/app/services/user.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SuccessModal } from 'src/app/app.model';
import { SuccessModalComponent } from '../success-modal/success-modal.component';

@Component({
  selector: 'app-account-generation-link-modal',
  templateUrl: './account-generation-link-modal.component.html',
  styleUrls: ['./account-generation-link-modal.component.css']
})
export class AccountGenerationLinkModalComponent implements OnInit {

  @Input() userUuid: any;
  accountCreationLink: any = [];
  accounUser: any = [];

  constructor(
    private dialogRef: MatDialogRef<AccountGenerationLinkModalComponent>,
    private userService: UserService,
    public dataSharedService: DataSharedService,
    public authService: AuthenticationService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: NgbModal,
    private activeModalService: NgbActiveModal,
  ) {
    this.userUuid = data.userUuid
  }

  ngOnInit(): void {
    this.userService.getAccounCreationLink(this.userUuid).subscribe((response) => {
      this.accountCreationLink = response.account_link;
      this.accounUser = response.user;
    });
  }

  close() {
    // this.dialogRef.close();
    this.activeModalService.close();
  }

  copyLink(timesheetApprovalLink: any) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = timesheetApprovalLink;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.successModal('Link copied');
  }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }
}

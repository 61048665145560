<div class="container-fluid vstack gap-6">
    <div class="vstack gap-4">

        <div class="loader" *ngIf="loading">
            <mat-spinner diameter="40"></mat-spinner>
        </div>

        <div class="card">
            <div class="card-header">
                <div class="row align-items-center">
                    <div class="col-sm col-12">
                        <h3 class="h3 ls-tight">Agencies</h3>
                    </div>
                    <div class="col-sm-auto col-12 mt-4 mt-sm-0">
                        <div class="hstack gap-2 justify-content-sm-end">
                            <a class="btn btn-sm btn-primary" (click)="onAdd()">
                                Add Agency
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="table-responsive">
                <table matSort (matSortChange)="sortData($event)" class="table table-hover table-nowrap">
                    <thead class="table-light">
                        <tr>
                            <th mat-sort-header="name" scope="col">Name</th>
                            <th mat-sort-header="name" scope="col">Agency UUID</th>
                            <th mat-sort-header="name" scope="col">Clug API Key</th>
                            <th mat-sort-header="created_at" scope="col">Created At</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let agency of allAgencies; let i = index">
                            <td>
                                <span class="text-heading font-semibold">{{agency.name}}</span>
                            </td>
                            <td>{{agency.uuid}}</td>
                            <td>{{agency.clug_api_key}}</td>
                            <td>{{dataSharedService.getFormatedDate(agency.created_at)}}</td>
                        </tr>
                </table>
            </div>
            <app-api-paginator *ngIf="totalCount" [totalCount]="totalCount"
                (onStepChange)="onStepChange($event)"></app-api-paginator>

        </div>
    </div>
</div>